import React, { useState } from "react";
import { useAddSubscribe } from "../../../hooks";
import validationSchema from "./SubscribeValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import logo from "../../../assets/img/case_study/logop.png";

const Footer = () => {
  const { mutate: doSubscribe } = useAddSubscribe((res) => {
    toast.success(res.message);
  });

  /**
   * This Block will execute on Form Submit, provides form fields and validations for that
   */
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        await doSubscribe(values);
        resetForm();
      } catch (error) {
        console.error("Submission error:", error);
      }
    },
  });

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  // };

  const socialContent = [
    {
      icon: "fa-instagram",
      link: "https://www.instagram.com/guiviewofficial?igsh=MXYyOTljN3k3cnVldw==",
    },
    {
      icon: "fa-youtube-play",
      link: "https://youtube.com/@Guiviewofficial?si=1n4BKcy5D9ZqJK9U",
    },
    {
      icon: "fa-linkedin",
      link: "https://www.linkedin.com/company/guiview/",
    },
    {
      icon: "fab fa-twitter",
      link: "https://x.com/guiviewofficial?s=11",
    },
    {
      icon: "fa-pinterest",
      link: "https://in.pinterest.com/infoguiview/",
    },
    {
      icon: "fa-facebook",
      link: "https://www.facebook.com/people/Guiviewofficial/61558387238769/?mibextid=LQQJ4d&rdid=nILSkMx1ifUHdFqj",
    },
  ];
  return (
    <footer className="theme-footer-seven mt-50 md-mt-100">
      <div className="lg-container">
        <div className="container inner-btn-black">
          <div className="row ">
            <div className="col-xl-2 col-lg-2 mb-40">
              {/* <div className="logo">
                  <a href="">
                  <img src={logo} alt="brand logo" />
                  </a>
                </div> */}
            </div>
            {/* End .col */}

            <div className="col-lg-2 col-md-6 mb-40">
              <h5 className="title">Links</h5>
              <ul className="footer-list">
                <li>
                  <Link to="/privacy-notice">Privacy Notice</Link>
                </li>
                {/* <li>
                    <Link to="/cookie-notice">Cookie Notice</Link>
                  </li> */}
                <li>
                  <Link to="/term-of-use">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/return-refund-shipping">
                    Refund, Return & Shipping Policy
                  </Link>
                </li>
                <li>
                  <Link to="/data-protection-statement">
                    Data Protection Statementy
                  </Link>
                </li>

                <li>
                  <Link to="/imprint">Imprinty</Link>
                </li>
                <li>
                  <Link to="/accessibility">Accessibility</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 mb-40">
              <div className="newsletter">
                <h5 className="title">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ marginRight: "8px" }}
                  />
                  CONTACT US
                </h5>
                <p>
                  <a href="mailto: sales@guiview.com">
                    {" "}
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ marginRight: "8px" }}
                    />
                    sales@guiview.com
                  </a>
                  <a href="mailto: support@guiview.com">
                    {" "}
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ marginRight: "8px" }}
                    />
                    support@guiview.com{" "}
                  </a>
                </p>
                <p>
                  Mahadev vadi street no 3,<br></br>
                  Umakant Pandit Udyognagar,<br></br>
                  laxminagar main road,<br></br>
                  Rajkot (Gujarat) - 360004
                </p>
              </div>
            </div>

            <div className="col-xl-4 col-lg-5 mb-40">
              <div className="newsletter">
                <h5 className="title">Newslettert</h5>

                <p>
                  Get all the latest information on events, sales and offers.
                  Sign up for newsletter
                </p>

                <form onSubmit={formik.handleSubmit}>
                  <input
                    type="text"
                    placeholder="Enter your email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  <button type="submit">SUBSCRIBE</button>
                </form>
              </div>
              {/* /.newsletter */}
            </div>
          </div>
        </div>

        <div className="container">
          <div className="bottom-footer">
            <div className="row">
              <div className="col-lg-8 order-lg-1 mb-20">
                <div className="col-xl-3 col-lg-2 mb-">
                  <div className="logo">
                    <a href="">
                      <img src={logo} alt="brand logo" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 order-lg-1 mt-40">
                <ul
                  className=" d-flex
                justify-content-center justify-content-lg-end
                social-icon"
                >
                  {socialContent.map((val, i) => (
                    <li key={i}>
                      <a href={val.link} target="_blank" rel="noreferrer">
                        <i className={`fa ${val.icon}`}></i>
                      </a>
                    </li>
                  ))}
                </ul>
                {/* End .social-icon */}
              </div>
              <div className="col-lg-12 order-lg-2">
                <p className="">
                  At GUIVIEW, we are the pioneers in automation software
                  solutions, empowering businesses to optimize processes, drive
                  efficiency, and achieve unparalleled productivity. Our
                  cutting-edge technology and expert team are dedicated to
                  transforming your operations and taking your business to new
                  heights of success.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="bottom-footer">
            <div className="row">
              <div className="col-lg-4 order-lg-1 mt-40">
                <ul
                  className="
                d-flex
                justify-content-center justify-content-lg-start
                footer-nav
              "
                ></ul>
              </div>

              <div className="col-lg-4 order-lg-2 mt-40">
                <p className="copyright text-center">
                  © Copyright {new Date().getFullYear()}. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /.lg-container */}
    </footer>
  );
};

export { Footer };
