import { useMutation,useQuery } from "react-query";
import { toast } from "react-toastify";
import { CareerService } from "../api";

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetCareerData = (onSuccess, onError = onDefaultError) => {
  return useQuery(["career-view"], CareerService.getCareerData, {
    onSuccess,
    onError,
  });
};

const useAddCareer = (onSuccess, onError = onDefaultError) => {
  return useMutation(CareerService.addCareerData, {
    onSuccess,
    onError,
  });
};
export { useGetCareerData,useAddCareer };
