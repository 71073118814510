import Yup from "../../../helpers/customValidation";

let validationSchema = Yup.object({
  name: Yup.string("Name is Required").required("Name is Required"),
  email: Yup.string("Email is Required")
    .required("Email is Required")
    .email("Email Must be Valid"),

  company: Yup.string("company is Required").required("company is Required"),
  businessrole: Yup.string("businessrole is Required").required(
    "businessrole is Required"
  ),
  vatnumber: Yup.string("vatnumber is Required").required(
    "vatnumber is Required"
  ),
  street: Yup.string("street is Required").required("street is Required"),
  city: Yup.string("City is Required").required("City is Required"),
  postalcode: Yup.string("Postalcode is Required").required(
    "Postalcode is Required"
  ),
  phonenumber: Yup.string().required("Phonenumber must be 10 digits"),

  username: Yup.string("username is Required").required("username is Required"),
});

export default validationSchema;
