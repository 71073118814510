import React from "react";
import { Helmet } from "react-helmet";

const TermOfUsePage = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Terms & Conditions || Deski-Saas & Software React Template
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-12">
              {/* Tab panes */}
              <div className="tab-content ">
                <div id="opt3">
                  <div className="col-xl-6 col-lg-6 m-auto terms-condition-container">
                    <h2 className="font-gilroy-bold">Terms of Use</h2>
                    <div className="update-date">Effective Date: 4/17/2024</div>
                  </div>
                  <p>
                    Please read all of these terms of use (“Terms”) carefully
                    before using our websites, email notifications, mobile
                    applications, social media applications, widgets, and our
                    other online services (the “Services”) because it affects
                    your legal rights and obligations.
                  </p>
                  <h3>About Us</h3>
                  <p>
                    PI Corporation is registered in the India as a corporation
                    in the State of Gujarat and has its registered office
                    Rajkot.
                  </p>
                  <h3>This Agreement and Your Acceptance</h3>
                  <p>
                    Our Services are owned, operated and provided by PI
                    Corporation Electric Co. and its subsidiaries and affiliates
                    (“PI Corporation,” “our,” “us” or “we”). Our Services are
                    hosted on servers located inside and outside the United
                    States.
                  </p>
                  <p>
                    The Services of PI Corporation’s subsidiaries and affiliated
                    companies are each owned, operated and provided by such
                    subsidiaries and companies. However, these Terms ONLY govern
                    the use of PI Corporation company Services that specifically
                    link to these Terms. Other PI Corporation company Services
                    may link to or otherwise provide their own, separate terms.
                  </p>
                  <p>
                    You agree to these Terms by accessing or using any of our
                    Services. If you do not agree to be bound by all of these
                    Terms, including our Privacy Notice incorporated herein by
                    reference, you should not access or use the Services.
                  </p>
                  <p>
                    THIS CONTRACT CONTAINS A BINDING ARBITRATION PROVISION WHICH
                    MAY BE ENFORCED BY THE PARTIES. This agreement requires the
                    use of binding arbitration on an individual basis to resolve
                    disputes, rather than jury trials or class actions, and also
                    limits the remedies available to you in the event of a
                    dispute.
                  </p>
                  <h3>Our Services</h3>
                  <p>
                    Our Services are provided for your personal information and
                    non-commercial use. The content of our Services is provided
                    for general information only. There will be occasions our
                    Services may be interrupted due to scheduled maintenance or
                    upgrades, emergency repairs, failure of telecommunications
                    links and/or equipment, or similar occurrences.
                  </p>
                  <p>
                    Our Services contain text, software, images, photographs,
                    graphics, audio, video, applications, user interfaces,
                    visual interfaces, logos, designs, product names, and
                    technical specifications (“Materials”) which are derived in
                    whole or in part from information and materials supplied by
                    us and other sources, and are protected by, copyright,
                    trademark, patent, and other applicable laws. The entire
                    content of our Services is copyrighted as a collective work
                    under the United States Copyright Laws. Trademarks, logos,
                    and service marks displayed in our Services are registered
                    and unregistered trademarks of PI Corporation, its
                    subsidiaries and affiliated companies, its licensors or
                    content providers, or other third parties. All of these
                    trademarks, logos, and service marks are the property of
                    their respective owners. Nothing in our Services shall be
                    constructed as granting any license or right to use any
                    trademark, logo, or service mark displayed without the
                    owner’s prior written permission.
                  </p>
                  <p>
                    The use of any such Materials on any other website or
                    networked computer or environment without our express
                    written consent is prohibited. Materials may not be copied,
                    reproduced, republished, modified, uploaded, posted,
                    transmitted or distributed in any way nor may they be
                    decompiled, reverse engineered or disassembled, except that
                    you may download one copy of the Material on any single
                    computer for your personal, non-commercial use, provided you
                    keep intact all copyright, trademark and other proprietary
                    notices and make no change in or deletion of author
                    attribution. This limited authorization is not a transfer of
                    title in the Materials, and, by your use of our Services,
                    you acknowledge that you do not acquire any license,
                    ownership or other rights in or to the Materials.
                  </p>
                  <h3>Use of Our Services</h3>
                  <p>
                    In order to access some features of our Services, you may
                    have to create an account. You may not:
                  </p>
                  <li>Create an account for anyone other than yourself;</li>
                  <li>
                    Use another User's account without permission, or solicit,
                    collect or use the login credentials of other users; or
                  </li>
                  <li>
                    Sell, transfer, license or assign your account, username, or
                    any account rights.
                  </li>
                  <p>
                    When creating your account, you must provide true, current,
                    accurate and complete information, and you must update your
                    information as necessary to maintain its truth and accuracy.
                    You are responsible for keeping your password secure and you
                    are responsible for the activity that occurs on your
                    account. You may be liable for our losses or the losses of
                    others due to unauthorized use. If you become aware of or
                    suspect any breach of security or unauthorized use of your
                    password or of your account, you should notify us
                    immediately at inquiries@PI Corporation.com or
                    +1.314.679.8984.
                  </p>
                  <p>
                    You agree you have the legal right and capacity to enter
                    into these Terms and to comply with all laws, rules and
                    regulations (e.g., federal, state, local and provincial)
                    applicable to your use of our Services and any content you
                    submit, post or display (e.g., images, photographs,
                    graphics, audio, video, text, information, works of
                    authorship, applications, links, and other communications,
                    content or materials) (collectively, “User Submissions”),
                    including without limitation, copyright laws and export
                    laws.
                  </p>
                  <p>You may not:</p>
                  <li>
                    Circumvent, disable or otherwise interfere with any
                    security-related features of our Services or features that
                    prevent or restrict use or copying of any Materials and User
                    Submissions or enforce limitations on use of our Services
                    and the Materials or User Submissions;
                  </li>
                  <li>
                    Engage in any activity that interferes with or disrupts our
                    Services (or the servers and networks which are connected to
                    our Services), including by transmitting any worms, viruses,
                    spyware, malware or any other code of a destructive or
                    disruptive nature;
                  </li>
                  <li>
                    Inject content or code or otherwise alter or interfere with
                    the way any part of our Services is rendered or displayed in
                    a user's browser or device;
                  </li>
                  <li>
                    Change, alter or modify any part of our Services for any
                    reason;
                  </li>
                  <li>
                    Use or launch any type of automated system, including but
                    not limited to, "robots," "spiders," or "offline readers,"
                    etc., that accesses our Services in a manner that sends more
                    request messages to our servers in a given period of time
                    than a human can reasonably produce in the same period by
                    using a conventional online web browser;
                  </li>
                  <li>
                    Frame or deep link into any of the PI Corporation websites;
                  </li>
                  <li>
                    Access (or attempt to access) any of our Services, including
                    Materials and User Submissions, by any means other than
                    through the interfaces that are provided by us;
                  </li>
                  <li>
                    Use our Services for any illegal or unauthorized purpose.
                  </li>
                  <h3>User Submissions</h3>
                  <p>
                    You and your agents are responsible for your User
                    Submissions, including without limitation, any damages
                    resulting from any infringement of copyright, patent,
                    trademark, proprietary rights, or any other harm resulting
                    from such a submission.
                  </p>
                  <p>Your User Submissions must comply with the following:</p>
                  <li>
                    You may not submit abusive, obscene, threatening, harassing,
                    defamatory, libelous, offensive, violent, nude, partially
                    nude, discriminatory, unlawful, infringing, hateful,
                    pornographic or sexually suggestive User Submissions via our
                    Services, or User Submissions that encourage conduct that
                    would be considered a criminal offense, give rise to civil
                    liability, violate any law, or are otherwise inappropriate.
                  </li>
                  <li>
                    You may not post advertisements, solicitations or spam links
                    to other websites or individuals, without prior written
                    permission from us.
                  </li>
                  <li>
                    You may not defame, stalk, bully, abuse, harass, threaten,
                    impersonate or intimidate people or entities.
                  </li>
                  <li>You may not make false or misleading statements.</li>
                  <li>
                    You may not submit private, confidential or sensitive
                    information unless specifically requested by us (e.g.,
                    providing credit card information to complete a purchase
                    transaction via our Services).
                  </li>
                  <p>
                    It has been and remains our policy not to accept or consider
                    ideas, suggestions or materials other than those that we
                    have specifically requested from you. Any information or
                    material you send to us will be deemed not to be
                    confidential or proprietary. Our Services may now or in the
                    future permit User Submissions and the hosting, sharing,
                    and/or publishing of such User Submissions. No User
                    Submissions are confidential whether published or not. You
                    also acknowledge that the internet may be subject to
                    breaches of security and that the submission of User
                    Submissions or other information cannot be guaranteed to be
                    secure.
                  </p>
                  <p>
                    You retain all of your ownership rights in your User
                    Submissions. By submitting information and material via our
                    Services, you automatically grant to us (or warrant that the
                    owner of such information and material has expressly granted
                    to us) a royalty-free, perpetual, irrevocable, unrestricted,
                    right and license to use, reproduce, display, perform,
                    modify, adapt, publish, translate, transmit and distribute,
                    or otherwise make available to others such information and
                    material (in whole or in part) worldwide for any purpose,
                    and/or to incorporate it in other works and for any purpose
                    and in any form, media, or technology now known or hereafter
                    developed. You also agree that we are free to use any ideas,
                    concepts, know-how, or techniques that you send to us for
                    any purpose.
                  </p>
                  <p>
                    We reserve the right to determine in our sole discretion
                    whether User Submissions are appropriate and comply with
                    these Terms for reasons including without limitation,
                    excessive length. We may, but have no obligation to, at any
                    time, without prior notice and in our sole discretion,
                    remove, edit, block, and/or monitor User Submissions or
                    terminate a user’s access for submitting such material in
                    violation of these Terms.
                  </p>
                  <p>
                    Our Services may allow for a live chat feature through a
                    third-party provider. In that case, any communications that
                    you have or content that you submit via the live chat
                    feature (“Chat Data”) will be considered personal
                    information and the use thereof is governed by our Privacy
                    Notice. Our third-party provider implements security
                    measures to protect the confidentiality of the transcript of
                    the live chat it stores and your Chat Data is deleted
                    approximately thirteen (13) months after it is captured.
                    However, no method of electronic storage is 100% secure or
                    error-free, so it is not possible to guarantee absolute
                    security of your Chat Data.
                  </p>
                  <h3>Third-Party Content and Linked Sites</h3>
                  <li>
                    When using our Services, you may be exposed to User
                    Submissions submitted, posted or displayed by users from a
                    variety of sources. We are not responsible for the accuracy,
                    usefulness, safety, or intellectual property rights relating
                    to such user posted content. You agree to waive any legal or
                    equitable rights or remedies you have or may have against us
                    with respect to the User Submissions of others to the
                    fullest extent permitted by law.
                  </li>
                  <li>
                    We may provide links to other independent third-party
                    websites (“Linked Sites”) as a convenience to you. If you
                    use these links, you do so at your own risk. We have no
                    control over, and assume no responsibility for, the content,
                    services, privacy policies, terms of use, or practices of
                    any third-party websites. Inclusion of such content and
                    links does not imply our sponsorship or endorsement of the
                    same (including the information or materials appearing or
                    any of products and services described on Linked Sites), our
                    affiliation or association with any such content and links,
                    or that any Linked Site is authorized to use any trademark,
                    trade name, logo, or copyright of PI Corporation, its
                    subsidiaries or affiliates. We will not be a party to or in
                    any way be responsible for monitoring any transaction
                    between you and third-party providers of products or
                    services. Your correspondence and business dealings with
                    third parties found through our Services are solely between
                    you and the third party.
                  </li>
                  <h3>Disclaimer</h3>
                  <p>
                    Our Services, including without limitation, Materials, User
                    Submissions, any products or services available on our
                    Services and all the information, software, facilities,
                    services, related communications, and other content therein
                    are provided on an “as is,” “as available,” and “with all
                    faults” basis. To the fullest extent permissible under
                    applicable law, we disclaim all representations or
                    warranties, express or implied, of any kind, including
                    without limitation: (i) warranties of merchantability,
                    non-infringement and fitness for particular purpose; (ii)
                    warranties arising from course of dealing or course of
                    performance; (iii) the accuracy, reliability, usefulness, or
                    completeness of any information contained in our Services;
                    (iv) that access to our Services will be uninterrupted or
                    error-free; and (v) that our Services will be secure. We
                    assume no responsibility and shall not be liable for any
                    damages of any nature caused by the use of our Services,
                    including damages caused by viruses, worms, Trojan horses or
                    any other computer software or anomaly that may infect,
                    affect, or damage your computer hardware, software, memory
                    or any other property of yours or others, as a result of
                    your access to or use of our Services.
                  </p>
                  <p>
                    We disclaim any responsibility or liability to any person or
                    entity for any loss, damage (whether actual, consequential,
                    punitive or otherwise), injury, claim, liability or other
                    cause of any kind or character based upon or resulting from
                    any materials, content, User Submissions, or products or
                    services advertised or offered by a third party through our
                    Services or featured in any banner or other advertising.
                  </p>
                  <h3>Limitation of Liability</h3>
                  <p>
                    You agree that you assume full responsibility for your use
                    of our Services and that PI Corporation’s liability to you
                    and any party is limited as follows. Under no circumstances
                    will we, our suppliers or other third parties mentioned or
                    involved in creating, producing, or delivering our Services
                    be liable for any direct, incidental, consequential,
                    indirect, special or punitive damages whatsoever (including
                    without limitation, costs and expenses of any type incurred,
                    lost profits, lost data or programs, and business
                    interruption) arising out of your access to, use of,
                    inability to use or the results of use of our Services, or
                    any Materials contained in any or all such Services
                    (including without limitation, those caused by or resulting
                    from a failure of performance; error; omission; linking to
                    Linked Sites; interruption; deletion; defect; delay in
                    operation or transmission; computer virus; communication
                    line failure; or destruction of, unauthorized access to,
                    alteration of, or use of any computer or system), whether
                    based on warranty, contract, tort, negligence, strict
                    liability, or any other legal theory and whether or not we
                    were advised of the possibility of such damages. We shall
                    have no liability or responsibility for any acts, omissions,
                    or conduct of any user or third party, including User
                    Submissions and Linked Sites. The maximum total liability of
                    PI Corporation and its subsidiaries, affiliates and our
                    officers, directors, shareholders, predecessors, successors
                    in interest, employees, and agents, to you for any claim
                    under these Terms, whether in contract, tort, or otherwise,
                    is one hundred dollars ($100). If you are dissatisfied with
                    any of the Materials contained in our Services, or with any
                    of these Terms, your sole and exclusive remedy is to
                    discontinue accessing and using our Services.
                  </p>
                  <p>
                    By accessing our Services, you understand that you may be
                    waiving rights with respect to claims that are at this time
                    unknown or unsuspected, and in accordance with such waiver,
                    you acknowledge that you have read and understand, and
                    hereby expressly waive, the benefits of section 1542 of the
                    civil code of the State of India, and any similar law of any
                    U.S. state or territory, which provides as follows: “a
                    general release does not extend to claims which the creditor
                    does not know or suspect to exist in his favor at the time
                    of executing the release, which if known by him must have
                    materially affected his settlement with the debtor.”
                  </p>
                  <h3>Indemnity</h3>
                  <p>
                    You agree to indemnify, defend and hold us, our officers,
                    directors, shareholders, predecessors, successors in
                    interest, employees, agents, subsidiaries and affiliates
                    harmless from any demands, loss, liability, claims and
                    expenses (including attorneys’ fees) made against PI
                    Corporation by any third party due to, arising out of or in
                    connection with: (i) your User Submission or your use of and
                    access to our Services; (ii) your breach or alleged breach
                    of these Terms; (iii) your violation or alleged violation of
                    any third-party right, including without limitation, any
                    intellectual property right, publicity, confidentiality,
                    property or privacy right; (iv) your violation or alleged
                    violation of any laws, rules, regulations, codes, statutes,
                    ordinances or orders; (v) any claim that your User
                    Submission caused damage to a third party; or (vi) any
                    misrepresentation made by you. We reserve the right to
                    assume the exclusive defense and control of any matter
                    subject to indemnification and defense by you, and you will
                    not in any event settle any claim without our prior written
                    consent. You will cooperate as fully required by us in the
                    defense of any claim. This defense and indemnification
                    obligation will survive these Terms and your use of our
                    Services.
                  </p>
                  <h3>Forward-looking Statements</h3>
                  <p>
                    The Materials in our Services may contain various
                    forward-looking statements which may be based on or include
                    assumptions concerning operations, future results and
                    prospects of PI Corporation. These forward-looking
                    statements are based on current expectations and are subject
                    to risk and uncertainties.
                  </p>
                  <p>
                    In connection with the “safe harbor” provisions of the
                    United States’ Private Securities Litigation Reform Act of
                    1995, we provide the following cautionary statement
                    identifying important economic, political and technology
                    factors which, among others, could cause the actual results
                    or events to differ materially from those set forth or
                    implied by the forward-looking statements and related
                    assumptions. Such factors include without limitation the
                    following: (i) changes in the current and future business
                    environment, including interest rates and capital and
                    consumer spending; (ii) competitive factors and competitor
                    responses to our initiatives; (iii) successful development
                    and market introductions of anticipated products; (iv)
                    changes in government laws and regulations, including taxes;
                    (v) unstable governments and business conditions in emerging
                    economies; and (vi) continuation of the favorable
                    environment to make acquisitions, domestic and foreign,
                    including regulatory requirements and market values of
                    candidates.
                  </p>
                  <h3>Changes to our Terms and Termination of the Services</h3>
                  <p>
                    We reserve the right, in our sole discretion, to revise
                    these Terms at any time and correct any errors or omissions
                    in any portion of our Services and Materials, and will post
                    updates to these Terms on this webpage with a new effective
                    date. Materials may also be changed, modified, added, or
                    removed and updated without notice at any time; however, we
                    are under no obligation to update Materials. We may also
                    make changes in the products, services, programs, or prices
                    (if any) described in our Services at any time without
                    notice. Your continued use of our Services after any change
                    we make to our Services will be considered acceptance of
                    those changes. You should revisit these Terms from time to
                    time to review the then-current Terms because your use of
                    our Services will be governed by the then-current Terms and
                    Privacy Notice.
                  </p>
                  <p>
                    These Terms will remain in full force and effect so long as
                    you continue to access or use our Services, or until
                    terminated in accordance with the provisions of these Terms.
                    At any time, we may: (i) suspend, withdraw, discontinue,
                    change, or terminate either our Services in general or (ii)
                    suspend, withdraw, discontinue, change, or terminate these
                    Terms and your rights to access or use our Services for any
                    reason, particularly if we believe that you have restricted
                    or inhibited any other user from using or enjoying our
                    Services or in any other way have used our Services in
                    violation of these Terms. Upon termination of these Terms,
                    your authorization to use our Services automatically
                    terminates and you must immediately destroy any downloaded
                    or printed Materials.
                  </p>
                  <h3>Governing Law and Arbitration</h3>
                  <li>
                    These Terms shall be governed by the laws of the State of
                    Gujarat, India, without giving effect to any principles of
                    conflicts of law or the 1980 U.N. Convention on Contracts
                    for the International Sale of Goods. Any claim or dispute
                    between you and PI Corporation that arises in whole or in
                    part from our Services shall be decided exclusively through
                    binding, individual arbitration in the State of Gujarat,
                    India You agree that disputes between you and PI Corporation
                    will be resolved by binding arbitration and you waive your
                    right to participate in class action lawsuits or class-wide
                    arbitration. In the event that binding arbitration is not
                    enforceable, you consent to the exclusive jurisdiction of
                    the State of Gujarat, India for the resolution any disputes.
                    Any cause of action you may have with respect to your use of
                    our Services must be commenced within six (6) months after
                    the claim or cause of action arises.
                  </li>
                  <h3>Copyright Infringement</h3>
                  <p>
                    We will investigate any allegations of copyright
                    infringement brought to our attention. If you have evidence
                    of, know of, or have a good faith belief that your rights or
                    the rights of a third party have been violated and you want
                    PI Corporation to review, delete, edit, or disable the
                    material in question, you must provide PI Corporation with
                    all of the following information:
                  </p>
                  <li>
                    An electronic or physical signature of the person authorized
                    to act on behalf of the owner of the copyright interest;
                  </li>
                  <li>
                    Description of the copyrighted work that you claim has been
                    infringed;
                  </li>
                  <li>
                    The location on our websites or mobile applications of the
                    material that you claim is infringing;
                  </li>
                  <li>Your address, telephone number and email address;</li>
                  <li>
                    A statement that your claim of infringement is based on a
                    good faith belief; and
                  </li>
                  <li>
                    A statement made under penalty of perjury that the
                    information you have provided is accurate and that you are
                    the copyright owner or authorized to act on the copyright
                    owner’s behalf.
                  </li>
                  <p>
                    For this notification to be effective, you must direct your
                    correspondence to PI Corporation’s designated agent at
                    <li>
                      <a href="#">info@piinco.com</a>
                    </li>
                  </p>
                  <h3>Entire Agreement and Void Where Prohibited</h3>
                  <p>
                    These Terms constitute the entire agreement between PI
                    Corporation and you with respect to your use of our
                    Services. If any provision of these Terms is deemed invalid
                    by a court of competent jurisdiction, the invalidity of such
                    provision shall not affect the validity of the remaining
                    provisions of these Terms, which shall remain in full force
                    and effect.
                  </p>
                  <p>
                    Our obligations and responsibilities regarding our products
                    and services are governed solely by our terms and conditions
                    of sale or license under which such products and services
                    are sold or licensed.
                  </p>
                  <p>
                    We make no representation that the Materials in our Services
                    are appropriate or available for use at other locations, and
                    access to them from territories where their content is
                    illegal is prohibited. If you access our Services from a
                    location outside of the State of Gujarat, you are
                    responsible for compliance with all applicable local laws.
                    You may not use our Services or export information and
                    materials in violation of the export laws of the United
                    States or any other country. Materials published on a
                    website, or otherwise included in our Services, may refer to
                    products, programs, or services that are not available in
                    your country.
                  </p>
                  <h3>Contact Us</h3>
                  <p>
                    If you have any questions about the Terms, please write to
                    us by mail at PI Corporation., Attn: Website Inquiries,
                    3-mahadev vadi, umakant pandit udhyog nagar,rajkot-360004 MO
                    63136, or email us at
                  </p>
                  <li>
                    <a href="#">info@piinco.com</a>
                  </li>
                  or call us at
                  <li>
                    {" "}
                    <a href="#">9974841011</a>
                  </li>
                </div>
              </div>
              {/*  /.tab-content */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermOfUsePage;
