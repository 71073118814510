/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { useGetHomeSvgData } from "../../hooks";

const BuildingAutomationPage = () => {

  let fileNames = ["building","buildingauto"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <>
      <div className="fancy-hero-three">
        
        <div className="">
          <div className="container">
            <div className="row">
              {/* <div className="col-xl-9 col-lg-10 m-auto">
                <h1 className="heading">Building Automation</h1>
              </div> */}
               {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "building") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-text-block-thirteen pt-20 md-pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Building automation</h2><br/>
                </div>
                {/* /.title-style-two */}
                <p>
                The building automation industry can significantly enhance efficiency and control with GUIview’s advanced solutions, powered by GUI Builder and Cloud View. <b>GUI Builder</b> provides robust tools for monitoring and managing various building systems, including HVAC, lighting, security, and energy management. Its intuitive interface ensures seamless integration and real-time control of these systems, promoting operational efficiency and sustainability.

                </p><br/>
                <p>
                
                  <b>Cloud View</b> further elevates these capabilities by offering remote access to building data and control functions. Facility managers can monitor systems, receive alerts, and make data-driven decisions from anywhere, ensuring optimal performance and swift issue resolution.
                </p><br/>
                <p>Together, GUI Builder and Cloud View deliver a comprehensive platform for building automation, providing real-time monitoring, remote accessibility, and enhanced control. Trust GUIview to optimize your building operations with cutting-edge technology and real-time connectivity.</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
               {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "buildingauto") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BuildingAutomationPage;
