import React, { useRef, useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";



import { toast } from "react-toastify";

import { useAddCareer, useGetHomeSvgData } from "../../hooks";
import validationSchema from "./AddCareerValidation";

const CareerPage = () => {
  const newsImageRef = useRef();
  const [selectedFileName, setSelectedFileName] = useState("");
  const { mutate: doAddCareer } = useAddCareer((response) => {
    toast.success(response.message);
    formik.resetForm();
    setSelectedFileName(""); // Reset selected file name
  });
  let fileNames = ["workwithus"];
  let data = "";

  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  // let careerData = [];
  /**
   * !This API will call when the career page is set. When the response comes, we set the career data.
   */
  // const { isLoading: careerLoading, data: careerDataResponse } =
  //   useGetCareerData();
  // if (!careerLoading) {
  //   careerData = careerDataResponse.data;
  // }
  const formik = useFormik({
    initialValues: {
      filename: "",
      full_name: "",
      designation: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      // console.log("aaaaa");
      doAddCareer(values);
    },
  });

  let fileName = "workm";
  let svgData = "";
  const { isLoading: svgLoading, data: svgDataResponse } =
    useGetHomeSvgData(fileName);
  if (!svgLoading) {
    
    svgData = svgDataResponse.data;
    //console.log(svgDataResponse, "svgDataResponse");
  }

  const FaqContent = [
    {
      title: "Our Benefits",
      desc: ` Accelerate your career with us. Access valuable
      resources, mentorship, and growth opportunities,
      paving the way for a successful and fulfilling
      professional journey.`,
      expand: "a",
    },
    {
      title: "Our Culture",
      desc: ` Experience a thriving culture that nurtures talent,
      fosters collaboration, and embraces innovation,
      creating a dynamic environment where your career can
      flourish.`,
      expand: "b",
    },
    {
      title: "Join Our Team",
      desc: ` Join our team and embark on an exciting career journey
      where your skills, passion, and aspirations converge,
      driving meaningful impact and growth opportunities.`,
      expand: "c",
    },
  ];
  // const removeHTMLTags = (input) => {
  //   return input.replace(/<[^>]*>?/gm, ""); // This regular expression matches and removes HTML tags
  // };

  return (
    <>
      <div className="fancy-hero-three">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl- col-lg- m-auto">
                <h1 className="heading"> Work With Us</h1>
                {data &&
                  data.svgDataArray.map((val, index) => {
                    if (val.fileName === "workwithus") {
                      return <img key={index} src={val.svgDataURL}></img>;
                    } else {
                      return null; // Or you can choose to return some other element if the condition is not met
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      <div className="fancy-feature-three pt-30 md-pt-80">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-one">
                  <h2>Find Your Oportunity</h2>
                </div>
                <p className="mt-30">
                  GUIview develops innovative solutions with updated
                  technologies to management, customer care, service, and sales.
                  It also builds solutions that maintain international quality,
                  creativity, and culture of Innovation.
                </p>
                <p className="mt-10">
                  The location of the parent company and central offices are at
                  Rajkot, India. A branch has been established to handle another
                  task by the parent company. The headquarters of international
                  management is located at Ahmedabad, India.
                </p>
                <p className="mt-10">
                  Our team is young, flexible, and innovative in generating new
                  ideas, which can solve the daily challenges of technological
                  development. Our innovation policies allow team members to
                  share their innovative and creative ideas for finding the best
                  solutions.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="clients-feedback-classic md-mt-80">
                <div
                  className="feedback-wrapper"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <p>
                    The organization has an energetic and innovative group that
                    contains young team members. All the team members have an
                    equal opportunity, which is handled with the co-operation of
                    young and motivated management.
                  </p>
                </div>{" "}
                {/* /.feedback-wrapper */}
              </div>
              {/* /.clients-feedback-classic */}
            </div>
          </div>
        </div>
      </div>

      <div
        className="faq-section-five lg-container pt-100 mt-50 md-pt-80"
        id="faq"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-11 m-auto">
              <div className="title-style-two text-center mb-35 md-mb-10">
                <div className="row">
                  <div className="col-lg-10 m-auto">
                    <h2>Application Form</h2>
                  </div>
                </div>
              </div>
              {/* /.title-style-one */}
            </div>

            <div className="col-lg-6 ml-auto">
              <div className="title-style-twelve">
                <div className="pt-0 pb-40 md-pb-30">
                  <form id="contact-form" onSubmit={formik.handleSubmit}>
                    <div className="messages"></div>
                    <div className="row controls">
                      <div className="col-md-12">
                        <div className="input-group-meta form-group mb-20">
                          <label>Full Name</label>
                          <input
                            type="text"
                            placeholder="Enter Your Name"
                            name="full_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.full_name}
                            className={`form-control ${
                              formik.touched.full_name &&
                              formik.errors.full_name
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.touched.full_name &&
                            formik.errors.full_name && (
                              <div
                                className="invalid-feedback"
                                style={{ marginBottom: "15px" }}
                              >
                                {formik.errors.full_name}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="input-group-meta form-group mb-20">
                          <label htmlFor="designation">Designation</label>
                          <select
                            id="designation"
                            name="designation"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.designation}
                            className={`form-select ${
                              formik.touched.designation &&
                              formik.errors.designation
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">Select Designation</option>
                            <option value="Web Developer">Web Developer</option>
                            <option value="Tester">Tester</option>
                          </select>
                          {formik.touched.designation &&
                            formik.errors.designation && (
                              <div
                                className="invalid-feedback"
                                style={{ marginBottom: "15px" }}
                              >
                                {formik.errors.designation}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="input-group-meta form-group mb-20">
                          <label>Drop Resume:</label>
                          <div className="d-flex align-items-center">
                            <input
                              className={"d-none"}
                              type="file"
                              accept=".text, .pdf"
                              ref={newsImageRef}
                              name="filename"
                              onChange={(event) => {
                                const file = event.currentTarget.files[0];
                                const extension = file.name.split(".");
                                if (["text", "pdf"].includes(extension[1])) {
                                  formik.setFieldValue("filename", file);
                                  setSelectedFileName(file.name);
                                }
                              }}
                              onBlur={formik.handleBlur}
                            />
                            <button
                              variant="outlined"
                              color="primary"
                              onClick={() => newsImageRef.current.click()}
                              className="theme-btn-two mt-10"
                              starticon={<FontAwesomeIcon icon={faUpload} />}
                            >
                              File
                            </button>
                          </div>

                          {selectedFileName && (
                            <div className="selected-file">
                              Selected File: {selectedFileName}
                            </div>
                          )}
                          {formik.touched.filename &&
                            formik.errors.filename && (
                              <div
                                className="invalid-feedback"
                                style={{ marginBottom: "15px" }}
                              >
                                {formik.errors.filename}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="d-flex justify-content-left mt-3">
                            <button
                              type="submit"
                              className="theme-btn-two"
                              disabled={!formik.isValid}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="accordion-style-four">
                <div className="faq-wrraper">
                <Accordion allowZeroExpanded>
                    
                      <AccordionItem
                        className="card"
                        
                      >
                        <AccordionItemHeading className="card-header">
                          <AccordionItemButton>
                            <h5 className="mb-0">
                              <button className="btn btn-link">
                                Application Form
                              </button>{" "}
                            </h5>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                      
                        
                          <div className="card">
                    
                    <div className="form-style-light">
                      <form id="contact-form" onSubmit={formik.handleSubmit} >
                        <div className="messages"></div>
                        <div className="row controls">
                          <div className="col-md-12">
                            <div className="input-group-meta form-group mb-60">
                              <label>Full Name</label>
                              <input
                                type="text"
                                placeholder="Enter Your Name"
                                name="full_name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.full_name}
                                className={`form-control ${
                                  formik.touched.full_name &&
                                  formik.errors.full_name
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {formik.touched.full_name &&
                                formik.errors.full_name && (
                                  <div className="invalid-feedback">
                                    {formik.errors.full_name}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="input-group-meta form-group mb-50">
                              <label htmlFor="designation">Designation</label>
                              <select
                                id="designation"
                                name="designation"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.designation}
                                className={`form-select ${
                                  formik.touched.designation &&
                                  formik.errors.designation
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <option value="">Select Designation</option>
                                <option value="Web Developer">
                                  Web Developer
                                </option>
                                <option value="Tester">Tester</option>
                              </select>
                              {formik.touched.designation &&
                                formik.errors.designation && (
                                  <div className="invalid-feedback">
                                    {formik.errors.designation}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="input-group-meta form-group mb-50">
                              <label>Upload Resume:</label>
                              <div className="d-flex align-items-center">
                                <input
                                  className={"d-none"}
                                  type="file"
                                  accept=".text, .pdf"
                                  ref={newsImageRef}
                                  name="filename"
                                  onChange={(event) => {
                                    const file = event.currentTarget.files[0];
                                    const extension = file.name.split(".");
                                    if (
                                      ["text", "pdf"].includes(extension[1])
                                    ) {
                                      formik.setFieldValue("filename", file);
                                      setSelectedFileName(file.name); 
                                    }
                                  }}
                                  onBlur={formik.handleBlur}
                                />
                                <button
                                  type="button"
                                  onClick={() => newsImageRef.current.click()}
                                  className="btn btn-outline-primary theme-btn-two mt-10"
                                >
                                  Choose File
                                </button>
                              </div>

                              {selectedFileName && (
                                <div className="selected-file">
                                  Selected File: {selectedFileName}
                                </div>
                              )}
                              {formik.touched.filename &&
                                formik.errors.filename && (
                                  <div className="invalid-feedback">
                                    {formik.errors.filename}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="d-flex justify-content-center mt-3">
                                <button
                                  type="submit"
                                  className="theme-btn-two"
                                  disabled={!formik.isValid}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    
                  </div>
                        
                       
                      </AccordionItem>
                    
                  </Accordion>
                  
                </div>
              </div> */}
            </div>

            <div className="col-lg-6 ml-auto">
              <div className="accordion-style-four">
                <div className="faq-wrraper">
                  <Accordion allowZeroExpanded>
                    {FaqContent.map((item, i) => (
                      <AccordionItem
                        className="card"
                        key={i}
                        uuid={item.expand}
                      >
                        <AccordionItemHeading className="card-header">
                          <AccordionItemButton>
                            <h5 className="mb-0">
                              <button className="btn btn-link">
                                {item.title}
                              </button>{" "}
                            </h5>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        {/* Accordion Heading */}
                        <AccordionItemPanel className="card-body fadeInUp">
                          <p>{item.desc}</p>
                        </AccordionItemPanel>
                        {/* Accordion Body Content */}
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerPage;
