import { useEffect,useRef } from "react";
import { useFormik } from "formik";
import validationSchema from "./QuoteValidation";
import { useAddQuote,AddQuotes } from "../../../hooks/useRQQuote";
import { toast } from "react-toastify";
import BitrixFormQuotes from "../../../common/components/BitrixFormQuotes";

const QuotePage = () => {
  // const { mutate: doQuotes, isLoading } = useAddQuote((res) => {
  //   toast.success(res.message);
  // });

  const doQuote = (data) => {
    // console.log("data",data)
    AddQuotes(data)
  }

  // const formik = useFormik({
  //   initialValues: {
  //     name: "",
  //     email: "",
  //     country: "",
  //     contactnumber: "",
  //     message: "",
  //   },
  //   validationSchema,
  //   onSubmit: async (values, { resetForm }) => {
  //     try {
  //       await doQuote(values);
  //       resetForm();
  //     } catch (error) {
  //       console.error("Submission error:", error);
  //     }
  //   },
  // });

  return (
    <div className="contact-us-light pt-0 md-pt-0 md-pb-0">
      
      
        <div className="form-style-light">
          <div
            className="row controls"
            id="quote-form"
            style={{ width: "100%", height: "100%" }}
          >
            <div id="QuotesForm">
            <BitrixFormQuotes doQuote={doQuote} />
            </div>
             
          </div>
        </div>
      </div>
   
  );
};

export { QuotePage };
