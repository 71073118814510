import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { ManualService } from "../api";

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetManualData = (onSuccess, onError = onDefaultError) => {
  return useQuery(["manual-view"], ManualService.getManualData, {
    onSuccess,
    onError,
  });
};
export { useGetManualData };
