/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { useGetHomeSvgData } from "../../hooks";

const ScadaPage = () => {
  let fileNames = ["scada"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <div>
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container"
        data-aos="fade-left"
        data-aos-duration="1200">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              
            >
              <div className="">
                <div className="title-style-two">
                  <h2> SCADA</h2>
                </div>
                {/* /.title-style-two */}
                <p className="mt-4">
                An intriguing feature from the client's perspective is the provision of a single editor for creating HMI projects. Not only is it potent, but it's also scalable. Essentially, it allows for the creation of an environment independent of hardware and screen resolution. Users can seamlessly connect with any PLC and distribute completed projects from any PC.
                </p>
                <p className="mt-4">
                This feature facilitates cost savings, thereby aiding financial situations. Moreover, it adheres to all necessary conditions and policies to fulfill requirements.

                </p>
                <p className="mt-4">
                The GUI builder is developed using the renowned C++ language. It offers a flexible client-server architecture based on WinForm, facilitating the distribution of applications from the Windows platform. This competitive product is built using traditional, comprehensive, and open-source technology.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              
            >
              {data &&
              data.svgDataArray.map((val, index) => {
                if (val.fileName === "scada") {
                  return <img key={index} src={val.svgDataURL}></img>;
                } else {
                  return null; // Or you can choose to return some other element if the condition is not met
                }
              })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div
        className="container mt-5"
        
      >
        <div className="row">
          <div className="col-xl-10 m-auto">
            <p className="mt-2">
            Utilizing advanced technology, GUIbuilder stands out as a simple, reliable, and robust platform. It has consistently ranked among the top choices for users due to its longevity. Despite its high-level features, it remains flexible and sturdy. Users can seamlessly connect their databases with others, and it offers an SVG-based vector graphic engine for a streamlined approach. Additionally, it boasts a powerful VBA and JavaScript engine for creating Instruction Lists. Its intuitive development features make functionality easy to access.
            </p>
            <p className="mt-4">
              <span>
              With various unique tools, users can create powerful visualization and control projects. Leveraging innovative technologies, GUIbuilder enables applications to:
              </span>
            </p>
            <li class="">
            Connect remotely and distribute data locally or remotely
            </li>
            <li class="">
            Facilitate easy connection and integration globally
            </li>
            <li class="">
            Establish connections with different measurement and control devices, aligning with Industry 4.0 directives
            </li>
            <p className="mt-4">
            Ultimately, GUIview strives to provide long-term software solutions through partnership. With years of experience, we understand the requirements of IIoT projects.
            </p>
          </div>
        </div>
        {/* End .row */}
      </div>
    </div>
  );
};

export default ScadaPage;
