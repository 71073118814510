import React, { useEffect } from "react";

function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
const BitrixFormSupport = ({doSupport}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdn.bitrix24.in/b30119693/crm/form/loader_24.js?" + (Date.now() / 180000 | 0);
    script.async = true;
    script.setAttribute('data-b24-form', 'inline/24/e28v8o');
    script.setAttribute('data-skip-moving', 'true');
    document.querySelector("#SupportForm").appendChild(script);

    setTimeout(() => {
      let button = document.querySelector("#SupportForm form");
      console.log("button",button)
      if(button != null){

     
       
      button.addEventListener("submit", function (event) {
        const checkbox = document.querySelector('input[type="checkbox"]');
       
        const isChecked = checkbox.checked;
        console.log("isChecked",isChecked)
        if (isChecked == true) {
          const inputs = document.querySelectorAll('input[type="string"]');
          const email = document.querySelector('input[name="email"]').value;
          console.log("aaa",document.querySelector('input[type="tel"]'))
          const phone = document.querySelector('input[type="tel"]').value;
            
          let name, company, street, city, postalcode,  product, licencenumber, build, priority, problemtype, object, problemdescription ;
          inputs.forEach((input, index) => {
            console.log("input",input)
            if (index == 0) {
              name = input.value;
            } else if (index == 1) {
                company = input.value;
            } else if (index == 2) {
                street = input.value;
            } else if (index == 3) {
                city = input.value;
            }
            else if (index == 4) {
             postalcode = input.value;
            }
            else if (index == 5) {
                product = input.value;
            }
            else if (index == 6) {
                licencenumber = input.value;
            }
            else if (index == 7) {
                build = input.value;
            }
            else if (index == 8) {
                priority = input.value;
            }
            else if (index == 9) {
                problemtype = input.value;
            }
            else if (index == 10) {
                object = input.value;
            }
            else if (index == 11) {
                problemdescription = input.value;
            }

          });

          const isValid = validateEmail(email);
       
          if (isValid) {
            if (
              email != "" &&
              name != "" &&
              company != "" &&
              street != "" &&
              city != "" &&
              postalcode != "" &&
              phone != "" &&
              product != "" &&
              licencenumber != "" &&
              build != "" &&
              priority != "" &&
              problemtype != "" &&
              object != "" &&
              problemdescription != "" 

              
            ) {
                doSupport({
                    name: name,
                    email: email,
                    company: company,
                    street: street,
                    city: city,
                    postalcode: postalcode,
                    phonenumber: phone,
                    product: product,
                    licencenumber: licencenumber,
                    build: build,
                    priority: priority,
                    problemtype: problemtype,
                    object: object,
                    problemdescription: problemdescription,

                    
                  })
             
            }
          } else {
            console.log(`"${email}" is not a valid email address.`);
          }
        }
      });
    }
    }, 5000);

    return () => {
       // document.querySelector("#QuotesForm").removeChild(script);
      
    };
  }, []);

  return <div id="bitrix-form-container"></div>;
};

export default BitrixFormSupport;
