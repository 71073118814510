import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { SoftwareService } from "../api";

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetSoftwareData = (onSuccess, onError = onDefaultError) => {
  return useQuery(["software-view"], SoftwareService.getSoftwareData, {
    onSuccess,
    onError,
  });
};
export { useGetSoftwareData };
