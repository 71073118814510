import client from "../libs/HttpClient";

class NewsArticleServices {
  static viewNewsArticle(params) {
    console.log("params",params)
    return client.get(`/newsarticle`, { params });
  }
}

export { NewsArticleServices };
