import React from "react";
import { Link } from "react-router-dom";
import More from "../../../assets/images/icon/69.svg";
import Shape from "../../../assets/images/shape/137.svg";
import { useGetGeneralsettingData } from "../../../hooks";
import PropTypes from "prop-types";
const OurProduct = ({ product_data }) => {
  const { isLoading: settingLoading, data: setting_data } =
    useGetGeneralsettingData();
  let title = "";
  let subtitle = "";
  let subdescription = "";
  if (!settingLoading && setting_data && setting_data.data) {
    const { list_generalsetting } = setting_data.data;
    const titleSetting = list_generalsetting.find(
      (setting) =>
        setting.text_key === "home_page_general_setting_our_production_title_1"
    );
    const subtitleSetting = list_generalsetting.find(
      (setting) =>
        setting.text_key === "home_page_general_setting_our_production_title_2"
    );
    const subdescriptionSetting = list_generalsetting.find(
      (setting) =>
        setting.text_key ===
        "home_page_general_setting_our_production_sub_description"
    );
    title = titleSetting ? titleSetting.text_value : "";
    subtitle = subtitleSetting ? subtitleSetting.text_value : "";
    subdescription = subdescriptionSetting
      ? subdescriptionSetting.text_value
      : "";
  }
  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const featuresContent = [
    {
      icon: "144",
      title: "Contracts",
      desc: "Simplify the process to create proposals.",
      delayAnimation: "",
    },
    {
      icon: "145",
      title: "Proposal",
      desc: "Create interactive, error-free quotes.",
      delayAnimation: "100",
    },
    {
      icon: "146",
      title: "eSignature",
      desc: "Create interactive, error-free quotes.",
      delayAnimation: "200",
    },
    {
      icon: "147",
      title: "Collect Doc",
      desc: "Create interactive, error-free quotes.",
      delayAnimation: "300",
    },
  ];
  // const FeaturesContent = [
  //   {
  //     bgColor: "#F7EDFF",
  //     title: "GUI Builder",
  //     desc: `GUI Builder is a real-time operation control platform that provides supervisory control and data acquisition (SCADA) solution as a reliable and straightforward service.`,
  //     dataDealy: "200",
  //     link: "guibuilder",
  //   },
  //   {
  //     bgColor: "#E0F8F8",
  //     title: "GIOT Gateway",
  //     desc: `Industrial Gateways for full fill your need regarding connectivity and data collection.`,
  //     dataDealy: "100",
  //     link: "product-gateway",
  //   },
  //   {
  //     bgColor: "#F7EDFF",
  //     title: "GCOM Virtualizer",
  //     desc: `GComm virtualizes a virtual connection between virtual COM ports and virtual null-modem cable. It provides recent technology for designing especially for developing, testing, or debugging serial port either in software or hardware. Users can use virtual COM as many as they want.`,
  //     dataDealy: "200",
  //     link: "product-virtualizer",
  //   },

  //   {
  //     bgColor: "#FFEBDB",
  //     title: "GCOM over TCP",
  //     desc: `Gcom allows users to connect easily to the communication via TCP/IP protocol. It can directly connect with a remote port by using a TCP/IP connection.`,
  //     dataDealy: "0",
  //     link: "gcomovertcp",
  //   },
  // ];
  return (
    <div
      className="fancy-feature-ten  md-pt-0 font-gordita"
      id="features"
      data-aos="fade-right"
      data-aos-duration="1200"
    >
      <div className="bg-wrapper">
        {/* <div className="container">
          <div className="title-style-five text-center mb-60 md-mb-30">
            <h6>{title}</h6>
            <h2>
              <span>{subtitle}</span>
              
            </h2>
            <div
              className="col-md-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="title-style-six"></div>
              
            </div>
           

            <div
              className="col-lg- col-md-"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <p className="sub-text pt-30 pb-30">
              {subdescription}
              </p>
            </div>
           
          </div>
          <div className="row justify-content-center mt-35 md-mt-20">
            {product_data.map((item, i) => (
              <div
                className="col-lg-6 col-md-6"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay={item.dataDealy}
                key={i}
              >
                <Link to={item.link} className="mt-30">
                  <div className="block-style-fifteen mt-40">
                    <h3>{item.title}</h3>
                     <p className="truncate-text">{stripHtml(item.subdescription)}</p>

                    {item.title.substring(0, 50) && (
                      <Link to={`/${item.redirectlink}`} className="mt-30">
                        <img src={More} alt="icon" />
                      </Link>
                    )}
                    
                  </div>
                 
                </Link>
              </div>
            ))}
          </div>
        
        </div> */}
        <div className="container">
          <div className="title-style-five text-center mb-0 md-mb-0">
            <h6>{title}</h6>
            <h2>
              <span>{subtitle}</span>
            </h2>
            <div className="col-md-6">
              <div className="title-style-six"></div>
            </div>

            <div
              className="col-lg- col-md-"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <p className="sub-text pt-30 pb-0">{subdescription}</p>
            </div>
          </div>
          <div className="row">
            {product_data.map((item, i) => (
              <div
                className="col-lg-3 col-sm-6"
                data-aos="fade-up"
                data-aos-delay={item.delayAnimation}
                key={i}
              >
                <Link  to={`/${item.redirectlink}`} className="mt-0">
                  <div className="block-style-fifteen mt-40">
                    <h3>{item.title}</h3>
                    <p className="truncate-text">
                      {stripHtml(item.subdescription)}
                    </p>

                    {item.title.substring(0, 50) && (
                      <Link to={`/${item.redirectlink}`} className="mt-30">
                        <img src={More} alt="icon" />
                      </Link>
                    )}
                  </div>
                </Link>

                {/* /.block-style-twentySeven */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
OurProduct.propTypes = {
  product_data: PropTypes.object,
};

export { OurProduct };
