import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { TheMeticDemoService } from "../api";

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetTheMaticDemoData = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["the_matic_demo-view"],
    TheMeticDemoService.getTheMaticDemoData,
    {
      onSuccess,
      onError,
    }
  );
};
export { useGetTheMaticDemoData };
