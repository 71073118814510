import { toast } from "react-toastify";

import { NewsArticleServices } from "../api";
import { useQuery } from "react-query";

/**
 * Hooks for Authentication Process
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useViewNewsArticle = (id, onSuccess, onError = onDefaultError) => {
  console.log("id 111",id)
  return useQuery(
    "news-article-view",
    () => NewsArticleServices.viewNewsArticle({ id }),
    {
      onSuccess,
      onError,
    }
  );
};

export { useViewNewsArticle };
