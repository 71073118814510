/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { useGetHomeSvgData } from "../../hooks";

const PowerAndEnergyPage = () => {
  let fileNames = ["energyanimation", "energy"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <>
      <div className="fancy-hero-three">
       
        <div className="">
          <div className="container">
            <div className="row">
              {/* <div className="col-xl-9 col-lg-10 m-auto">
                <h1 className="heading">Power And Energy</h1>
              </div> */}
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "energyanimation") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-text-block-thirteen pt-40 md-pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Power And Energy</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                The power and energy industry can significantly enhance efficiency and reliability with GUIview’s advanced solutions, powered by GUI Builder and Cloud View. GUI Builder provides powerful tools for real-time monitoring and control of power generation, transmission, and distribution systems. Its intuitive interface allows seamless integration with various equipment, offering clear visualization of operational parameters and enabling swift response to system changes.
                </p>
                <br />
                <p>
                <b>Cloud View</b> further extends these capabilities by offering remote access to critical data and control functions. Operators and engineers can monitor power systems, receive alerts, and make informed decisions from anywhere, improving responsiveness and operational efficiency.</p><br/>
                <p>Together, GUI Builder and Cloud View deliver a robust platform for the power and energy industry, providing real-time data access, remote monitoring, and enhanced control. Trust GUIview to drive efficiency, reliability, and innovation in your power and energy operations with cutting-edge technology.</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "energy") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PowerAndEnergyPage;
