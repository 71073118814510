import client from "../libs/HttpClient";

class BlogArticleServices {
  static viewBlogArticle(params) {
    return client.get(`/blogarticle`, { params });
  }
  static listBlogArticle(params) {
    return client.get(`/blog`, { params });
  }
}

export { BlogArticleServices };
