import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MegaMenu from "../HeaderMenu/MegaMenu";
import logo from "../../../assets/images/logo.png";
import { logoutSuccess } from "../../../store/features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import AfterLoginMegaMenuMobile from "../HeaderMenu/AfterLoginMegaMenuMobile";
import { loggedUser } from "../../../store/features/authSlice";

const AfterSignuoNavbarHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const checkLoggenInUser = useSelector(loggedUser);

  const [navbar, setNavbar] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 68) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const handleLogout = () => {
    dispatch(logoutSuccess());
    navigate("/");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  return (
    <>
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-four bg-none fixed"
            : "theme-main-menu sticky-menu theme-menu-one bg-none"
        }
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="brand logo" />
            </Link>
          </div>

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div className="navbar-collapse collapse" id="navbarSupportedContent">
                <div className="d-lg-flex align-items-center">
                  <MegaMenu />
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link"
                        href="#"
                        onClick={toggleDropdown}
                      >
                        Hello, {checkLoggenInUser.user.name}
                      </a>
                      <ul className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}>
                        <li>
                          <Link className="dropdown-item" to="/edit-profile">
                            Edit Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={handleLogout}
                          >
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>

        <AfterLoginMegaMenuMobile />
      </div>
      {/* /.theme-main-menu */}
    </>
  );
};

export { AfterSignuoNavbarHeader };
