import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { TutorialServices } from "../api";

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetTutorialList = (onSuccess, onError = onDefaultError) => {
  console.log("useGetTutorialList", useGetTutorialList);
  return useQuery("tutorial-list", TutorialServices.getTutorialList, {
    onSuccess,
    onError,
  });
};

export { useGetTutorialList };
