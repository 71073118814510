import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useViewNewsArticle } from "../../hooks";
import { FILE_PATH } from "../../config";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
const NewsArticlePage = () => {
  const { id } = useParams();
  const [data, setData] = useState("");
  const [otherNews, setOtherData] = useState("");

  console.log("id",id)
  const { refetch } = useViewNewsArticle(id, ({ data: news }) => {
    if (news) {
      setData(news.data);
      setOtherData(news.data1);
    }
  });
  useEffect(() => {
    refetch();
  }, [id, refetch]);
  console.log(data, otherNews, "news");
  const removeHTMLTags = (input) => {
    if (typeof input === "string" || input instanceof String) {
      return input.replace(/<[^>]*>?/gm, "");
    } else {
      return input;
    }
  };
  return (
    <div>
      <div className="fancy-hero-three">
       
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
            <div className="title-style-one text-center mb-50 md-mb-20">
                <h2> News Brief</h2>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container"
        data-aos="fade-left"
        data-aos-duration="1200">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h2>{data?.title}</h2>
                </div>
                <p>{removeHTMLTags(data.description)}</p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              
            >
              <img src={`${FILE_PATH}/news/${data.images}`} alt="feature" className="image-meta mx-auto d-block"/>
            </div>
          </div>
        </div>
      </div>

      <div className="feature-blog-one pt-100 pb-120 md-pt-100 md-pb-60">
        <div className="container">
          <div className="row">
            <div className="m-auto">
              <div className="title-style-one text-center mb-50 md-mb-20">
                <h2>Read The Other News</h2>
              </div>
              {/* /.title-style-one */}
            </div>
          </div>
          <div className="row justify-content-center">
            {otherNews &&
              otherNews.map((item, i) => (
                <div
                  className="col-lg-4 col-md-6"
                
                  key={i}
                  data-aos-delay={item.dealyAnimation}
                >
                  <div className="post-meta">
                    <img
                      src={`${FILE_PATH}/news/${item.images}`}
                      alt="media"
                      className="image-meta mx-auto d-block"
                    />
                    <div className="tag">
                      {moment(item.createdAt).format("DD MMM YYYY")}
                    </div>
                    <h3>
                      <Link to={`/newsarticle/${item.id}`} className="title">
                        {item.title.substring(0, 50)}
                      </Link>
                    </h3>
                    <Link
                      to={`/newsarticle/${item.id}`}
                      className="read-more d-flex justify-content-between align-items-center"
                    >
                      <span>Read More</span>
                      <i className="flaticon-right-arrow"></i>
                    </Link>
                  </div>
                  {/* /.post-meta */}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsArticlePage;
