import React, { useEffect } from "react";

function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
const BitrixFormQuotes = ({doQuote}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://cdn.bitrix24.in/b30119693/crm/form/loader_36.js?" +
      ((Date.now() / 180000) | 0);
    script.setAttribute("data-b24-form", "inline/36/utoa4i");
    script.setAttribute("data-skip-moving", "true");
    console.log("script",script)
    document.querySelector("#QuotesForm").appendChild(script);

    setTimeout(() => {
      let button = document.querySelector("#QuotesForm form");
      console.log("button",button)
      button.addEventListener("submit", function (event) {
        const checkbox = document.querySelector('input[type="checkbox"]');

        const isChecked = checkbox.checked;
        console.log("isChecked",isChecked)
        if (isChecked == true) {
          const inputs = document.querySelectorAll('input[type="string"]');
          const email = document.querySelector('input[name="email"]').value;
            
          let name, contact, country, msg;
          inputs.forEach((input, index) => {
            console.log("input",input)

            console.log("value",input.value)
            console.log("index",index)
            if (index == 0) {
              name = input.value;
            } else if (index == 1) {
              contact = input.value;
            } else if (index == 2) {
              country = input.value;
            } else if (index == 3) {
              msg = input.value;
            }
          });

          const isValid = validateEmail(email);
         
         
          if (isValid) {
            if (
              email != "" &&
              name != "" &&
              contact != "" &&
              country != "" &&
              msg != ""
            ) {
                doQuote({
                    name: name,
                    email: email,
                    country: country,
                    contactnumber: contact,
                    message: msg,
                    
                  })
             
            }
          } else {
            console.log(`"${email}" is not a valid email address.`);
          }
        }
      });
    }, 5000);

    return () => {
       // document.querySelector("#QuotesForm").removeChild(script);
      
    };
  }, []);

  return <div id="bitrix-form-container"></div>;
};

export default BitrixFormQuotes;
