/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useGetHomeSvgData } from "../../hooks";
// import img1 from "../../assets/img/Product/Gui_builder/Forum.svg";

const IndustrialIotPage = () => {
  let fileNames = ["iiot"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <div>
      <div className="fancy-text-block-thirteen pt-140 md-pt-100" data-aos="fade-left"
              data-aos-duration="1200">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Industrial IoT</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                GUIview’s industrial IoT solutions, powered by GUI Builder and Cloud View, offer comprehensive connectivity and control for modern industrial environments. GUI Builder enables seamless integration with various devices and systems, supporting real-time data collection and visualization. Users can easily publish real-time tag values to cloud servers such as AWS, Azure, and MQTT through the IoT gateway module.</p><br/>
                <p><b>Cloud View</b> enhances these capabilities by allowing remote monitoring and control from anywhere in the world. This cloud-based solution enables users to manage and control tags via subscribe topics, ensuring continuous oversight and flexibility in operations.</p><br/>
                <p>Together, GUI Builder and Cloud View provide a robust platform for industrial IoT, empowering users with real-time data access, remote control, and enhanced operational efficiency. Trust GUIview to deliver reliable and scalable IoT solutions for your industrial needs.</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "iiot") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="title-style-two text-center mb-35 md-mb-10">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <h2>What do you need?</h2>
            </div>
          </div>
        </div>
        {/* End title-style-two */}

        <div className="row">
          <div className="col-xl-10 m-auto">
            <li
              
            >
              Flexible and effective monitoring of consumption is essential
            </li>
            <li
              
            >
              A clear project vision enables easy communication and alignment
              with your requirements.
            </li>
            <li
              
            >
              If you already have a system, locating proper data and its storage
              locations is crucial, as data may reside on different servers
              across various devices. Once located, data is transferred to the
              internet via gateways, which communicate using various protocols.
              Subsequently, it is uploaded to the internet using special IoT
              protocols. If data is not available, it can be generated from
              different sensors and I/O devices. These devices connect directly
              with IoT platforms to retrieve data and identify trends.
            </li>
            <li
             
            >
              When exchanging data between devices, gateways are necessary for
              reading and writing data.
            </li>
            <li
              
            >
              An IoT platform is essential for gaining insights into device
              data, allowing for monitoring and analysis. The platform manages
              data from different devices, storing it in the cloud or a local
              database. It also provides specialized dashboards for data
              analysis, generating reports, charts, and tables.
            </li>
            <li
              
            >
              For innovative solutions based on recent technologies and advanced
              IoT concepts, GuiView's Giot gateway technology is the ideal
              choice for your projects.
            </li>
          </div>
        </div>
        {/* End .row */}
      </div>
    </div>
  );
};

export default IndustrialIotPage;
