import { useQuery } from "react-query";

import { FeaturesService } from "../api";
import { toast } from "react-toastify";
const onDefaultError = (error) => {
  toast.error(error.message);
};

/**
 * Hooks for Home page data
 * */
const useGetFeaturesData = (onSuccess, onError = onDefaultError) => {
  return useQuery(["features-view"], FeaturesService.getFeaturesData, {
    onSuccess,
    onError,
  });
};



export { useGetFeaturesData };
