import { useQuery } from "react-query";

import { OurproductService } from "../api";
import { toast } from "react-toastify";
const onDefaultError = (error) => {
  toast.error(error.message);
};

/**
 * Hooks for Home page data
 * */
const useGetOurproductData = (onSuccess, onError = onDefaultError) => {
  return useQuery(["ourproduct-view"], OurproductService.getOurproductData, {
    onSuccess,
    onError,
  });
};



export { useGetOurproductData };
