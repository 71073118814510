import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useListBlogArticle } from "../../hooks";
import { FILE_PATH } from "../../config";

const BlogPage = () => {
  const [data, setData] = useState([]);
  const [pageTitle, setPageTitle] = useState('Blog Page Title'); // default title
  const [pageDescription, setPageDescription] = useState('Blog page description');
  const [pageKeywords, setPageKeywords] = useState('Blog, Articles, News'); // default keywords

  const onSuccess = (response) => {
    if (response && response.data) {
      setData(response.data);
    }
  };

  const onError = (error) => {
    console.error("Error fetching blog articles", error);
  };

  const { refetch } = useListBlogArticle(onSuccess, onError);

  useEffect(() => {
    refetch();
    if (data.length > 0) {
      const seo_title = data[0].seo_title || 'Blog Page Title';
      const seo_description = data[0].seo_description || 'Blog page description';
      const seo_keyword = data[0].seo_keyword || 'Blog, Articles, News';
      
      setPageTitle(seo_title);
      setPageDescription(seo_description);
      setPageKeywords(seo_keyword);
    }
  }, [data,refetch]);

  const removeHTMLTags = (input) => {
    if (typeof input === "string" || input instanceof String) {
      return input.replace(/<[^>]*>?/gm, "");
    } else {
      return input;
    }
  };

  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="og:title" content={pageTitle} />
        <meta name="title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta name="og:description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
        <meta name="og:keywords" content={pageKeywords} />
      </Helmet>

      <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-10 m-auto">
              <h2 className="font-gilroy-bold">Our Blog</h2>
            </div>
            <div className="col-lg-9 m-auto">
              <p className="font-rubik">You will find here our company news and latest update</p>
            </div>
          </div>
        </div>
      </div>

      <div className="feature-blog-one blog-page-bg">
        <div className="container">
          <div className="row">
            <div className="row justify-content-center">
              {data.map((item, i) => (
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  key={i}
                  data-aos-delay={item.dealyAnimation}
                >
                  <Link to={`/blogarticle/${item.id}`} className="post-meta">
                    <img
                      src={`${FILE_PATH}/blog/${item.images}`}
                      alt="media"
                      className="image-meta mx-auto d-block"
                    />
                    <div className="tag">{item.category}</div>
                    <h3 className="title">
                      {item.title.substring(0, 50)}
                    </h3>
                    <div className="read-more d-flex justify-content-between align-items-center">
                      <span>Read More</span>
                      <i className="flaticon-right-arrow"></i>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
