import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { ContactUsService } from "../api";

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useAddContactUs = (onSuccess, onError = onDefaultError) => {
  return useMutation(ContactUsService.addContactUs, {
    onSuccess,
    onError,
  });
};

export { useAddContactUs };
