import { useQuery } from "react-query";

import { TestimonialService } from "../api";
import { toast } from "react-toastify";
const onDefaultError = (error) => {
  toast.error(error.message);
};

/**
 * Hooks for Home page data
 * */
const useGetTestimonialData = (onSuccess, onError = onDefaultError) => {
  return useQuery(["testimonial-view"], TestimonialService.getTestimonialData, {
    onSuccess,
    onError,
  });
};



export { useGetTestimonialData };
