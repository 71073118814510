/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { useGetHomeSvgData } from "../../hooks";

const WaterAndWastePage = () => {
  let fileNames = ["wastewateranimation", "wastewater2"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <>
      <div className="fancy-hero-three">
        
        <div className="">
          <div className="container">
            <div className="row">
              {/* <div className="col-xl-9 col-lg-10 m-auto">
                <h1 className="heading">Water & Waste</h1>
              </div> */}
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "wastewateranimation") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-text-block-thirteen pt-40 md-pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Water and waste</h2><br/>
                </div>
                {/* /.title-style-two */}
                <p>
                The water and waste management industry can greatly benefit from GUIview’s advanced solutions, powered by GUI Builder and Cloud View. <b>GUI Builder</b> provides robust tools for real-time monitoring and control of water treatment plants, wastewater facilities, and distribution networks. Its intuitive interface allows seamless integration with various sensors and equipment, offering clear visualization and efficient management of critical processes.
                </p><br/>
                <p>
                <b>Cloud View</b> enhances these capabilities by offering remote access to vital data and control functions. Operators and managers can monitor systems, receive alerts, and make informed decisions from anywhere, improving operational efficiency and response times.
                </p><br/>
                <p>Together, GUI Builder and Cloud View deliver a comprehensive platform for water and waste management, providing real-time data access, remote monitoring, and enhanced control. Trust GUIview to support your water and waste operations with cutting-edge technology, ensuring efficiency and reliability in managing vital resources.</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
               {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "wastewater2") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
    </>
  );
};

export default WaterAndWastePage;
