import * as Yup from "yup";
let validationSchema = Yup.object({
  name: Yup.string("Name is Required").required("Name is Required"),
  company: Yup.string("Company is Required").required("Company is Required"),
  street: Yup.string("street is Required").required("street is Required"),
  city: Yup.string("City is Required").required("City is Required"),
  postalcode: Yup.string("Postalcode is Required").required(
    "Postalcode is Required"
  ),
  email: Yup.string("Email is Required")
    .required("Email is Required")
    .email("Email Must be Valid"),
  product: Yup.string("product is Required").required("product is Required"),
  licencenumber: Yup.string("licencenumber is Required").required(
    "licencenumber is Required"
  ),
  build: Yup.string("build is Required").required("build is Required"),

  priority: Yup.string("priority is Required").required("priority is Required"),

  object: Yup.string("object is Required").required("object is Required"),
  problemdescription: Yup.string("problemdescription is Required").required(
    "problemdescription is Required"
  ),
  phonenumber: Yup.string().required("Phonenumber must be 10 digits"),
  problemtype: Yup.string("problemtype is Required").required(
    "problemtype is Required"
  ),
});

export default validationSchema;
