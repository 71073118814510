import React from "react";
import { Helmet } from "react-helmet";

const AccessibilityPage = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Terms & Conditions || Deski-Saas & Software React Template
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-12">
              {/* Tab panes */}
              <div className="tab-content ">
                <div id="opt6">
                  <div className="col-xl-6 col-lg-6 m-auto terms-condition-container">
                    <h2 className="font-gilroy-bold">Accessibility</h2>
                    <div className="update-date">
                      Effective Date: 17/04/2024
                    </div>
                  </div>
                  <p>
                    PI Corporation is committed to facilitating and ensuring the
                    accessibility and usability of its applicable websites so
                    that all persons, including individuals with disabilities,
                    have full and equal enjoyment of PI Corporation’s online
                    goods, services, facilities, and accommodations.
                  </p>
                  <h3>Measures to Support Accessibility</h3>
                  <p>
                    At PI Corporation, we actively engage in a variety of
                    measures to support accessibility and inclusion across our
                    digital presence and physical workplaces:
                  </p>
                  <li>
                    Appoint a Chief People Officer to oversee diversity, equity
                    and inclusion and accountability for culture
                  </li>
                  <li>
                    Prioritize the inclusion and support of people with
                    disabilities within our Purpose, Causes & Values statements
                  </li>
                  <li>
                    Consider and include people with disabilities within our
                    design processes
                  </li>
                  <li>
                    Support and amplify the voices of people with disabilities
                    and allies through our Diverse Abilities Employee Resource
                    Group
                  </li>
                  <p>
                    Through encouraging social awareness of perceived
                    impairments and/or disabilities and highlighting the effect
                    of discrimination and social exclusion on individuals, PI
                    Corporation aims to promote understanding and move beyond
                    simple tolerance to embrace, celebrate, and utilize the rich
                    dimensions of diversity contained within PI Corporation.
                  </p>
                  <h3>Conformance Status & Feedback</h3>
                  <p>
                    PI Corporation is working to implement the relevant portions
                    of the World Wide Web Consortium’s Web Content Accessibility
                    as its website accessibility standard.
                  </p>
                  <p>
                    Please be aware that our efforts are ongoing. If, at any
                    time, you have specific questions or concerns about the
                    accessibility of any particular web page, please contact us.
                    If you do encounter an accessibility issue, please specify
                    the issue and web page in your communication. We will make
                    all reasonable efforts to ensure that the content is
                    accessible for you.
                  </p>
                </div>
              </div>
              {/*  /.tab-content */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessibilityPage;
