import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { BrochureService } from "../api";

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetBrochureData = (onSuccess, onError = onDefaultError) => {
  return useQuery(["brochure-view"], BrochureService.getBrochureData, {
    onSuccess,
    onError,
  });
};
export { useGetBrochureData };
