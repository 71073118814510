import { useMutation } from "react-query";
import { toast } from "react-toastify";

import { AuthServices } from "../api";

/**
 * Hooks for Authentication Process
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useLogin = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthServices.login, {
    onSuccess,
    onError,
  });
};
const useRegister = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthServices.register, {
    onSuccess,
    onError,
  });
};
const useVerification = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthServices.verification, {
    onSuccess,
    onError,
  });
};
const useResetpassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthServices.resetpassword, {
    onSuccess,
    onError,
  });
};
const useNewpassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthServices.newpassword, {
    onSuccess,
    onError,
  });
};
export { useLogin, useRegister, useVerification,useResetpassword,useNewpassword };
