/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { RadialGauge, LinearGauge } from "canvas-gauges";
import img1 from "../../../assets/img/Product/Gui_builder/unified_icon.png";
import img2 from "../../../assets/img/Product/Gui_builder/builder_icon.png";
import img3 from "../../../assets/img/Product/Gui_builder/openess_icon.png" 
import img4 from "../../../assets/img/Product/Gui_builder/support.jpg";
import { Link } from "react-router-dom";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetHomeSvgData } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useGetGeneralsettingData } from "../../../hooks/useRQGeneralsetting";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const LinearGaugeComponent = ({ width, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const options = {
      renderTo: canvasRef.current,
      width: width,
      height: height,
    };

    const gauge = new LinearGauge(options).draw();

    // Animation
    let value = 0;
    const animationInterval = setInterval(() => {
      gauge.value = value;
      gauge.update();
      value += 5; // Adjust the increment as needed
      if (value > 100) {
        value = 0; // Reset value when it reaches 100
      }
    }, 100); // Adjust the interval duration as needed

    return () => {
      clearInterval(animationInterval);
      gauge.destroy();
    };
  }, [width, height]);

  return <canvas ref={canvasRef} data-width={width} data-type="linear-gauge" />;
};

const RadialGaugeComponent = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const options = {
      renderTo: canvasRef.current,
    };

    const gauge = new RadialGauge(options).draw();
    // Continuous animation
    let value = 0;
    const animationInterval = setInterval(() => {
      gauge.value = value;
      gauge.update();
      value += 5; // Adjust the increment as needed
      if (value > 100) {
        value = 0; // Reset value when it reaches 100
      }
    }, 100); // Adjust the interval duration as needed

    return () => {
      clearInterval(animationInterval);
      gauge.destroy();
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  return <canvas ref={canvasRef} data-type="radial-gauge" />;
};

const GuiBuilderPage = (props) => {
  const navigate = useNavigate();
  const chartRef = useRef(null);
  const canvasRef = useRef(null);
  const [data1, setData] = useState([{ type: "line", dataPoints: [] }]);
  const { isLoading: settingLoading, data: setting_data } =
    useGetGeneralsettingData();
  const [pageTitle, setPageTitle] = useState("Default Page Title"); // default title
  const [pageDescription, setPageDescription] = useState("Default description");
  const [pageKeywords, setPageKeywords] = useState("Default keywords"); // default description

  let gauge;
  useEffect(() => {
    // Interval for updating data
    const intervalId = setInterval(() => {
      updateData();
    }, 1000);

    return () => {
      clearInterval(intervalId);

      if (gauge) {
        gauge.destroy();
      }
    };
  }, [props]);
  useEffect(() => {
    if (!settingLoading && setting_data) {
      const { list_generalsetting } = setting_data.data;
      const titleSetting = list_generalsetting.find(
        (setting) =>
          setting.text_key === "home_page_general_setting_gui_builder_seo_title"
      );
      const subtitleSetting = list_generalsetting.find(
        (setting) =>
          setting.text_key ===
          "home_page_general_setting_gui_builder_seo_description"
      );
      const keywordSetting = list_generalsetting.find(
        (setting) =>
          setting.text_key ===
          "home_page_general_setting_gui_builder_seo_keywords"
      );
      if (titleSetting && titleSetting.text_value) {
        setPageTitle(titleSetting.text_value);
      }
      if (subtitleSetting && subtitleSetting.text_value) {
        setPageDescription(subtitleSetting.text_value);
      }
      if (keywordSetting && keywordSetting.text_value) {
        setPageKeywords(keywordSetting.text_value);
      }

      // console.log("Title:", titleSetting?.text_value ?? "No title found");
      // console.log("Subtitle:", subtitleSetting?.text_value ?? "No subtitle found");
      // console.log("Keywords:", keywordSetting?.text_value ?? "No subtitle found");
    }
  }, [settingLoading, setting_data]);

  const updateData = () => {
    const currentDate = new Date();
    const y = Math.random();

    setData((prevData) => {
      const newDataSeries = { type: "line" };
      const newDataPoints = [
        ...prevData[0].dataPoints,
        { x: currentDate, y: y },
      ];
      newDataSeries.dataPoints = newDataPoints;
      return [newDataSeries];
    });
  };

  const spanStyle = {
    position: "absolute",
    top: "10px",
    fontSize: "20px",
    fontWeight: "bold",
    backgroundColor: "#d85757",
    padding: "0px 4px",
    color: "#ffffff",
  };

  const options = {
    zoomEnabled: true,
    animationEnabled: true,
    title: {},
    axisX: {
      // valueFormatString: "DD-MMM-YYYY HH:mm:ss",
    },
    axisY: {
      // minimum: 0,
      // maximum: 1,
    },
    data: data1,
  };

  let fileNames = [
    "guibuilder main page",
    "guibuildericon",
    "simplifieddevelopment",
    "animationcontrols",
    "symbollibrary",
    "iotgateway2",
    "alarammanagmant",
    "daconectivity",
    "security",
    "verticalgraphics",
    "recipemanagement_new",
    "data_source_new",
    "reporting_new",
    "data_logging_new",
    "clientserverarchitecture",
    "Iotpartnermodules"
  ];

  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  console.log(general_data,'general_data');
  const handleclick = () => {
    navigate("/quote");
  };

  return (
    <>
      <Helmet>
        <title>GUI View</title>
        <meta name="og:title" content={pageTitle} />
        <meta name="title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta name="og:description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
        <meta name="og:keywords" content={pageKeywords} />
      </Helmet>
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  {data &&
                    data.svgDataArray.map((val, index) => {
                      if (val.fileName === "guibuildericon") {
                        return <img key={index} src={val.svgDataURL}></img>;
                      } else {
                        return null; // Or you can choose to return some other element if the condition is not met
                      }
                    })}
                  <br />
                </div>

                {/* /.title-style-two */}
                <p>
                  GUI Builder is a real-time operation control platform that
                  provides supervisory control and data acquisition (SCADA)
                  solution as a reliable and straightforward service. It allows
                  user flexible and simpleness options in SCADA operations.
                  Builder provides various services along with a human-machine
                  interface (HMI).
                </p>
                <br />
                <p>
                  The main aim of our products is that manage your operation
                  with flexibility and agility. It provides the best solution
                  for Manufacturing Execution System (MES) &amp; SCADA software
                  from IIOT architecture. However, all integrated services allow
                  users to improve the visibility and power of decision-making.
                  It is a highly configured system that can help in your future
                  also
                </p>
                <br />
              </div>
              <div className="md-pt-0 mt-10">
                {/* Submit button */}
                <button
                  onClick={handleclick}
                  style={{ marginBottom: "30px" }}
                  className="theme-btn-two float-lg-right"
                >
                  Request a Quote
                </button>
              </div>
            </div>

            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "guibuilder main page") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-feature-twentyFour pt-10 md-pt-5" id="service">
        <div className="container">
          <div className="text-center">
            <div className="title-style-two"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100">
              <h2>Benefits Of Gui Builder</h2>
              <br />
            </div>
          </div>
        </div>
        <div className="bg-wrapper mt-0 md-mt-0">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex mb-35"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <div className="block-style-twentyFour">
                  <div className="d-flex align-items-start">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      //style={{ background: "#3FE193" }}
                    >
                      <img src={img1} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>Unified Infrastructure</h4>
                      <p>
                        "Our GUI builder provides a unified infrastructure,
                        seamlessly integrating all your systems for streamlined
                        operations."
                      </p>
                    </div>
                  </div>
                </div>
                {/* /.block-style-twentyFour */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-6 d-flex mb-35"
                data-aos="fade-up"
                data-aos-duration="1200"
                 data-aos-delay="100"
              >
                <div className="block-style-twentyFour">
                  <div className="d-flex align-items-start">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      //style={{ background: "#FF4F86" }}
                    >
                      <img src={img2} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>Lightweight Platform</h4>
                      <p>
                        "Experience the efficiency of a lightweight platform,
                        designed for fast performance and minimal resource
                        usage."
                      </p>
                    </div>
                  </div>
                </div>{" "}
                {/* /.block-style-twentyFour */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-6 d-flex mb-35"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <div className="block-style-twentyFour">
                  <div className="d-flex align-items-start">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      //style={{ background: "#FFCF39" }}
                    >
                      <img src={img3} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>Openness</h4>
                      <p>
                        "Benefit from our open architecture, enabling easy
                        integration and compatibility with diverse devices and
                        systems."
                      </p>
                    </div>
                  </div>
                </div>{" "}
                {/* /.block-style-twentyFour */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-6 d-flex mb-35"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <div className="block-style-twentyFour">
                  <div className="d-flex align-items-start">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      //style={{ background: "#6D64FF" }}
                    >
                      <img src={img4} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>Superior Support</h4>
                      <p>
                        "Rely on our superior support, offering top-tier
                        assistance and ensuring smooth, uninterrupted
                        operations."
                      </p>
                    </div>
                  </div>
                </div>{" "}
                {/* /.block-style-twentyFour */}
              </div>
              {/* End .col */}
            </div>
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>

      <div className="fancy-text-block-thirteen pt-20 md-pt-20">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="t">
                <div className="title-style-two">
                  <h2>Simplified Development</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  GUIBuilder is designed for simplicity and speed, allowing
                  quick setup and deployment of your SCADA system. Our intuitive
                  interface and streamlined functionalities save time and
                  resources.
                </p>
                <br />{" "}
                <p>
                  With GUIBuilder, you can complete projects faster using
                  ready-to-use objects and resources, eliminating the need for
                  programming and scripting. Our powerful editor features a
                  drag-and-drop system, making it easy for anyone to handle
                  complex solutions without specialized knowledge. .
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "simplifieddevelopment") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-80 md-pt-80">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Connectivity Drivers</h2>
                  <br />
                </div>
               
                <p>
                  GUI Builder SCADA software is equipped with extensive
                  connectivity options, supporting over 80 device drivers to
                  ensure seamless integration with a wide range of industrial
                  equipment. Key connectivity features include:
                </p>
                <br />
                <p>
                  <b>Modbus :</b> Comprehensive support for Modbus RTU, ASCII,
                  and TCP/IP protocols, facilitating communication with a
                  variety of devices.
                </p>

                <p>
                  <b>OPC UA and OPC DA :</b> Industry-standard protocols for
                  secure and reliable data exchange with diverse devices and
                  applications.
                </p>
              </div>
            </div>
            
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "daconectivity") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; 
                  }
                })}
            </div>
            
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-80 md-pt-80">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Reportings</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Empower your operations with robust and versatile reporting
                  capabilities. GUI Builder SCADA software includes multiple
                  reporting functions, enabling detailed data analysis and
                  decision-making:
                </p>
                <br />
                <p>
                  <b>Data Logger Reports :</b> Track and analyze historical data
                  to identify trends and improve processes.
                </p>
                <p>
                  <b>Alarm Reports :</b> Gain insights into system alarms for
                  enhanced safety and operational efficiency.
                </p>
                <p>
                  <b>Chart Reports :</b> Visualize data trends over time for
                  better understanding and analysis.
                </p>
                <p>
                  <b>Custom Live Reporting Windows :</b> Create and customize
                  live reporting windows to monitor real-time data.
                </p>
                <p>
                  <b>Scheduled Email Dispatch :</b> Automate the distribution of
                  reports through scheduled emails, ensuring timely information
                  sharing.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "reporting_new") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-80 md-pt-80">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Alarm Management &amp; Notification</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Enhance system reliability and responsiveness with advanced
                  alarm management features:
                </p>
                <br />
                <p>
                  <b>Alarm Monitoring :</b> Continuous monitoring of system
                  conditions with immediate notifications for any deviations.
                </p>
                <p>
                  <b>Notification Systems :</b> Receive alerts via email
                  notifications to ensure timely responses.
                </p>
                <p>
                  <b>Customizable Alarm Thresholds :</b> Set specific parameters
                  for different alarms to tailor the system to your operational
                  needs.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "alarammanagmant") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-0 md-pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Animation Controls</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Bring your SCADA system to life with a suite of ready-to-use
                  animation controls, enhancing visualization and interaction:
                </p>
                <br />
                <p>
                  <b>Animations :</b> Implement dynamic visualizations with
                  moving, scaling, rotating, and filling water height controls.
                </p>
                <p>
                  <b>Objects :</b> Utilize a wide range of objects including
                  charts, gauges, scales, tables, buttons, and displays for
                  detailed and interactive system representations.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "animationcontrols") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-0 md-pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Data Logging</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Ensure comprehensive and flexible data capture with GUI
                  Builder SCADA software’s robust data logging capabilities:
                </p>
                <br />
                <p>
                  <b>Condition-Based Logging :</b> Capture tag data when
                  specific conditions are met.
                </p>
                <p>
                  <b>Interval Logging :</b> Log data at particular time
                  intervals.
                </p>
                <p>
                  <b>Configurable Limits :</b> Configure logging limits by days
                  or raw counts.
                </p>
                <p>
                  <b>Database Support :</b> Log data to various databases
                  including MS Access, SQLite, and SQL-related databases.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "data_logging_new") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-0 md-pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Recipe Management</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Streamline production processes with efficient recipe
                  management features:
                </p>
                <br />
                <p>
                  <b>Recipe Creation and Management :</b> Easily create, manage,
                  and execute recipes for different production processes.
                </p>
                <p>
                  <b>Real-Time Monitoring :</b> Monitor recipe execution in
                  real-time to ensure consistency and quality control.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "recipemanagement_new") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-50 md-pt-50">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Data Source Connectivity</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Achieve seamless data integration with various sources through
                  robust connectivity options:
                </p>
                <br />
                <p>
                  <b>ODBC Database Connectivity :</b> Effortlessly connect to
                  ODBC-compliant databases.
                </p>
                <p>
                  <b>Query Tags :</b> Generate default and custom query tags for
                  tailored data retrieval and manipulation, enhancing data
                  accessibility and utilization.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "data_source_new") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-50 md-pt-50">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-first"
              // data-aos="fade-right"
              // data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Symbol Factory</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  The software provides many features, and all parts have
                  specific symbols. The GUI builder has an impressive library so
                  users can quickly build and update graphical displays on the
                  fly. One can find easily find the meaning of symbols; for
                  example, if a simple switch is turn on, one can see a green
                  light, whereas red light shows that the switch is turned off.
                </p>
                <br />
                <p>
                  There are other complex switches, dials, gauges, and other
                  icons available these could solve your problems. In the
                  software, we provide the software in which one can modify
                  objects and then re-upload them symbol libraries for your
                  future usage in the screens and projects. Apart from symbols,
                  the software allows adding any combination of tags, aliases,
                  expressions, or shapes to the object.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              // data-aos="fade-left"
              // data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "symbollibrary") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-120 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Client-Server Architecture</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Benefit from a robust and scalable system architecture with
                  our client-server model:
                </p>
                <br />
                <p>
                  <b>Centralized Control :</b> TCentralized server controls with
                  distributed client access.
                </p>
                <p>
                  <b>Scalability :</b> Easily scale your system by adding more
                  clients without affecting performance.
                </p>
                <p>
                  <b>Security :</b> Enhanced security features to protect
                  sensitive data and ensure secure communication between clients
                  and servers.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "clientserverarchitecture") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-120 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>IoT Partner Modules</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Expand your system’s capabilities with GUI Builder SCADA
                  software’s IoT partner modules, enabling advanced integration
                  with cloud services:
                </p>
                <br />
                <p>
                  <b>Real-Time Tag Publishing :</b> Publish real-time tag values
                  to cloud servers such as AWS, Azure, and MQTT through the IoT
                  gateway module.
                </p>
                <p>
                  <b>Remote Control :</b> Control tags from cloud servers using
                  subscribe topics, allowing for remote management and
                  monitoring of your industrial processes.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "Iotpartnermodules") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      {/* <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>IOT Gateway</h2>
                  <br />
                </div>
                
                <p>
                  GUI BUILDER provides service with the MQTT, which can give
                  instant integration of data in the SCADA and IIOT system. The
                  server of MQTT can play a vital role in the server connection
                  as it can be configured with a hybrid approach (on-premise,
                  cloud).
                </p>
                <br />
                <p>
                  MQTT is considered a lightweight IoT protocol, so it can save
                  our workload time, resulting in increased productivity. IIOt
                  solution and different SCADA applications MQTT can be used
                  with IoT gateways. This kind of combination allows users to
                  get highly effective data for SCADA solutions. Moreover, users
                  can accept high performance by using this kind of system.
                </p>
              </div>
            </div>
           
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "iotgateway2") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; 
                  }
                })}
            </div>
          
          </div>
        </div>
      </div> */}

      

      

      

      {/* <div className="fancy-text-block-thirteen pt-120 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Security protection</h2>
                  <br />
                </div>
                
                <p>
                  GUI builder integrates with top-level security. We understand
                  the threat of network security and know our responsibility
                  regarding your risk that is attached to use any software.
                </p>
                <br />
                <p>
                  The system cannot access by the unauthorized user as we are
                  providing a proper authentication system for data access.
                  There are many layers of security available to secure your
                  data. Apart from system security, we also store your data in
                  an encrypted manner. Thus, your system and database are secure
                  for completing your projects.
                </p>
              </div>
            </div>
           
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "security") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; 
                  }
                })}
            </div>
          
          </div>
        </div>
      </div> */}

      {/* <div className="fancy-text-block-thirteen pt-120 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2> Vectorial Graphics</h2>
                  <br />
                </div>
               
                <p>
                  GUI builder offers a vector graphics engine based on Scalable
                  Vectorial Graphics (SVG). Users can use these graphics by drag
                  and drop. By using these kinds of pictures, users can save
                  their precious time and stay focus on their work.
                </p>
                <br />
                <p>
                  Vectorial graphics are independent of screen resolution, and
                  it can be used with multi-touch. The system has a collection
                  of vector graphics, all of our unique and well designed, for
                  your projects' work. Various types of pictures are supported,
                  such as BMP, GIF, JPG, WMF, EMF, and multimedia.
                </p>
              </div>
            </div>
            
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "verticalgraphics") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; 
                  }
                })}
            </div>
            
          </div>
        </div>
      </div> */}

      

      

     

    

      {/* <div className="fancy-text-block-thirteen pt-120 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Chart and Gauge</h2>
                  <br />
                </div>
                

                <p>
                  Charts and Gauges play a vital role in a Manufacturing
                  Execution System (MES), and the gauge tool assists users to
                  get them accurately. It supports many types of gauges and
                  charts and it remains easy to use with the drag-and-drop
                  functionality. Users just need to select its properties.
                </p>
                <br />
                <p>
                  They can also customize the gauge as this tool supports
                  multiple types of gauges including linear gauge, round
                  (circular) gauge, thermometer style, etc. The chart feature in
                  the GUI Builder is fixed but you can configure various
                  parameters as per your requirement. This feature enables you
                  to make a line chart, bar chart, etc.
                </p>
              </div>
            </div>
            
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div style={{ flex: 1 }}>
                <div>
                  <CanvasJSChart
                    options={options}
                    onRef={(ref) => (chartRef.current = ref)}
                  />
                  <span id="timeToRender" style={spanStyle}></span>
                </div>
              </div>
              <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <LinearGaugeComponent width={100} height={200} />
                </div>
                <div style={{ flex: 1, marginTop: 20 }}>
                  <RadialGaugeComponent />
                </div>
              </div>
            </div>
           
          </div>
          
        </div>
      </div> */}

     

      
      <br></br>
      <section
        className="bg-color-orange position-relative border-0  m-0"
        style={{
          backgroundImage:
            "linear-gradient(to right, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #8d3d86 100%)",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <div className="container">
          <div className="row text-white text-center align-items-center">
            <div className="col-md-6">
              <h2
                style={{ fontSize: "25px", color: "white", marginBottom: "0" }}
              >
                Download and try it now!
              </h2>
            </div>
            <div className="col-md-6 mt-4">
              <div className="d-flex flex-column align-items-center">
                <Link
                  to="/contact-us"
                  className="btn"
                  style={{
                    backgroundColor: "#000",
                    color: "white",
                    height: "45px",
                    width: "70%",
                    fontSize: "15px",
                    border: "none",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none", // to remove default underline
                  }}
                >
                  Talk with our experts
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: "8px" }}
                  />
                </Link>
                <Link
                  to="/software"
                  className="btn"
                  style={{
                    backgroundColor: "#000",
                    color: "white",
                    height: "45px",
                    width: "70%",
                    fontSize: "15px",
                    border: "none",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none", // to remove default underline
                  }}
                >
                  Download product
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: "8px" }}
                  />
                </Link>
                <Link
                  to="/tutorial"
                  className="btn"
                  style={{
                    backgroundColor: "#000",
                    color: "white",
                    height: "45px",
                    width: "70%",
                    fontSize: "15px",
                    border: "none",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none", // to remove default underline
                  }}
                >
                  Video tutorial
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: "8px" }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GuiBuilderPage;
