import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import img1 from "../../../assets/img/Product/Gui_builder/alarmmanager.gif";
import img3 from "../../../assets/img/Product/Gui_builder/connectivity.png";
import img4 from "../../../assets/img/Product/Gui_builder/server_gateway.png";
import img5 from "../../../assets/img/Product/Gui_builder/iot.png";
import img6 from "../../../assets/img/Product/Gui_builder/configuration.png";
import { RadialGauge, LinearGauge } from "canvas-gauges";
import {
  faChevronCircleRight,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img2 from "../../../assets/img/Product/Gui_builder/icon.png";
import { useGetHomeSvgData } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import CanvasJSReact from "@canvasjs/react-charts";
import { Helmet } from "react-helmet";
import { useGetGeneralsettingData } from "../../../hooks/useRQGeneralsetting";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const LinearGaugeComponent = ({ width, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const options = {
      renderTo: canvasRef.current,
      width: width,
      height: height,
    };

    const gauge = new LinearGauge(options).draw();

    // Animation
    let value = 0;
    const animationInterval = setInterval(() => {
      gauge.value = value;
      gauge.update();
      value += 5; // Adjust the increment as needed
      if (value > 100) {
        value = 0; // Reset value when it reaches 100
      }
    }, 100); // Adjust the interval duration as needed

    return () => {
      clearInterval(animationInterval);
      gauge.destroy();
    };
  }, [width, height]);

  return <canvas ref={canvasRef} data-width={width} data-type="linear-gauge" />;
};

const RadialGaugeComponent = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const options = {
      renderTo: canvasRef.current,
    };

    const gauge = new RadialGauge(options).draw();
    // Continuous animation
    let value = 0;
    const animationInterval = setInterval(() => {
      gauge.value = value;
      gauge.update();
      value += 5; // Adjust the increment as needed
      if (value > 100) {
        value = 0; // Reset value when it reaches 100
      }
    }, 100); // Adjust the interval duration as needed

    return () => {
      clearInterval(animationInterval);
      gauge.destroy();
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  return <canvas ref={canvasRef} data-type="radial-gauge" />;
};

const CloudViewPage = (props) => {
  const chartRef = useRef(null);
  const navigate = useNavigate();
  const [data1, setData] = useState([]);
  const { isLoading: settingLoading, data: setting_data } =
    useGetGeneralsettingData();
  const [pageTitle, setPageTitle] = useState("Default Page Title"); // default title
  const [pageDescription, setPageDescription] = useState("Default description");
  const [pageKeywords, setPageKeywords] = useState("Default keywords"); // default description

  let gauge;
  useEffect(() => {
    // Interval for updating data
    const intervalId = setInterval(() => {
      updateData();
    }, 1000);

    return () => {
      clearInterval(intervalId);

      if (gauge) {
        gauge.destroy();
      }
    };
  }, [props]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateData();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  useEffect(() => {
    if (!settingLoading && setting_data) {
      const { list_generalsetting } = setting_data.data;
      const titleSetting = list_generalsetting.find(
        (setting) =>
          setting.text_key === "home_page_general_setting_cloud_view_seo_title"
      );
      const subtitleSetting = list_generalsetting.find(
        (setting) =>
          setting.text_key ===
          "home_page_general_setting_cloud_view_seo_description"
      );
      const keywordSetting = list_generalsetting.find(
        (setting) =>
          setting.text_key ===
          "home_page_general_setting_cloud_view_seo_keywords"
      );
      if (titleSetting && titleSetting.text_value) {
        setPageTitle(titleSetting.text_value);
      }
      if (subtitleSetting && subtitleSetting.text_value) {
        setPageDescription(subtitleSetting.text_value);
      }
      if (keywordSetting && keywordSetting.text_value) {
        setPageKeywords(keywordSetting.text_value);
      }

      // console.log("Title:", titleSetting?.text_value ?? "No title found");
      // console.log("Subtitle:", subtitleSetting?.text_value ?? "No subtitle found");
      // console.log("Keywords:", keywordSetting?.text_value ?? "No subtitle found");
    }
  }, [settingLoading, setting_data]);

  const updateData = () => {
    const newData1 = generateRandomData();
    const newData2 = generateRandomData();
    setData([
      { type: "line", dataPoints: newData1 },
      { type: "line", dataPoints: newData2 },
    ]);
  };

  const generateRandomData = () => {
    const newData = [];
    for (let i = 0; i <= 250; i++) {
      const x = i;
      const y = Math.random();
      newData.push({ x, y });
    }
    return newData;
  };

  const options = {
    theme: "light2",
    animationEnabled: true,
    title: {},
    axisX: {
      minimum: 0,
      maximum: 250,
    },
    axisY: {
      minimum: 0,
      maximum: 1,
    },
    data: data1,
  };

  let fileNames = [
    "iiot",
    "cloudviewicon",
    "iot editor",
    "reportingcloud",
    "iottunnel",
    "dragdrop",
    "iotdataloading",
    "iotsecurity",
    "iotrecipe",
    "iotanimation",
    "connectivitydrivers",
    "datasource",
    "symbollibrary",
    "alarammanagmantcloud",
    "securewebaccesscloud",
    "animationcontrolcloud"
  ];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  const handleclick = () => {
    navigate("/quote");
  };

  return (
    <div>
      <Helmet>
        <title>GUI View</title>
        <meta name="og:title" content={pageTitle} />
        <meta name="title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta name="og:description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
        <meta name="og:keywords" content={pageKeywords} />
      </Helmet>
      <div className="fancy-text-block-nine mt-130 md-mt-80">
        <div className="container">
          {/* <div className="title-style-two text-center mb-35 md-mb-10">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <h2>Cloud View</h2>
              </div>
            </div>
          </div> */}
          {/* End title-style-two */}

          {/* <div className="row">
            <div className="col-xl-10 m-auto">
              <p
                className="text-meta"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                Industrial Gateways for full fill your need regarding
                connectivity and data collection.
              </p>
            </div>
          </div> */}
          <div className="fancy-text-block-thirteen pt-50 md-pt-100">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-lg-6 order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="">
                    <div className="title-style-two">
                      <h2>
                        {data &&
                          data.svgDataArray.map((val, index) => {
                            if (val.fileName === "cloudviewicon") {
                              return (
                                <img key={index} src={val.svgDataURL}></img>
                              );
                            } else {
                              return null; // Or you can choose to return some other element if the condition is not met
                            }
                          })}
                      </h2>
                      <br />
                    </div>
                    {/* /.title-style-two */}
                    <p>
                      CloudView IIoT Gateway: Enhance Your Industrial Automation
                    </p>
                    <br />
                    <p>
                      The CloudView IIoT Gateway is a versatile and robust
                      solution designed to enhance your industrial automation
                      infrastructure. With two operational modes, pass-through
                      tunnel mode and web SCADA mode, this gateway offers
                      unmatched flexibility and functionality.
                    </p>
                  </div>
                  <div className="md-pt-0 mt-10">
                    {/* Submit button */}
                    <button
                      onClick={handleclick}
                      style={{ marginBottom: "30px" }}
                      className="theme-btn-two float-lg-right"
                    >
                      Request a Quote
                    </button>
                  </div>
                </div>
                {/* End .col */}

                <div
                  className="col-lg-6 col-md-8 m-auto order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  {data &&
                    data.svgDataArray.map((val, index) => {
                      if (val.fileName === "iot editor") {
                        return (
                          <object key={index} data={val.svgDataURL}></object>
                        );
                      } else {
                        return null; // Or you can choose to return some other element if the condition is not met
                      }
                    })}
                </div>
                {/* End .col */}
              </div>
            </div>
          </div>
          {/* End .row */}
        </div>
      </div>

      <div className="fancy-feature-twentyFour pt-50 md-pt-5" id="service">
        <div className="container">
          <div className="text-center">
            <div className="title-style-two"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100">
              <h2>Benefits of CloudView IIoT Gateway</h2>
              <br />
            </div>
          </div>
        </div>
        <div className="bg-wrapper mt-0 md-mt-0">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex mb-35"
               data-aos="fade-up"
                 data-aos-duration="1200"
              >
                <div className="block-style-twentyFour">
                  <div className="d-flex align-items-start">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      //style={{ background: "#3FE193" }}
                    >
                      <img src={img6} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>Easy Configurable</h4>
                      <p>
                        "The CloudView IIoT Gateway is designed for quick and
                        easy configuration, allowing you to set up and deploy
                        your industrial automation system with minimal effort
                        and downtime."
                      </p>
                    </div>
                  </div>
                </div>
                {/* /.block-style-twentyFour */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-6 d-flex mb-35"
                 data-aos="fade-up"
                 data-aos-duration="1200"
                 data-aos-delay="100"
              >
                <div className="block-style-twentyFour">
                  <div className="d-flex align-items-start">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      //style={{ background: "#FF4F86" }}
                    >
                      <img src={img5} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>
                        All-in-One IIoT Gateway - Remote Access Tunnel and Web
                        SCADA
                      </h4>
                      <p>
                        "Combining remote access tunnel and web SCADA
                        capabilities, CloudView IIoT Gateway offers an
                        all-in-one solution for comprehensive device management
                        and real-time monitoring from any location."
                      </p>
                    </div>
                  </div>
                </div>{" "}
                {/* /.block-style-twentyFour */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-6 d-flex mb-35"
                 data-aos="fade-up"
               data-aos-duration="1200"
              >
                <div className="block-style-twentyFour">
                  <div className="d-flex align-items-start">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      //style={{ background: "#FFCF39" }}
                    >
                      <img src={img3} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>Maximum Connectivity</h4>
                      <p>
                        "Ensures connectivity with diverse devices, enhancing
                        operational efficiency and data flow."
                      </p>
                    </div>
                  </div>
                </div>{" "}
                {/* /.block-style-twentyFour */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-6 d-flex mb-35"
                data-aos="fade-up"
                data-aos-duration="1200"
                 data-aos-delay="100"
              >
                <div className="block-style-twentyFour">
                  <div className="d-flex align-items-start">
                    <div
                      className="icon d-flex align-items-center justify-content-center"
                      //style={{ background: "#6D64FF" }}
                    >
                      <img src={img4} alt="icon" />
                    </div>
                    <div className="text">
                      <h4>Server Gateway</h4>
                      <p>
                        <b>
                          "CloudView device hosts the application locally,
                          enabling access through our server from anywhere.
                          Users can also access the application using a local
                          IP, providing flexibility and secure remote
                          connectivity."
                        </b>
                      </p>
                    </div>
                  </div>
                </div>{" "}
                {/* /.block-style-twentyFour */}
              </div>
              {/* End .col */}
            </div>
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Remote Access Tunnel</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  In tunnel mode, the CloudView Gateway enables remote access to
                  user-side devices via a Windows-based application, supporting
                  both serial and TCP devices. For serial devices, it generates
                  a virtual serial port accessible from anywhere, while for TCP
                  devices, it creates a local TCP server with a localhost IP
                  address. This remote access tunnel also facilitates uploading
                  or downloading PLC programs from any location, ensuring
                  seamless VPN-based access to PLCs and user-side devices.
                </p>
                <br />
                <p>
                  For the remote access of serial devices, our Windows-based
                  application can generate a virtual serial port and give it to
                  the end application. In a way, users can access a serial
                  device from anywhere. Similarly, for TCP (Transmission Control
                  Protocol) device, our application creates a TCP server for the
                  local host and gives the IP of a local host to the end
                  application.
                </p>
                <br />
                <p>
                  The remote access tunnel also facilitates the upload or
                  download of the PLC program from anywhere. The end application
                  based on VPN can access PLC from anywhere, and the user-side
                  devices remain accessible remotely for the application.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "iottunnel") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Connectivity Drivers</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Supporting over 80 device drivers, the CloudView IIoT Gateway
                  ensures smooth integration with numerous industrial devices.
                  Key protocols supported include:
                </p>
                <br />
                <p>
                  <li>Modbus (RTU, TCP)</li>
                  <li>OPC UA</li>
                  <li>Ethernet/IP</li>
                  <li>BACnet</li>
                  <li>Siemens S7</li>
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "connectivitydrivers") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Comprehensive Reporting</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  CloudView Gateway features comprehensive reporting
                  capabilities, including:
                </p>
                <br />
                <p>
                  <b>Tag Table Reports : </b> Detailed reports of tag data for
                  analysis and troubleshooting.
                </p>
                <p>
                  <b>Customizable Live Reporting :</b> Create and customize live
                  reporting windows to monitor real-time data.
                </p>
                <p>
                  <b>Scheduled Email Reports :</b> Automatically generate and
                  send reports via email at predefined intervals.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "reportingcloud") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Alarm Management & Notifications</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Efficiently manage alarms and notifications with features such
                  as:
                </p>
                <br />
                <p>
                  <b>Real-time Alarm Monitoring :</b> Instantaneous detection
                  and reporting of alarm conditions.
                </p>
                <p>
                  <b>Custom Notification Settings :</b> Notification options
                  including email alerts to ensure timely responses to critical
                  alarms.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
               {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "alarammanagmantcloud") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Advanced Animation Controls</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Enhance your IIoT application interface with ready-to-use
                  animation controls, including:
                </p>
                <br />
                <p>
                  <b>Moving :</b> Animate objects to represent real-time
                  processes.
                </p>
                <p>
                  <b>Scaling :</b> Adjust object size dynamically based on data
                  values.
                </p>
                <p>
                  <b>Rotating :</b> Rotate objects to reflect changes in
                  operational status.
                </p>
                <p>
                  <b>Fill Water Height :</b> Visualize tank levels or other
                  variable capacities.
                </p>
                <p>
                  <b>Objects Library :</b> Access a wide variety of charts,
                  gauges, scales, tables, buttons, and displays to build
                  intuitive interfaces.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "animationcontrolcloud") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Efficient Data Logging</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>Capture and store tag data efficiently :</p>
                <br />
                <p>
                  <b>Configurable Intervals : </b>Log data at user-defined
                  intervals to capture critical information.
                </p>
                <p>
                  <b>Internal Database : </b>Store data locally with
                  configurable retention periods.
                </p>
                <p>
                  <b>External SQL Databases : </b>Support for SQL-related
                  databases, enabling long-term storage and complex queries.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "iotdataloading") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Streamlined Recipe Management</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>Easily manage and deploy recipes for process automation :</p>
                <br />
                <p>
                  <b>Recipe Creation : </b>Define and save sets of parameters
                  for various production processes.
                </p>
                <p>
                  <b>Recipe Deployment :</b>Quickly apply saved recipes to
                  streamline operations and ensure consistency.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "iotrecipe") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Flexible Data Sources</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>Connect to data logger databases effortlessly :</p>
                <br />
                <p>
                  <b>Default Queries : </b>Utilize pre-configured queries for
                  common data retrieval needs.
                </p>
                <p>
                  <b>Custom Queries :</b>Create and execute custom queries to
                  tailor data access to specific requirements.
                </p>
                <p>
                  <b>Real-time Visualization : </b>Use retrieved data to update
                  control elements in real time, providing immediate insight
                  into operations.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "datasource") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Extensive Symbol Factory</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Leverage an extensive library of pre-designed symbols to
                  create professional and informative SCADA displays:
                </p>
                <br />
                <p>
                  <b>Standard Symbols :</b> Use industry-standard symbols for
                  clarity and consistency.
                </p>
                <p>
                  <b>Custom Symbols :</b> Design and integrate custom symbols to
                  match specific operational needs.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "symbollibrary") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-50 md-pt-50">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Secure Web Browser Access</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Access the CloudView application remotely via any web browser:
                </p>
                <br />
                <p>
                  <b>Cross-platform Compatibility :</b> Compatible with major
                  web browsers on various devices.
                </p>
                <p>
                  <b>Secure Access :</b> Ensure secure data transmission and
                  user authentication for remote access
                </p>
                <p>
                  <b>Real-time Monitoring and Control :</b> Perform real-time
                  monitoring and control from any location with internet access.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "securewebaccesscloud") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-50 md-pt-50">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2> IoT Integration Modules</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Seamlessly integrate with leading IoT platforms to enhance
                  data sharing and control:
                </p>
                <br />
                <p>
                  <b>AWS :</b> Publish real-time tag values to AWS IoT services
                  and control tags via subscribe topics.
                </p>
                <p>
                  <b>Azure :</b> Utilize Azure IoT Hub for data integration and
                  remote control.
                </p>
                <p>
                  <b>MQTT :</b> Connect to MQTT cloud servers for lightweight
                  and efficient data communication.
                </p>
                <br />
                <p>
                  The CloudView IIoT Gateway is engineered to provide
                  comprehensive, real-time insights and control over your
                  industrial processes, ensuring enhanced productivity and
                  efficiency across your operations.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "iiot") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
     

      
      {/* <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Drag and Drop</h2>
                  <br />
                </div>
                
                <p>
                  hmi designing easy as drag n drop the object and give it to
                  property that object, designer editor can also draw the
                  different object and grouping or ungrouping multiple object,
                  designer editor has many more common features like your
                  favourite editor. Many objects readymade and use with select n
                  draw.It has many readymade objects that you can use with the
                  feature ‘Select n Draw’. A large library for symbols and
                  vectorial graphics is inbuilt into the Designer Editor which
                  helps you make various objects as and when you want.
                </p>
                <br />
                <p>
                  GIOT Gateway is a robust IIoT operation control system for
                  manufacturing, automotive, F&B, and other core industries. As
                  a reliable and user-friendly industrial IoT gateway, it offers
                  easy-to-use features with seamless functionality. With the
                  drag-and-drop facility in HMI designing, users can easily drag
                  the objective to give it a necessary property. Our Designer
                  Editor can also draw different objects as per the user’s
                  requirements while grouping or ungrouping multiple objects.
                </p>
              </div>
            </div>
           
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "dragdrop") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; 
                  }
                })}
            </div>
            
          </div>
        </div>
      </div> */}

      

      {/* <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Chart and Gauge</h2>
                  <br />
                </div>
               
                <p>
                  The Chart and Gauge feature is configurable in our operation
                  control system based on IIoT. It assists users to get
                  customized charts and gauges accurately. Industrial machinery
                  and manufacturing sectors can leverage the benefits of this
                  feature for getting a gauge design and chart as per the
                  requirement. The GIOT Gateway application has a configurable,
                  inbuilt functionality for making gauges with a drag-and-drop
                  facility.
                </p>
                <br />
                <p>
                  The Chart and Gauge feature supports multiple types of gauges.
                  All you need to configure the necessary parameters and the
                  inbuilt functionality will do the rest. It enables you to get
                  accurate gauges and charts for various projects.
                </p>
              </div>
            </div>
            
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div style={{ flex: 1 }}>
                <div>
                  <CanvasJSChart
                    options={options}
                    onRef={(ref) => (chartRef.current = ref)}
                  />
                </div>
              </div>
              <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <LinearGaugeComponent width={100} height={200} />
                </div>
                <div style={{ flex: 1, marginTop: 20 }}>
                  <RadialGaugeComponent />
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div> */}

      

     {/* <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Device driver and iot gateway</h2>
                  <br />
                </div>

                <p>
                  An IIoT-based operation control is one of the key
                  characteristics of the IIoT Gateway application. The
                  application has a powerful device driver that supports many
                  brands, PLCs, controllers, OPC UA, and other IoT gateways. We
                  ensure maximum connectivity with the help of a device driver.
                  It enables the system to communicate effectively with all
                  types of devices. Here is a brief understanding of the device
                  driver functionality.
                </p>
                <br />
                <p>
                  When connecting the software with specific devices, systems,
                  and other applications, the device driver plays a vital role.
                  This built-in driver is free of cost and you can get it with
                  the purchased software. The driver has OPC UA and DA
                  connectivity to ensure seamless access to multiple devices.
                  The OPC UA connectivity enables users to access features like
                  past events and hierarchies, whereas the OPC DA is useful for
                  accessing the current data stored in a local legacy OPC
                  server.
                </p>
              </div>
            </div>
            
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "iotdeviceconnectivity") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; 
                  }
                })}
            </div>
            
          </div>
        </div>
      </div> */}
      <br></br>
      <section
        className="bg-color-orange position-relative border-0  m-0"
        style={{
          backgroundImage:
            "linear-gradient(to right, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #8d3d86 100%)",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <div className="container">
          <div className="row text-white text-center align-items-center">
            <div className="col-md-6">
              <h2
                style={{ fontSize: "25px", color: "white", marginBottom: "0" }}
              >
                Download and try it now!
              </h2>
            </div>
            <div className="col-md-6 mt-4">
              <div className="d-flex flex-column align-items-center">
                <Link
                  to="/contact-us"
                  className="btn"
                  style={{
                    backgroundColor: "#000",
                    color: "white",
                    height: "45px",
                    width: "70%",
                    fontSize: "15px",
                    border: "none",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none", // to remove default underline
                  }}
                >
                  Talk with our experts
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: "8px" }}
                  />
                </Link>
                <Link
                  to="/software"
                  className="btn"
                  style={{
                    backgroundColor: "#000",
                    color: "white",
                    height: "45px",
                    width: "70%",
                    fontSize: "15px",
                    border: "none",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none", // to remove default underline
                  }}
                >
                  Download product
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: "8px" }}
                  />
                </Link>
                <Link
                  to="/tutorial"
                  className="btn"
                  style={{
                    backgroundColor: "#000",
                    color: "white",
                    height: "45px",
                    width: "70%",
                    fontSize: "15px",
                    border: "none",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none", // to remove default underline
                  }}
                >
                  Video tutorial
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: "8px" }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CloudViewPage;
