// import libs
import { React, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { PageNotFound, ScrollTopBehaviour } from "../common/components";
import HomePage from "../pages/Home/HomePage";
import AuthRoute from "./AuthRoute";
import LoginPage from "../pages/Auth/Login/LoginPage";
import SignUpPage from "../pages/Auth/SignUp/SignUpPage";
import VerificationPage from "../pages/Auth/UserVerification/VerificationPage";
import ContactUsPage from "../pages/Contact Us/ContactUsPage";
import QualityPage from "../pages/Quality & Research/QualityPage";
import CareerPage from "../pages/Career/CareerPage";
import AboutUsPage from "../pages/About Us/AboutUsPage";
import ManualPage from "../pages/Download/Manual/ManualPage";
import GuiBuilderPage from "../pages/Product/GuiBuilder/GuiBuilderPage";
import EnergyEfficencyPage from "../pages/Solution/EnergyEfficencyPage";
import BrochurePage from "../pages/Download/Brochure/BrochurePage";
import TheMaticDemoPage from "../pages/Download/TheMaticDemo/TheMaticDemoPage";
import SoftwarePage from "../pages/Download/Software/SoftwarePage";
import CloudViewPage from "../pages/Product/IotGatway/CloudViewPage";
import SerialVirtualizerPage from "../pages/Product/Virtual Serial Port/SerialVirtualizerPage";
import SerialOverEthernetPage from "../pages/Product/Virtual Serial Port/SerialOverEthernetPage";
import AlarmingPage from "../pages/Solution/AlarmingPage";
import HistorianDataPage from "../pages/Solution/HistorianDataPage";
import IndustrialIotPage from "../pages/Solution/IndustiralIotPage";
import OpcUaPage from "../pages/Solution/OpcUaPage";
import ProductionAnalysisPage from "../pages/Solution/ProductionAnalysisPage";
import ScadaPage from "../pages/Solution/ScadaPage";
import AirportPage from "../pages/Industry/AirportPage";
import AutomotivePage from "../pages/Industry/AutomotivePage";
import BuildingAutomationPage from "../pages/Industry/BuildingAutomationlPage";
import DataCenterPage from "../pages/Industry/DataCenterPage";
import FoodBeveragePage from "../pages/Industry/FoodAndBeveragePage";
import OilAndGasPage from "../pages/Industry/OilAndGasPage";
import PharmaceuticalPage from "../pages/Industry/PharmaceuticalPage";
import PowerAndEnergyPage from "../pages/Industry/PowerAndEnergyPage";
import SmartCitiesPage from "../pages/Industry/SmartCitiesPage";
import WaterAndWastePage from "../pages/Industry/WaterAndWastePage";
import TechnicalSupportPage from "../pages/Support/TechnicalSupportPage";
import SupportTicketPage from "../pages/Support/SupportTicketPage";
import TrainingPage from "../pages/Support/TrainingPage";
import TutorialPage from "../pages/Support/TutorialPage";
import ForumPage from "../pages/Support/ForumPage";
import ForumDetailPage from "../pages/Support/ForumDetailPage";
import TutorialVideoPage from "../pages/Support/TutorialVideoPage";
import EditProfilePage from "../pages/Auth/EditProfile/EditProfilePage";
import NewsArticlePage from "../pages/News Article/NewsArticlePage";
import BlogArticlePage from "../pages/Blog Article/BlogArticlePage";
import PrivacyNoticePage from "../pages/Terms & Conditions/PrivacyNoticePage";
import CookieNoticePage from "../pages/Terms & Conditions/CookieNoticePage";
import TermOfUsePage from "../pages/Terms & Conditions/TermsOfUsePage";
import DataProtectionStatementPage from "../pages/Terms & Conditions/DataProtectionStatementPage";
import ImprintPage from "../pages/Terms & Conditions/ImprintPage";
import AccessibilityPage from "../pages/Terms & Conditions/AccessibilityPage";
import { QuotePage } from "../pages/Request Quote/QuotePage";
import { useGetGeneralsettingData } from "../hooks/useRQGeneralsetting";
import ForgotPasswordPage from "../pages/Auth/ForgotPassword/ForgotPasswordPage";
import ChangePasswordPage from "../pages/Auth/ChangePassword/ChangePassword";
import BlogPage from "../pages/Blog/BlogPage";
import MachineToolsIndustryPage from "../pages/Industry/MachineToolsIndustryPage";
import RefundAndReturnPage from "../pages/Terms & Conditions/RefundAndReturnPage";

const PagesRoutes = () => {
  const { isLoading: settingLoading, data: setting_data } =
    useGetGeneralsettingData();
  const [pageTitle, setPageTitle] = useState("Default Page Title"); // default title
  const [pageDescription, setPageDescription] = useState("Default description");
  const [pageKeywords, setPageKeywords] = useState("Default keywords"); // default description

  useEffect(() => {
    if (!settingLoading && setting_data) {
      const { list_generalsetting } = setting_data.data;
      const titleSetting = list_generalsetting.find(
        (setting) =>
          setting.text_key === "home_page_general_setting_home_page_seo_title"
      );
      const subtitleSetting = list_generalsetting.find(
        (setting) =>
          setting.text_key ===
          "home_page_general_setting_home_page_seo_description"
      );
      const keywordSetting = list_generalsetting.find(
        (setting) =>
          setting.text_key ===
          "home_page_general_setting_home_page_seo_keywords"
      );
      if (titleSetting && titleSetting.text_value) {
        setPageTitle(titleSetting.text_value);
      }
      if (subtitleSetting && subtitleSetting.text_value) {
        setPageDescription(subtitleSetting.text_value);
      }
      if (keywordSetting && keywordSetting.text_value) {
        setPageKeywords(keywordSetting.text_value);
      }

      console.log("Title:", titleSetting?.text_value ?? "No title found");
      console.log(
        "Subtitle:",
        subtitleSetting?.text_value ?? "No subtitle found"
      );
      console.log(
        "Keywords:",
        keywordSetting?.text_value ?? "No subtitle found"
      );
    }
  }, [settingLoading, setting_data]);
  return (
    <Router>
      <ScrollTopBehaviour />
      <Helmet>
        <title>GUI View</title>
        <meta name="og:title" content={pageTitle} />
        <meta name="title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta name="og:description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
        <meta name="og:keywords" content={pageKeywords} />
      </Helmet>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/innovation" element={<QualityPage />} />
          <Route path="/career" element={<CareerPage />} />
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route path="/manual" element={<ManualPage />} />
          <Route path="/guibuilder" element={<GuiBuilderPage />} />
          <Route path="/energy-efficiency" element={<EnergyEfficencyPage />} />
          <Route path="/brochure" element={<BrochurePage />} />
          <Route path="/thematic-demo" element={<TheMaticDemoPage />} />
          <Route path="/software" element={<SoftwarePage />} />
          <Route path="/product-gateway" element={<CloudViewPage />} />
          <Route path="/alarming" element={<AlarmingPage />} />
          <Route path="/historian" element={<HistorianDataPage />} />
          <Route path="/industrial-iot" element={<IndustrialIotPage />} />
          <Route path="/opc-ua" element={<OpcUaPage />} />
          <Route path="/privacy-notice" element={<PrivacyNoticePage />} />
          {/* <Route path="/cookie-notice" element={<CookieNoticePage />} /> */}
          <Route path="/term-of-use" element={<TermOfUsePage />} />{" "}
          <Route
            path="/data-protection-statement"
            element={<DataProtectionStatementPage />}
          />
          <Route path="/imprint" element={<ImprintPage />} />
          <Route
            path="/return-refund-shipping"
            element={<RefundAndReturnPage />}
          />
          <Route path="/accessibility" element={<AccessibilityPage />} />
          <Route
            path="/production-analysis"
            element={<ProductionAnalysisPage />}
          />
          <Route path="/scada" element={<ScadaPage />} />
          <Route
            path="/product-virtualizer"
            element={<SerialVirtualizerPage />}
          />
          <Route path="/gcomovertcp" element={<SerialOverEthernetPage />} />
          <Route path="/airport" element={<AirportPage />} />
          <Route path="/automotive" element={<AutomotivePage />} />
          <Route
            path="/building-automation"
            element={<BuildingAutomationPage />}
          />
          <Route path="/data-center" element={<DataCenterPage />} />
          <Route
            path="/machine-tools-industry"
            element={<MachineToolsIndustryPage />}
          />
          <Route path="/food-beverage" element={<FoodBeveragePage />} />
          <Route path="/oil-gas" element={<OilAndGasPage />} />
          <Route path="/pharmaceutical" element={<PharmaceuticalPage />} />
          <Route path="/energy" element={<PowerAndEnergyPage />} />
          <Route path="/smart-cities" element={<SmartCitiesPage />} />
          <Route path="/water-wastewater" element={<WaterAndWastePage />} />
          <Route path="/technical-support" element={<TechnicalSupportPage />} />
          <Route path="/support-ticket" element={<SupportTicketPage />} />
          <Route path="/training" element={<TrainingPage />} />
          <Route path="/tutorial" element={<TutorialPage />} />
          <Route path="/forum" element={<ForumPage />} />
          <Route path="/forum-detail/:id" element={<ForumDetailPage />} />
          <Route path="/quote" element={<QuotePage />} />
          <Route path="/videotutorial/:id" element={<TutorialVideoPage />} />
          <Route path="/newsarticle/:id" element={<NewsArticlePage />} />
          <Route path="/blogarticle/:id" element={<BlogArticlePage />} />
          <Route path="/blog" element={<BlogPage />} />
        </Route>
        {/* Auth Routes for organisation */}
        <Route element={<AuthRoute />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/verification" element={<VerificationPage />} />
          <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
          <Route path="/newpassword/:token" element={<ChangePasswordPage />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/edit-profile" element={<EditProfilePage />} />
        </Route>
        <Route>
          <Route path="*" element={<PageNotFound />} from="front" />
        </Route>
      </Routes>
    </Router>
  );
};

export default PagesRoutes;
