import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import { useGetTheMaticDemoData } from "../../../hooks";
import { useSelector } from "react-redux";
import { loggedUser } from "../../../store/features/authSlice";
import { FILE_PATH } from "../../../config";

const TheMaticDemoPage = () => {
  const checkLoggenInUser = useSelector(loggedUser);
  const isLoggedIn = checkLoggenInUser.isLoggedIn;
  let theMaticDemo = [];
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: the_metic_demo_data } = useGetTheMaticDemoData();
  if (!isLoading) {
    theMaticDemo = the_metic_demo_data.data;
  }

  return (
    <>
      <div className="fancy-hero-three bg-transparent">
      
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">Thematic Demo</h1>
                {/* <p className="sub-heading">
                  
                </p> */}
              </div>
              <div className="faq-section-four">
              <div className="container">
              <div className="title-style-five text-center mb-0 md-mb-0">
            <h6>
            You will find thematic demos with GUIView.
            </h6>
          </div>
              </div>
              
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      <div className="faq-section-four">
       
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-10 m-auto">
              <div
                className="accordion-style-four"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="faq-wrraper">
                  <Accordion preExpanded={["b"]} allowZeroExpanded>
                    {theMaticDemo.length > 0 &&
                      theMaticDemo.map((item, i) => (
                        <AccordionItem
                          className="card"
                          key={i}
                          uuid={item.expand}
                        >
                          <AccordionItemHeading className="card-header">
                            <AccordionItemButton>
                              <h5 className="mb-0">
                                <button className="btn btn-link">
                                  {item.title}
                                </button>{" "}
                              </h5>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          {/* Accordion Heading */}
                          <AccordionItemPanel className="card-body fadeInUp">
                            <div className="row align-items-center">
                              <div className="col-sm-6">
                                <h3 className="mb-3">
                                  Download {item.title} {item.id}
                                </h3>
                                <p className="mb-2">{item.description}</p>
                                <p className="mb-2">
                                  File size: {item.filesize}
                                </p>
                                {isLoggedIn === true ? (
                                  <a
                                    href={`${FILE_PATH}/thematicdemo/${item.demofile}`}
                                    target="_blank"
                                    className="btn bg-color-white text-black btn-rounded btn-3d btn-info mb-2"
                                  >
                                    Download {item.title}
                                  </a>
                                ) : (
                                  <a
                                    href="/login"
                                    className="btn bg-color-white text-black btn-rounded btn-3d btn-info mb-2"
                                  >
                                    Download {item.title}
                                  </a>
                                )}
                              </div>

                              <div className="col-sm-6">
                                <img
                                  style={{
                                    width: "100%",
                                    maxWidth: "300px",
                                    height: "auto",
                                  }}
                                  src={`${FILE_PATH}/thematicdemo/${item.imageFile}`}
                                  alt={item.title}
                                />
                              </div>
                            </div>
                          </AccordionItemPanel>
                          {/* Accordion Body Content */}
                        </AccordionItem>
                      ))}
                  </Accordion>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
        {/* /.container */}
      </div>
    </>
  );
};

export default TheMaticDemoPage;
