/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../../assets/img/Product/Gui_builder/Forum.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useGetHomeSvgData } from "../../../hooks";
import { useNavigate } from "react-router-dom";
const SerialOverEthernetPage = () => {
  const navigate = useNavigate();
  let fileNames = [
    "serialTCP",
    "comsharetcp",
    "tcpincommingconnection",
    "portethernet",
    "rawdata",
    "remotehost",
    "windowsservice",
    "serialdevice",
    "smartsend",
  ];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  const handleclick = () => {
    navigate("/quote");
  };
  return (
    <div>
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>
                    {data &&
                      data.svgDataArray.map((val, index) => {
                        if (val.fileName === "serialTCP") {
                          return <img key={index} src={val.svgDataURL}></img>;
                        } else {
                          return null; // Or you can choose to return some other element if the condition is not met
                        }
                      })}
                  </h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                Gcom facilitates easy communication via the TCP/IP protocol, allowing users to connect effortlessly. It establishes direct connections with remote ports using TCP/IP connections and utilizes telnet sessions for establishing connections. Once connected, it can send commands directly to the remote equipment. Despite its advanced features, the system remains user-friendly, robust, and reliable, even for new users. It boasts compatibility with various hardware and software configurations, making it highly configurable compared to other systems. Additionally, there are no limitations on port creation, enabling users to create custom ports according to their requirements.
                </p>
                
              </div>
              <div className="md-pt-0 mt-10">
                  {/* Submit button */}
                  <button
                    onClick={handleclick}
                    style={{ marginBottom: '30px' }}
                    className="theme-btn-two float-lg-right"
                  >
                    Request a Quote
                  </button>
                </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "comsharetcp") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="container">
        <h2 className="title-style-two text-center mt-50">
          Serial over Ethernet Connector functionality
        </h2>
        <br />
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-8 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Access serial port over Ethernet network</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                The software offers services that enable users to connect COM ports to the network, facilitating the connection of numerous serial ports to their computer. These ports can be connected either directly or through remote services. Primarily designed to generate virtual ports on your computer, the software allows you to create ports from anywhere in the world. Through remote ports, users can grant access to others for retrieving information. Not only does the software provide monitoring capabilities, but it also allows for changes to be made. Remote work can be accomplished using RS232 devices connected to the local computer. This connectivity enables remote access, which can be particularly beneficial for organizations.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-2 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "portethernet") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-8 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Share serial device over Ethernet</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                The software features a COM port functionality, which proves beneficial for remote connections. It offers a simple and swift solution for accessing RS232/RS422/RS485 ports. These ports can be connected via serial interface devices to the local network. The connection can be established either through Ethernet LAN or over the Internet, depending on specific requirements. Users can transmit data through the channel, which is then sent over the internet to control the software. This functionality extends to Ethernet connections as well, providing users with flexibility according to their needs. Essentially, it functions as a redirector, enabling the connection of ports to the local network via either wireless or wired connections.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-2 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "serialdevice") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-8 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Connect serial port with the remote host (Client)</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                This service enables users to remotely connect to serial ports. Initially, the connection is established with ports, which can be either local or virtual, along with devices. Typically, all devices function as remote servers and connect to the ports using the TCP/IP protocol. It is not mandatory for the client connection to require the SEC (Security Exchange Context) used for remote connections. Upon establishing a connection, users must provide information regarding the remote server's IP for security purposes. Occasionally, information about the port is also necessary for authentication. Once authentication is successfully completed, users can communicate via Ethernet.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-2 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "remotehost") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-8 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2> Share serial port for incoming connections (Server)</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                The software facilitates easy data transmission using the RAW protocol for data transformation, ensuring secure data transfer through a secure channel. Data transmission occurs promptly upon receipt without delays. Notably, it can transfer data without requiring a specific protocol at the application layer. The server is responsible for establishing and managing connections, as well as transferring data. It establishes connections with incoming client connections and shares their ports to the Ethernet, whether they are local or virtual serial ports. The server can handle multiple client connections simultaneously. Users access and transmit data serially using virtual or local ports. The connection between the server and remote ports is established via an Ethernet cable, which automatically connects and facilitates simultaneous data transmission. Countless clients can join and utilize the same services simultaneously without delays. Users can access any of the mentioned services through connection to a TCP/IP server.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-2 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "tcpincommingconnection") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-8 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Send and receive RAW data</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                The software enables easy data transmission, utilizing the RAW protocol for data transformation, ensuring secure transmission through a secure channel. Data is sent promptly upon receipt, without delays. An interesting feature is its capability to transfer data without relying on any particular protocol at the application layer.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-2 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "rawdata") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-8 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Works as Windows service</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                To enhance user convenience, we offer these services as a Windows service. The software features a user-friendly system for establishing connections, automatically connecting upon system boot. Security is ensured with mandatory user login before any connection. Users can maintain their LAN connection using our remote service, which remains secure despite its remote nature. Additionally, users can modify settings and configure them easily using this service
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-2 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "windowsservice") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-100 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-8 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Smart data sending</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                We prioritize user security by employing a service capable of intelligently transferring data. Users have control over application settings and data flow. Data can be transmitted to the network connection, where it is temporarily stored in a special buffer zone. Typically, this holding period is brief. However, in certain cases, data may be held until it reaches a specific size. Our software offers numerous features for data transformation to meet diverse needs.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-2 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "smartsend") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <br></br>
      <section
        className="bg-color-orange position-relative border-0  m-0"
        style={{
          backgroundImage:
            "linear-gradient(to right, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #8d3d86 100%)",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <div className="container">
          <div className="row text-white text-center align-items-center">
            <div className="col-md-6">
              <h2
                style={{ fontSize: "25px", color: "white", marginBottom: "0" }}
              >
                Download and try it now!
              </h2>
            </div>
            <div className="col-md-6 mt-4">
              <div className="d-flex flex-column align-items-center">
                <Link
                  to="/contact-us"
                  className="btn"
                  style={{
                    backgroundColor: "#000",
                    color: "white",
                    height: "45px",
                    width: "70%",
                    fontSize: "15px",
                    border: "none",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none", // to remove default underline
                  }}
                >
                  Talk with our experts
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: "8px" }}
                  />
                </Link>
                <Link
                  to="/software"
                  className="btn"
                  style={{
                    backgroundColor: "#000",
                    color: "white",
                    height: "45px",
                    width: "70%",
                    fontSize: "15px",
                    border: "none",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none", // to remove default underline
                  }}
                >
                  Download product
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: "8px" }}
                  />
                </Link>
                <Link
                  to="/tutorial"
                  className="btn"
                  style={{
                    backgroundColor: "#000",
                    color: "white",
                    height: "45px",
                    width: "70%",
                    fontSize: "15px",
                    border: "none",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none", // to remove default underline
                  }}
                >
                  Video tutorial
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: "8px" }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SerialOverEthernetPage;
