import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import img from "../../../assets/images/assets/ils_08.svg";
import img2 from "../../../assets/images/assets/ils_08.1.svg";
import img3 from "../../../assets/images/assets/ils_08.2.svg";
import { useFormik } from "formik";
import validationSchema from "./LoginValidation";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useLogin } from "../../../hooks";
import { loginSuccess } from "../../../store/features/authSlice";
import password from "../../../assets/images/icon/view.svg";
const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // for password show hide
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  /**
   * This Block will execute on Form Submit, provides form fields and validations for that
   */
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      doLogin(values);
    },
  });
  /**
   * This Block is execute on Form Submit when we'll get success response, then we are storing data in Redux using React Toolkit.
   */
  const { mutate: doLogin, isLoading } = useLogin((res) => {
    const dataStore = {
      userData: res.data.user_data,
      isLoggedIn: true,
      accessToken: res.data.accessToken,
    };
    toast.success(res.message);
    dispatch(loginSuccess(dataStore));
    navigate("/");
  });
  return (
    <div className="user-data-page clearfix d-lg-flex justify-content-center align-items-center mt-100">
      {/* <div className="illustration-wrapper d-flex align-items-center justify-content-between flex-column">
        <div className="illustration-holder">
          <img src={img} alt="illustration" className="illustration" />
          <img src={img2} alt="illustration" className="shapes shape-one" />
          <img src={img3} alt="illustration" className="shapes shape-two" />
        </div>
  </div> */}
      {/* /.illustration-wrapper */}

      <div className="form-wrapper">
        {/* <div className="d-flex justify-content-between">
          <Link className="font-rubik go-back-button" to="/">
            Go to home
          </Link>
        </div> */}
        <div className="mt-80 md-mt-40">
            <h2>
              Login <br />
            </h2>
            <p className="header-info pt-30 pb-50">
              Still don't have an account? <Link to="/signup">Sign Up</Link>
            </p>
          </div>
        
        
        <form onSubmit={formik.handleSubmit} className="user-data-form ">
          <div className="row">
            <div className="col-12">
              <div className="input-group-meta mb-80 sm-mb-70">
                <label>Email</label>
                <input
                  placeholder="Enter Your Email"
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={` ${formik.errors.email ? "is-invalid" : ""}`}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="input-group-meta mb-25">
                <label>Password</label>
                <input
                  placeholder="Enter Password"
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className={` ${formik.errors.password ? "is-invalid" : ""}`}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="invalid-feedback">
                    {formik.errors.password}
                  </div>
                ) : null}
                <span
                  className="placeholder_icon"
                  onClick={togglePasswordVisiblity}
                >
                  <span
                    className={
                      passwordShown ? "passVicon eye-slash" : "passVicon"
                    }
                  >
                    <img src={password} alt="ico" />
                  </span>
                </span>
              </div>
            </div>
            <div className="col-12">
              <div className="agreement-checkbox d-flex justify-content-between align-items-center"></div>
              {/*  /.agreement-checkbox */}
            </div>
            <div className="col-12 text-right"> {/* Modified this line */}
              <p className="header-info pt-10 pb-10"> {/* Modified this line */}
                 <Link to="/forgotpassword"> Forgot Password ?</Link>
              </p>
            </div>
            <div className="col-12">
              <button className="theme-btn-one mt-30 mb-50">Login</button>
            </div>
          </div>
        </form>
        {/* Login Form End */}
        <p className="text-center font-rubik copyright-text">
          © Copyright {new Date().getFullYear()}. All Rights Reserved.
        </p>
      </div>
      {/* /.form-wrapper */}
    </div>
  );
};

export default LoginPage;
