import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useGetTopic } from "../../hooks";
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ForumPage = () => {
  const [data, setData] = useState([]);

  const onSuccess = (response) => {
    // console.log("data", response.data.topic_list);
    if (response && response.data) {
      setData(response.data.topic_list);
    }
  };

  const onError = (error) => {
    console.error("Error fetching topics", error);
  };

  useGetTopic(onSuccess, onError);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div className="main-page-wrapper">
      
        <div className="container">
          <div className="row">
            <div className="col-xl-11 m-auto">
              <div className="faqs-header pb-60 md-pb-50">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="d-flex">
                      <div className="md-mb-30">
                        <h3>Forum</h3>
                        <p className="collection-preview">
                        This concise description encourages users to reach out for additional support and assures them of expert assistance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.faqs-header */}

              <div className="all-faqs">
                <div className="faqs-all-qus">
                  {data.filter(item => item.status === true || item.status === 1).map((item, i) => (
                    <Link
                      className="article-preview d-flex"
                      to={`/forum-detail/${item.id}`}
                      key={i}
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <div>
                        <h3 className="font-rubik">{item.title}</h3>
                        <div className="avatar-info">
                          Written by <span>{item.UsersRef.username}</span> <br />
                          <span><FontAwesomeIcon icon={faCalendarAlt} /> {new Date(item.createdAt).toLocaleDateString()}</span>
                          
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
                {/* /.faqs-all-qus  */}
              </div>
              {/*  /.all-faqs */}

              <div
                className="text-center more-faq-ask"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <h3 className="mb-35 font-rubik">Don’t find your answer?</h3>
                <a href="contact-us" className="theme-btn-one">
                  Contact us
                </a>
              </div>
              {/* End more-faq-ask */}
            </div>
          </div>
        </div>
      
      {/* /.faqs-inner-page */}
    </div>
  );
};

export default ForumPage;
