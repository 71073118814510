/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/img/Training/icon-1.svg";
import img2 from "../../assets/img/Training/icon-2.svg";
import img3 from "../../assets/img/Training/icon-3.svg";
import { useGetHomeSvgData } from "../../hooks";

const BlogContent = [
  {
    img: img1,
    title: "Courses and Seminars",
    tag: ` Choose the module most suited to your needs or request a
      tailored training course`,
    routePath: "/training",
    dealyAnimation: "",
  },
  {
    img: img2,
    title: "Pro Support",
    tag: ` The Pro Support extends the Basic Support to include
      access to the Help Desk for support over the phone with
      GUIView Team experts.`,
    routePath: "/training",
    dealyAnimation: "50",
  },
  {
    img: img3,
    title: "Recorded Webinars",
    tag: `Get to know the GUIViewproducts directly from your office`,
    routePath: "/training",
    dealyAnimation: "100",
  },
];

const TrainingPage = () => {
  let fileNames = ["training"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <div
      className="main-page-wrapper"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="fancy-hero-one mt-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-10 m-auto">
              <h2 className="font-gilroy-bold">Training</h2>
              <br />
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "training") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
          </div>
        </div>
      </div>
      {/* /.fancy-hero-one */}

      {/* =====================================================
            Feature Blog One
        ===================================================== */}
      <div className="feature-blog-one blog-page-bg">
        <div className="container">
          <div className="row">
            {BlogContent.map((item, i) => (
              <div
                className="col-lg-4 col-md-6"
                key={i}
                data-aos-delay={item.dealyAnimation}
              >
                <div className="post-meta">
                  <img
                    src={item.img}
                    alt="media"
                    className="image-meta mx-auto d-block" // Added mx-auto and d-block classes
                    style={{ height: "50px" }}
                  />
                  <h3 className="title mt-5">{item.title}</h3>
                  <div className="tag">{item.tag}</div>
                  <Link
                    to={item.routePath}
                    className="read-more d-flex justify-content-between align-items-center"
                  >
                    <span>More Info</span>
                    <i className="flaticon-right-arrow"></i>
                  </Link>
                </div>
                {/* /.post-meta */}
              </div>
            ))}
          </div>

          {/* End .row */}

          <div className="container mt-3">
            <div className="title-style-two text-center mb-35 md-mb-10">
              <div className="row">
                <div className="">
                  <h2>Courses and Seminars</h2>
                </div>
              </div>
            </div>
            {/* End title-style-two */}

            <div className="row">
              <div className="">
                <li>
                  GUIView offers
                  <strong>
                    comprehensive GUI Builder and Cloud View training courses
                    for customers and partners, facilitating rapid deployment of
                    project applications. Our hands-on, interactive format
                    ensures that users acquire the skills needed to successfully
                    develop their projects using GUIView immediately.
                  </strong>
                  GUIView offers The hands-on, interactive format used ensures
                  that users learn the skills needed for immediate use of
                  GUIView to develop their projects successfully.
                </li>
                <br></br>
                <li>
                  GUIView offers free basic training courses with sheduled dates
                  and locations ready for viewing on the training course
                  calendar page. In addition, GUIViewalso offers training
                  courses either at their offices or the customer’s offices.
                  On-site training courses are tailored made to meet the
                  specific needs of the customer’s project. The already existing
                  courses can be modified or a special SCADA training course can
                  be designed to fit customer needs.
                </li>
                <br></br>
                <li>
                  <strong>
                    Contact us for an estimate based on your training needs.
                  </strong>
                </li>
              </div>
            </div>

            {/* End .row */}
          </div>
          <div className="faq-classic pt-100 pb-70 md-pt-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="md-mt-60">
                    <div className="title-style-one">
                      <h3>Trainings and Webinars</h3>
                      <br></br>
                      <h4 className="title-style-one">
                        CLICK HERE TO DOWNLOAD COURSE PROGRAMS
                      </h4>
                    </div>
                    {/* /.title-style-one */}
                    <Link
                      to="/faq"
                      className="theme-btn-one mt-50 md-mt-30 title-style-one"
                    >
                      GUIView.Program
                    </Link>
                  </div>
                </div>
                {/* End .col */}
                <div className="col-lg-6">
                  <div className="md-mt-60">
                    <div className="title-style-one">
                      <h3>Live Webinar Programs</h3>
                      <h6 className="font-rubik mt-2">
                        CLICK HERE TO FIND OUT MORE ABOUT THE COURSES PROGRAM
                      </h6>
                      <Link
                        to="/login"
                        className="theme-btn-one mt-30 md-mt-30"
                      >
                        Free Live Webinar
                      </Link>
                    </div>
                  </div>
                </div>
                {/* End .col */}
              </div>
            </div>
          </div>

          <div className="container mt-3">
            <div className="title-style-two text-center mb-35 md-mb-10">
              <div className="row">
                <div className="">
                  <h2>Course on demand</h2>
                </div>
              </div>
            </div>
            {/* End title-style-two */}

            <div className="row">
              <div className="">
                <p>
                  Our expert technicians are at your disposal to give courses
                  based on your specific project needs.
                </p>
                <br></br>
                <p>
                  A great number of clients often ask for a custom training
                  course tailored to their particular needs that derive from the
                  type of application they wish to develop or existing problems
                  that need to be solved.
                </p>
                <br></br>
                <p>
                  The expert GUIViewtechnicians are fully qualified to answer
                  client queries and help solve problems by providing solutions
                  to satisfy particular application requirements.
                </p>
                <br></br>
                <p>
                  The tailor-made courses are based on training programs
                  specifically designed to confront the arguments submitted by
                  the client. The module or program is based on the arguments
                  put forward by the client using concrete and clear examples.
                </p>
                <br></br>
                <p>
                  Courses on demand are designed to fit your specific project
                  needs and can be held at our offices or yours.
                </p>
                <br></br>
                <p>
                  The modules are based on the indicated Training Programs and
                  can be customized to accommodate your project needs. We can
                  help create your project and give you the right guidelines to
                  ensure its success.
                </p>
                <br></br>
                <p>
                  The tailor-made courses are based on training programs
                  specifically designed to confront the arguments submitted by
                  the client. The module or program is based on the arguments
                  put forward by the client using concrete and clear examples.
                </p>
                <Link to="/contact-us" className="theme-btn-one mt-30 md-mt-30">
                  Contact Us
                </Link>
              </div>
            </div>

            {/* End .row */}
          </div>
        </div>
      </div>
      {/* /.feature-blog-one */}
    </div>
  );
};

export default TrainingPage;
