import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import validationSchema from "./ForgotPasswordValidation";
import { useResetpassword } from "../../../hooks";
import { decryptIdValues } from "../../../helpers/commonHelpers";
import { useFormik } from "formik";
import { toast } from "react-toastify";
const ForgotPasswordPage = () => {
  
  const navigate = useNavigate();
  const { mutate: doResetpassword, isLoading } = useResetpassword((res) => {
    // console.log("res",res)
    toast.success(res.message);
    
    navigate("/");
  });
 
 
  
  
  const formik = useFormik({
    initialValues: {
      email: "",
   },
    validationSchema,
    onSubmit: (values) => {
      // console.log(values, "values");
      
      doResetpassword(values);
    },
  });
 

  // const handleSubmit = (event) => {
  //   // Prevent the default form submission behavior
  //   event.preventDefault();
    
  //   // Redirect to the Change Password page
  //   navigate("/newpassword");
  // };
 
  

  return (
    
    <div className="user-data-page clearfix d-lg-flex justify-content-center align-items-center mt-100">
      <div className="form-wrapper">
        
        <div className="mt-80 md-mt-40">

          <h2>Forgot Password</h2>
          <p className="header-info pt-30 pb-50"></p>
        </div>
        
        <form onSubmit={formik.handleSubmit} className="user-data-form">
          <div className="row">
            <div className="col-12">
              <div className="input-group-meta mb-80 sm-mb-70">
                <label>Email</label>
                <input
                  placeholder="Enter Your Email"
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={` ${formik.errors.email ? "is-invalid" : ""}`}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="agreement-checkbox d-flex justify-content-between align-items-center"></div>
            </div>
            <div className="col-12 text-right"> {/* Modified this line */}
              <p className="header-info pt-10 pb-10"> {/* Modified this line */}
                <Link to="/login">Login</Link>
              </p>
            </div>
            <div className="col-12">
              <button type="submit" className="theme-btn-one mt-10 mb-50">
                Submit
              </button>
            </div>
          </div>
        </form>
        {/* Form End */}
        <p className="text-center font-rubik copyright-text">
          © Copyright {new Date().getFullYear()}. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
