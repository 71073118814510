import client from "../libs/HttpClient";

class ForumServices {
  static getTopicList(request) {
    return client.get(`topic`, request);
  }
  static getPostAnswerList(params) {
    return client.get(`post-answer/${params.id}`);
  }
  static addPostData(request) {
    return client.post(`/add-post`, request);
  }
  static addAnswerData(request) {
    return client.post(`/add-postanswer`, request, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  }
  
}

export { ForumServices };
