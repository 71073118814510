/* eslint-disable jsx-a11y/alt-text */
import React from "react";
// import img1 from "../../assets/img/Product/Gui_builder/Forum.svg";
import { useGetHomeSvgData } from "../../hooks";

const ProductionAnalysisPage = () => {
  let fileNames = ["productionanalisys"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <div>
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container"
        data-aos="fade-left"
        data-aos-duration="1200">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              
            >
              <div className="">
                <div className="title-style-two">
                  <h2> Production Analysis</h2>
                </div><br/>
                {/* /.title-style-two */}
                {/* <p className="mt-4">
                  Data can be used in many ways and we are also using data for
                  getting more insight about users. We easily log your data and
                  use it for generating production statistics. After the
                  collection, we aggregate statistics by using our module. The
                  usage of modules is quite easy as one can define aggregate by
                  a couple of clicks. Many things should be considered for
                  defining aggregate:
                </p>
                <li class="">Batches</li>
                <li class="">Products</li>
                <li class="">Shifts</li>
                <li class="">Time intervals (hours, days, weeks, etc.)</li> */}
                <p>GUIview’s production analysis solutions, powered by GUI Builder and Cloud View, offer comprehensive tools for monitoring and optimizing manufacturing processes. GUI Builder provides detailed data collection and visualization capabilities, allowing users to track production metrics, identify trends, and uncover inefficiencies in real-time. With customizable dashboards and reporting tools, users can gain valuable insights into every aspect of their production operations.</p><br/>
                <p><b>Cloud View</b> enhances these features by enabling remote access to production data. This cloud-based solution allows users to analyse and manage production metrics from anywhere, facilitating informed decision-making and swift corrective actions.</p><br/>
                <p>Together, GUI Builder and Cloud View deliver a powerful production analysis platform, providing the real-time data and remote accessibility needed to drive operational efficiency and improve manufacturing performance. Trust GUIview to empower your production analysis with advanced, user-friendly solutions.</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
             
            >
               {data &&
              data.svgDataArray.map((val, index) => {
                if (val.fileName === "productionanalisys") {
                  return <img key={index} src={val.svgDataURL}></img>;
                } else {
                  return null; // Or you can choose to return some other element if the condition is not met
                }
              })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div
        className="container mt-5"
       
      >
        <div className="row">
          <div className="col-xl-10 m-auto">
            <p className="mt-2">
            In today's manufacturing industries, collecting real-time production data is essential for gaining deeper insights. By analyzing this data, patterns can be identified and the root causes of errors can be reduced, leading to increased productivity. Implementing such a system requires fast and accurate data collection to effectively identify patterns and address errors in a timely manner, thereby enhancing productivity.

            </p>
            <p className="mt-4">
            Various circumstances, such as unavoidable scheduled production downtimes (due to staff absence or maintenance) or unscheduled downtimes (due to system failure), can lead to an increase in rejection rates. These issues often stem from a lack of machine efficiency, typically measured using the OEE metrics (Overall Equipment Effectiveness). Neglecting this aspect may result in equipment-related losses.
            </p>
            <p className="mt-4">
              <span>
              To address equipment-related losses, attention should be given to three components:
              </span>
            </p>
            <li class=""><b>Availability :</b> Losses due to downtimes</li>
            <li class=""><b>Quality :</b> Losses due to process defects</li>
            <li class=""><b>Performance :</b> Losses due to reduced productivity</li>
            <p className="mt-4">
            These components are the primary causes of production loss and unexpected downtimes resulting from failures, such as setup changes, scheduled maintenance, or other disruptions. To mitigate unclassified losses, operation discretion machinery can be used to address problems directly and automatically."

            </p>
          </div>
        </div>
        {/* End .row */}
      </div>
    </div>
  );
};

export default ProductionAnalysisPage;
