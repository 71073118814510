import { useMutation } from "react-query";
import { toast } from "react-toastify";

import { QuoteService } from "../api";

/**
 * Hooks for Authentication Process
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useAddQuote = (onSuccess, onError = onDefaultError) => {
  return useMutation(QuoteService.addQuote, {
    onSuccess,
    onError,
  });
};

const AddQuotes = (data) => {
  return QuoteService.addQuote(data)
};

export { useAddQuote,AddQuotes };
