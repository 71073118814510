import client from "../libs/HttpClient";
class HomeService {
  static getHomeNewsData() {
    return client.get("/");
  }
  static getHomeBlogData() {
    return client.get("/home-blog");
  }
  static getHomeSvgData(params) {
    return client.get(`/get-svg`, { params });
  }
}
export { HomeService };
