import React from "react";
import { Helmet } from "react-helmet";

const ImprintPage = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Terms & Conditions || Deski-Saas & Software React Template
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-12">
              {/* Tab panes */}
              <div className="tab-content ">
                <div id="opt5">
                  <div className="col-xl-6 col-lg-6 m-auto terms-condition-container">
                    <h2 className="font-gilroy-bold">Imprint</h2>
                  </div>
                  <p>
                    This Website is maintained by PI Corporation Electric Co.
                    (3-mahadev vadi, umakant pandit udhyog nagar,rajkot-360004
                    ).
                  </p>
                  <p>
                    We have taken a number of steps to comply with the GDPR. For
                    more details about our compliance and how we handle your
                    data please check our latest legal documents below:
                  </p>
                  <ul>
                    <p>Electronic contact:</p>
                    <li>
                      <a href="#">info@piinco.com</a>
                    </li>
                    <p>Authorized Representative- India</p>
                    <li>
                      <p>Phone:</p>
                      <a href="#">9974841011</a>
                    </li>
                  </ul>
                  <p>Monday - Friday, between 8:00 AM and 5:00 PM CST</p>
                  <p>
                    Commercial Register:Rajkot, Gujarat Business No. 9974841011
                    .
                  </p>
                </div>
              </div>
              {/*  /.tab-content */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImprintPage;
