import { useMutation,useQuery } from "react-query";
import { toast } from "react-toastify";
import { ForumServices } from "../api";

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetTopic = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    "topic-view",
    () => ForumServices.getTopicList(),
    {
      onSuccess,
      onError,
    }
  );
};

const useGetPostAnswer = (id,onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["postanswer-view", id],
    () => ForumServices.getPostAnswerList({ id }),
    {
      onSuccess,
      onError,
    }
  );
};
const useAddPost = (onSuccess, onError = onDefaultError) => {
  return useMutation(ForumServices.addPostData, {
    onSuccess,
    onError,
  });
};
const useAddAnswer = (onSuccess, onError = onDefaultError) => {
  return useMutation(ForumServices.addAnswerData, {
    onSuccess,
    onError,
  });
};


export { useGetTopic,useGetPostAnswer,useAddPost,useAddAnswer };
