import React, { useEffect, useState } from "react";
import Icon from "../../assets/images/icon/chevron-w.png";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      {isVisible && (
        <div>
          <button className="scroll-top " onClick={scrollTop}>
            <img src={Icon} alt="icon" />
          </button>
        </div>
      )}
    </>
  );
};

export { ScrollToTop };
