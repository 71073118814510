import React from "react";
import {
  CaseStudy,
  HeroSection,
  NewsSection,
  OurProduct,
  QuotePage,
  Testimonial,
  BlogSection
} from "./Component";
import { useGetHomeNewsData } from "../../hooks/useRQHome";
import { useGetHomeBlogData } from "../../hooks/useRQHome";
import { useGetOurproductData} from "../../hooks/useRQOurproduct"
import { useGetFeaturesData } from "../../hooks/useRQFeatures";
import { useGetTestimonialData } from "../../hooks/useRQTestimonial";

const HomePage = () => {
  let home_data = [];
  let home_data1 = [];
  let ourproduct_data = [];
  let features_data = [];
  let testimonial_data = [];
  
  
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading: homeNewsLoading, data: general_data } = useGetHomeNewsData();
  if (!homeNewsLoading) {
    home_data = general_data?.data || [];
  }
  // console.log("home_data",home_data)

  const { isLoading: homeBlogLoading, data: general1_data } = useGetHomeBlogData();
  if (!homeBlogLoading) {
    home_data1 = general1_data?.data || [];
  }
  // console.log("home_data1",home_data1)

  const { isLoading: productLoading, data: product_data } = useGetOurproductData();

  if (!productLoading) {
    ourproduct_data = product_data?.data || [];
  }

// console.log("ourproduct_data",ourproduct_data)

const { isLoading: featuresLoading, data: feature_data } = useGetFeaturesData();

  if (!featuresLoading) {
    features_data = feature_data?.data || [];
  }
  // console.log("features_data",features_data)

  
const { isLoading: testimonialLoading, data: testimonials_data } = useGetTestimonialData();

  if (!testimonialLoading) {
    testimonial_data = testimonials_data?.data || [];
  }
  // console.log("testimonial_data",testimonial_data)

  return (
    <div>
      {features_data.active_features &&<HeroSection feature_data={features_data.active_features}/>}
      
      {home_data.data1 && <NewsSection news_data={home_data.data1} />}
      
      {ourproduct_data.active_ourproduct && <OurProduct product_data={ourproduct_data.active_ourproduct}/>}
      {testimonial_data.active_testimonial &&<Testimonial testimonials_data={testimonial_data.active_testimonial}/>}
      <CaseStudy />
      
      {home_data1.data1 && <BlogSection blog_data={home_data1.data1} />}
      <QuotePage />
    </div>
  );
};

export default HomePage;
