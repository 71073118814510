/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { useGetHomeSvgData } from "../../hooks";

const MachineToolsIndustryPage = () => {
  let fileNames = ["oilngasa","oilgas"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <>
      <div className="fancy-hero-three">
        
        <div className="">
          <div className="container">
            <div className="row">
              {/* <div className="col-xl-9 col-lg-10 m-auto">
                <h1 className="heading">Oil & Gas</h1>
              </div> */}
               {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "oilngasa") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-text-block-thirteen pt-40 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Machine Tools Industry</h2><br/>
                </div>
                {/* /.title-style-two */}
                <p>
                The machine tools industry can achieve remarkable improvements in efficiency and precision with GUIview’s cutting-edge solutions, powered by GUI Builder and Cloud View. <b>GUI Builder</b> offers robust monitoring and control capabilities for CNC machines, lathes, milling machines, and other tools. Its intuitive interface allows seamless integration and real-time visualization of machine performance, reducing downtime and enhancing productivity.
                </p><br/>
                <p>
                <b>Cloud View</b> complements these features by providing remote access to critical machine data and control functions. Operators and managers can monitor operations, receive alerts, and make informed decisions from anywhere, ensuring optimal machine performance and swift issue resolution.
                </p><br/>
                <p>
                Together, GUI Builder and Cloud View deliver a powerful platform for the machine tools industry, offering real-time data access, remote monitoring, and enhanced operational control. Trust GUIview to drive efficiency, precision, and innovation in your machine tool operations with state-of-the-art technology.

                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "oilgas") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MachineToolsIndustryPage;
