import React from "react";
import { Helmet } from "react-helmet";

import ScrollspyNav from "react-scrollspy-nav";

const PrivacyNoticePage = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Terms & Conditions || Deski-Saas & Software React Template
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-12">
              {/* Tab panes */}
              <div className="tab-content ">
                <div id="opt1">
                  <div className="col-xl-6 col-lg-6 m-auto terms-condition-container">
                    <h2 className="font-gilroy-bold">Privacy Notice</h2>
                    <div className="update-date">LAST UPDATED: 4/16/2024</div>
                  </div>

                  <h3>1. Introduction</h3>
                  <p>
                    This Privacy Notice (“Privacy Notice”) describes how PI
                    Corporation”., its subsidiaries and affiliates that link to
                    this Privacy Notice – each entity being a data controller-
                    (“PI Corporation”, “our”, “we”, or “us”) may collect,
                    process, use and share information relating to you as an
                    individual or as a business (“Personal Data”). The Personal
                    Data we collect depends on the interactions you have with us
                    (“Your Relationship”), such as (but not limited to) the
                    products and services you use, this website, your location
                    and applicable law. Please note that specific data privacy
                    information (a “Specific Notice”) in addition to this
                    Privacy Notice may be provided depending on specific
                    interactions you have with us. In the event of a conflict
                    with this Privacy Notice and a Specific Notice, the Specific
                    Notice will prevail unless specifically stated otherwise.
                  </p>
                  <p>
                    If you are a India resident, please click here to review the
                    privacy notice applicable to you.
                  </p>
                  <h3>Contacting Us</h3>
                  <p>
                    PI Corporation” (NYSE: EMR) is registered in the India as a
                    corporation in the State of Gujarat and has its registered
                    office at Rajkot.
                  </p>
                  <p>
                    A list of PI Corporation subsidiaries and affiliates can be
                    found here.
                  </p>
                  <p>
                    The PI Corporation subsidiary or affiliate responsible for
                    the respective processing activity is identified in the
                    applicable documentation pertaining to Your Relationship.
                  </p>
                  <p>
                    If you want to exercise your data privacy rights as
                    described below or you have any questions about this Privacy
                    Notice, please reach out to our data protection
                    organization:
                  </p>
                  <p>Email: Data.privacy@PI Corporation.com</p>
                  <p>Regular Mail: PI Corporation </p>
                  <p>Attention: Privacy Inquiries </p>

                  <p>Rajkot,Gujarat,India</p>
                  <p>Mobile: 9974841011</p>
                  <p>
                    To opt-out of our use of third-party advertising Cookies,
                    please see the section Cookies below. You may submit a
                    request to opt-out of sales by clicking on this link: “Do
                    Not Sell or Share My Personal Information.” You also may
                    submit a request by calling us at 1-888-905-0846.
                  </p>
                  <p>
                    If you would instead like to unsubscribe from our marketing
                    communications, please select the unsubscribe link in the
                    footer of our email communication or email us at
                    data.privacy@PI Corporation.com.
                  </p>
                  <h3>Overview</h3>
                  <p>
                    Our privacy practices may vary among the countries in which
                    we operate to reflect local requirements.
                  </p>
                  <p>
                    The following table provides an overview of our processing
                    activities under this Privacy Notice. By clicking “Read
                    More” or by scrolling down the page, you will find more
                    detailed information. Kindly note that a Specific Notice may
                    provide additional information which would prevail unless
                    specifically stated otherwise.
                  </p>
                  <h3>Personal Data Collection</h3>
                  <p>
                    Depending on Your Relationship with us, we may collect the
                    following types of Personal Data from you through your
                    interactions with us, either automatically or provided
                    directly by you:
                  </p>

                  <ul>
                    <li>Contact Information:</li>
                    <p>
                      including your name, email, username, phone number,
                      company, industry and address, as well as the name and
                      address of the dealer that sold you your product;
                    </p>

                    <li>Demographic Information:</li>
                    <p>
                      including your gender, age, citizenship, education,
                      profession, occupation, and income level;
                    </p>
                    <li>Financial/Payment Information: </li>
                    <p>
                      such as your credit card number, billing address, shipping
                      address, security code and other payment transaction and
                      verification details, as well as consumer’s application
                      and claims history;
                    </p>
                    <li>Analytical Information: </li>
                    <p>
                      including pages and products viewed, items added to your
                      shopping cart, products and services you have purchased
                      from us, ads that you clicked on, emails from us that you
                      opened, browser type, operating system (“OS”), Internet
                      Protocol (“IP”) address and device and geolocation
                      information;
                    </p>
                    <li>Publicly available information:</li>
                    <p>
                      {" "}
                      about you from third-party sources, such as the postal
                      service for shipping address verification;
                    </p>
                    <li>Unique Personal Identifiers:</li>
                    <p>
                      Your mobile OS, a mobile device identifier embedded by us,
                      or other commonly used mobile device and unique personal
                      identifiers such as cookies, beacons, pixel tags or
                      similar technology.
                    </p>
                    <p>
                      In certain circumstances, we collect Personal Data from
                      you about a third party. If you submit any Personal Data
                      about another individual to us, you are responsible for
                      making sure that you have the authority to do so and to
                      allow us to use their Personal Data in accordance with
                      this Privacy Notice.
                    </p>
                    <p>
                      We also collect your Personal Data or usage data from
                      third parties in certain circumstances:
                    </p>
                  </ul>
                  <p>
                    We will apply the terms of our Privacy Notice to any
                    Personal Data received from a third party, unless we have
                    disclosed to you otherwise. PI Corporation is not
                    responsible for these third parties’ dissemination of your
                    Personal Data.
                  </p>
                  <h3>How We Use Your Personal Data </h3>
                  <p>
                    Depending on the type of Relationship with us, we use your
                    Personal Data for the following purposes, as permitted by
                    applicable law:
                  </p>
                  <ul>
                    <li>Purchases:</li>
                    <p>
                      We use your Contact Information and Payment Information to
                      allow us to process and fulfill purchases you make through
                      Your Relationship.
                    </p>
                    <li>Customer Service:</li>
                    <p>
                      We use your Contact Information to receive and respond to
                      your questions about products, services or warranties and
                      to communicate with you about contests, surveys or
                      sweepstakes. To address your questions, we may also
                      request your industry and/or the name and address of the
                      dealer that sold you our product.
                    </p>
                    <li>Use of Product: </li>
                    <p>
                      For certain products and as specifically disclosed in a
                      Specific Notice, we may require your username and email
                      address to use the product.
                    </p>
                    <li>Feedback:</li>
                    <p>
                      We use your username, email address, products purchased,
                      and other user-generated content you may provide when you
                      rate and review our products. Your feedback helps us
                      develop new products and services, maintain quality
                      assurance and debug issues you may identify.
                    </p>
                    <li>Website Registration: </li>
                    <p>
                      We may use your Contact Information and Demographic
                      Information when you create an account through any of our
                      services in order to provide you a more personalized user
                      experience.
                    </p>
                    <li>Analytics:</li>
                    <p>
                      When you use our services (e.g., our website), we may
                      automatically collect and use Analytical Information
                      (where permissible by law) in order for us to continually
                      improve your experience with our services and to target
                      marketing and advertising of our products to you.
                    </p>
                    <li>Marketing:</li>
                    <p>
                      We may use your Personal Data to determine what products
                      may be of interest to you, provide you with marketing
                      communications (if you have opted-in to receive such
                      communications or otherwise where permitted under
                      applicable law) and to conduct market research. We may
                      also use information you provide us, including your
                      industry, whether you own our product, experience with our
                      products, and user-generated content, for these marketing
                      purposes.
                    </p>
                    <li>Location-Based Services:</li>
                    <p>
                      For certain products and as disclosed in a Specific
                      Notice, we may use your current location, provided address
                      and/or zip code to provide you the location of the
                      retailer of our products or other appropriate information.
                    </p>
                  </ul>
                  <h3>Legal Bases for the Processing and Consequences</h3>
                  <p>
                    We rely on the following legal grounds for the collection,
                    processing, and use of your Personal Data:
                  </p>
                  <ul>
                    <li>Contract:</li>
                    <p>
                      the processing is necessary to provide the products or
                      services as requested by you or for the performance of a
                      contract to which you are a party (or to take steps at
                      your request prior to entering into a contract);
                    </p>
                    <li>Consent:</li>
                    <p>
                      the processing is based on your consent for one or more
                      purposes (e.g., marketing);
                    </p>
                    <li>Legal Obligation:</li>
                    <p>
                      the processing is necessary for compliance with our legal
                      obligations (e.g., complying with law or subpoenas);
                    </p>
                    <li>Legitimate Interest:</li>
                    <p>
                      the processing is necessary for the purposes of our
                      legitimate interests or those of a third party (e.g.,
                      improving your experience with our websites and products)
                      except where overridden by your fundamental rights and
                      freedoms that would require protection.
                    </p>
                  </ul>
                  <p>
                    In general, the provision of your Personal Data is
                    voluntary, but in certain cases it is necessary in order to
                    enter into a contract with us or to receive our products or
                    services as requested by you.
                  </p>
                  <h3>Personal Data Shared with Third Parties</h3>
                  <p>
                    We only share your Personal Data with companies,
                    organizations and individuals outside of PI Corporation as
                    described below or as described in a Specific Notice.
                  </p>
                  <ul>
                    <li>Recipients within PI Corporation:</li>
                    <p>
                      Depending on the categories of Personal Data and the
                      purposes for which the Personal Data has been collected,
                      different PI Corporation entities and the internal
                      departments within these entities may receive your
                      Personal Data. For example, our IT department may have
                      access to your account data, and our marketing and sales
                      departments may have access to your account data or data
                      relating to product orders. Moreover, other departments
                      within PI Corporation may have access to certain Personal
                      Data about you on a need-to-know basis, such as the legal
                      and compliance department, the finance department or
                      internal auditing.
                    </p>
                    <li>PI Corporation Affiliates:</li>
                    <p>
                      We share your Personal Data with PI Corporation’s
                      affiliates and business partners that distribute our
                      products, including our unaffiliated dealers,
                      manufacturers and suppliers in order for these companies
                      to contact you about their products, services or other
                      offerings that may be of interest to you.
                    </p>
                    <li>Service Providers:</li>
                    <p>
                      We may share your Personal Data with companies that
                      perform tasks on our behalf related to our business. Such
                      tasks may include processing transactions, locational
                      services, analyzing website or mobile application usage
                      data, electronic and postal mail service,
                      contests/surveys/sweepstakes administration, or marketing
                      services. Third-party service providers receive your
                      Personal Data as necessary to perform their role, and we
                      instruct them not to use your Personal Data for any other
                      purposes.
                    </p>
                    <li>As Required or Appropriate by Law:</li>
                    <p>
                      We will use or disclose your Personal Data if required or
                      authorized by applicable law as necessary to (without
                      limitation) comply with legal processes or respond to
                      requests from government authorities, enforce our terms
                      and conditions or investigate violations of them, to
                      detect, prevent or address fraud, security or technical
                      issues, or to protect our operations or those of our
                      affiliates, protect the rights and safety of PI
                      Corporation, its affiliates or individuals.
                    </p>
                    <li>Business Transfers:</li>
                    <p>
                      As we continue to develop our business, we might sell,
                      buy, merge or reorganize brands, stores, subsidiaries or
                      business units. In this regard, we may share your Personal
                      Data with a third party in such transactions if and to the
                      extent permitted by applicable law. Customer information
                      generally is one of the transferred business assets, but
                      remains subject to any preexisting applicable Privacy
                      Notice.
                    </p>
                  </ul>
                  <p>
                    We may share aggregated data that has been anonymized or
                    pseudonymized (so that you are not identified) with third
                    parties – like publishers, advertisers or connected websites
                    – and may make this data publicly available or use it for
                    any purpose.
                  </p>
                  <p>Disclosure or Sale of Personal Data</p>
                  <h3>Public Forums</h3>
                  <p>
                    Our websites may offer publicly accessible blogs, message
                    boards, or community forums. You should be aware that any
                    information you provide in these areas may be read,
                    collected, and used by others who access them.
                  </p>
                  <h3>
                    Links to Social Networking and other Third-Party Websites
                  </h3>
                  <p>
                    Our services may contain links to social networking and
                    other websites and mobile applications that are operated and
                    controlled by third parties. While we try to link only to
                    websites that share our high standards and respect for
                    privacy, we do not take responsibility for the content, or
                    the privacy practices employed by other websites. Unless
                    otherwise stated, any Personal Data you provide to any such
                    third-party website will be collected by that party and not
                    by us and will be subject to that party’s privacy policy (if
                    any), rather than this Privacy Notice. In such a situation,
                    we will have no control over, and shall not be responsible
                    for, that party’s use of the Personal Data you provide to
                    them.
                  </p>
                  <h3>Security</h3>
                  <p>
                    We maintain appropriate technical and organizational
                    measures to protect your Personal Data, including assuring
                    that third-party service providers who access or handle
                    Personal Data on our behalf and affiliates maintain such
                    safeguards. We seek to encrypt credit card numbers from
                    e-commerce transactions conducted on our websites using
                    secure socket layer ("SSL") technology.
                  </p>
                  <p>
                    However, no method of Internet transmission or electronic
                    storage is 100% secure or error-free, so it is not possible
                    to guarantee absolute security. You must protect against
                    unauthorized access to your password and to your computer,
                    and be sure to sign off when finished using a shared
                    computer. If you have reason to believe that your
                    interaction with us is no longer secure (e.g., if you feel
                    that the security of any account you might have with us has
                    been compromised), please immediately notify us by emailing
                    info@piinco.com or by calling us at 9974841011
                  </p>
                  <p>
                    Where we have given you or you have chosen a password which
                    enables you to access certain portions of our websites, you
                    are responsible for keeping this password confidential. You
                    should not share your password with anyone.
                  </p>
                  <h3>International Data Flows </h3>
                  <p>
                    As PI Corporation is a global organization, the Personal
                    Data that we collect or receive about you may be transferred
                    to and/or processed in accordance with applicable law by
                    entities that are located inside or outside the local
                    jurisdiction it is collected in. The laws of these
                    jurisdictions may differ from the laws of your country.
                    Regardless, we take necessary measures to ensure that
                    transfers out of the local jurisdiction are adequately
                    protected as required by applicable data protection laws,
                    such as adopting the European Commission’s Standard
                    Contractual Clauses or another safeguard to enable the
                    transfer in accordance with applicable law. You can ask for
                    a copy of such appropriate safeguards by contacting us as
                    stated above under the section Contacting Us.
                  </p>
                  <h3>Your Choices and Rights</h3>
                  <p>
                    Depending on your location and applicable law, you may have
                    certain rights in relation to your Personal Data, as
                    described below. To exercise these rights, please contact us
                    using the information provided in the Contacting Us section
                    above.
                  </p>
                  <ul>
                    <li>Right to Access, Correct, Update and Portability:</li>
                    <p>
                      You may have the right to obtain from us confirmation as
                      to whether or not Personal Data concerning you is being
                      processed, and, where that is the case, to request access
                      to the Personal Data. You may have the right to obtain a
                      copy of the Personal Data undergoing processing in a
                      structured, commonly used and machine-readable format and
                      you may have the right to transmit the data to another
                      entity without hinderance from us. For further copies
                      requested by you, we may charge a reasonable fee based on
                      administrative costs. In the event Personal Data
                      concerning you is inaccurate, you may have the right to
                      have it corrected, and in some cases completed by means of
                      providing a supplementary statement.
                    </p>
                    <li>Deletion: </li>
                    <p>
                      Under certain circumstances, you may have the right to
                      obtain from us the erasure of Personal Data concerning you
                      and we may be obliged to erase such Personal Data.
                    </p>
                    <li>Objection to or Restriction of Processing:</li>
                    <p>
                      Under certain circumstances, you may have the right to
                      object to the processing of your Personal Data by us, or
                      request that we restrict the processing of your Personal
                      Data. In such case, the respective data will be marked and
                      may only be processed by us for certain specific purposes,
                      or not at all (especially in the case of profiling to
                      better understand your interests or for marketing).
                      Furthermore, under certain circumstances with respect to
                      automated individual decision-making, you have the right
                      to obtain human intervention, express your point of view,
                      and contest the decision. You also may have the right to
                      lodge a complaint with the competent data protection
                      supervisory authority.
                    </p>
                    <li>Opt out:</li>
                    <p>
                      In case you have given us your consent for direct
                      marketing purposes (e.g., you actively subscribed to our
                      newsletters) you can withdraw your consent with future
                      effect by contacting us. You may also have the right to
                      opt-out of the sale of certain types of personal
                      information under applicable law.
                    </p>
                  </ul>
                  <p>
                    Please note that requests to exercise your rights with us
                    may be subject to verification of the identity of the
                    requester. How we verify your identity will depend on the
                    manner and context in which your data was collected. You may
                    be entitled to use an authorized agent to exercise your
                    rights on your behalf, and if you choose to do so, the agent
                    will be required to verify both their identity and their
                    authority to act on your behalf.
                  </p>
                  <p>South Africa Residents</p>
                  <p>
                    Please see attached external privacy statement and access to
                    information manual applicable to South Africa.
                  </p>
                  <h3>How long we keep your data</h3>
                  <p>
                    Your Personal Data will be kept as long as necessary to
                    provide you with the services and products you requested.
                    Once you have ended Your Relationship with us, we will
                    either delete or anonymize your Personal Data, unless a
                    statutory retention requirement applies (e.g. for tax
                    purposes, compliance with laws or in regards to a legal
                    claim). To the extent possible, we will restrict the
                    processing of your Personal Data for such limited purposes
                    after the termination of Your Relationship and on a
                    need-to-know basis only.
                  </p>
                  <h3>Notice to PI Corporation Investors</h3>
                  <p>
                    Certain pages of our websites allow investors in PI
                    Corporation to obtain publicly available information related
                    to company performance. Investors may view and/or request
                    certain information through our website here. Depending upon
                    what information is requested by an investor, this feature
                    of our website may ask for, and the investor may choose to
                    provide, the investor’s name, title, organization,
                    occupation, address, telephone number, and email address
                    (“Investor Information”). PI Corporation will use Investor
                    Information to verify an investor’s identity and fulfill any
                    requests for information.
                  </p>
                  <h3>Notice to Employment Applicants</h3>
                  <p>
                    Certain pages of our websites include the ability for people
                    interested in working for PI Corporation or one of its
                    subsidiaries or companies to find out more information about
                    employment opportunities at PI Corporation, its affiliates
                    and/or subsidiaries. To apply for open positions using our
                    website, you must create an employment profile through our
                    website here, which includes the information that PI
                    Corporation requests you to provide, and that you may choose
                    to provide, to be considered for employment (the “Employment
                    Applicant Information”).
                  </p>
                  <p>
                    Prior to creating an employment profile, you must
                    affirmatively consent to the privacy terms governing the
                    submission of your information to PI Corporation for
                    employment purposes here. The terms that you agree to in
                    order to create an employment profile shall govern PI
                    Corporation’s use of the information you provide to apply
                    for employment. PI Corporation will use Employment Applicant
                    Information for evaluation and hiring purposes, and
                    communication in furtherance of the evaluation and hiring
                    purposes.
                  </p>
                  <h3>Children</h3>
                  <p>
                    In compliance with the Children’s Online Privacy Protection
                    Act, 15 U.S.C., §§ 6501-06 and 16 C.F.R., §§ 312.1-312.12,
                    our website does not permit children under 13 years of age
                    to become users, and we do not intentionally collect
                    information from children. By using our website, you
                    represent that you are 13 years of age or older.
                  </p>
                  <h3>Cookies</h3>
                  <p>
                    We use “cookies,” a small text file transferred to your
                    device, along with similar technologies (e.g., internet tag
                    technologies, web beacons and embedded scripts) to help
                    provide you with a better, more personalized user
                    experience. To learn more about how we use cookies and to
                    change your cookie settings, please click here.
                  </p>
                  <h3>“Do Not Track” Signals</h3>
                  <p>
                    Some internet browsers incorporate a “Do Not Track” feature
                    that signals to websites you visit that you do not want to
                    have your online activity tracked. Given that there is not a
                    uniform way that browsers communicate the “Do Not Track”
                    signal, our websites do not currently interpret, respond to
                    or alter their practices when they receive “Do Not Track”
                    signals.{" "}
                  </p>
                  <h3>Google reCAPTCHA</h3>
                  <p>
                    We utilize Google reCAPTCHA, which is a free service that
                    protects websites from spam and abuse using advanced risk
                    analysis techniques to tell humans and bots apart. Google
                    reCAPTCHA works differently depending on what version is
                    deployed. For example, you may be asked to check a box
                    indicating that you are not a robot or Google reCAPTCHA may
                    detect abusive traffic without user interaction. Google
                    reCAPTCHA works by transmitting certain types of information
                    to Google, such as the referrer URL, IP address, visitor
                    behavior, operating system information, browser and length
                    of the visit, cookies, and mouse movements. Your use of
                    Google reCAPTCHA is subject to Google’s Privacy Policy and
                    Terms of Use. More information as to Google reCAPTCHA and
                    how it works is available here.
                  </p>
                  <h3>India Privacy Notice</h3>
                  <p>
                    This India Privacy Notice supplements the information
                    contained in our Privacy Notice and applies solely to
                    individual residents of the state of India (“consumers” or
                    “you”). Under the India Consumer Privacy Act ("CCPA"), as
                    amended by the India Privacy Rights Act ("CPRA")
                    (collectively, "India Privacy Law"), PI Corporation is
                    required to provide you with a privacy notice in a specified
                    format. If you are a consumer as defined by India Privacy
                    Law, please review this section below as it is a version of
                    the privacy notice applicable to your personal information
                    as an individual or business ("India Personal Information").
                    If you are a India resident, please note the following about
                    how we have collected, used, disclosed, and otherwise
                    processed your personal information over the past 12 months,
                    as well as additional rights you may have with respect to
                    your personal information. This Privacy Notice does not
                    reflect our processing of India residents' personal
                    information where an exception under India law applies.
                  </p>
                  <p>
                    For the purposes of this India Privacy Notice, Personal
                    Information does not include:
                  </p>

                  <li>
                    Publicly available information from government records.
                  </li>
                  <li>
                    Deidentified, aggregated or anonymized information that is
                    maintained in a form that is not capable of being associated
                    with or linked to you.
                  </li>
                  <li>
                    Lawfully obtained, truthful information that is of public
                    concern.
                  </li>
                  <li>
                    Information excluded from the scope of India Privacy Law.
                  </li>
                  <li>
                    Information reflecting communications or transactions
                    between us and representatives of our business customers and
                    other third-party entities in connection with us
                    considering, providing or receiving a product or service to
                    or from the business customer or other third-party entity.
                  </li>
                  <h3>India Personal Information.</h3>
                  <p>
                    Consistent with the section "Personal Data Collection"
                    above, we may collect certain categories of information
                    about India residents, such as:
                  </p>
                  <ul>
                    <li>Identifiers:</li>
                    <p>
                      including your name, email, username, phone number,
                      company, industry and address, as well as the name and
                      address of the dealer that sold you your product;
                    </p>
                    <li>Personal information:</li>
                    <p>
                      including, in addition to identifiers and other data
                      mentioned, your age, education, profession, occupation,
                      income level, credit card number, billing address,
                      shipping address, security code and other payment
                      transaction and verification details, as well as
                      consumer’s application and claims history;
                    </p>
                    <li>Characteristics of protected classifications:</li>
                    <p> including gender, and citizenship;</p>
                    <li>Commercial information:</li>
                    <p> including purchase history;</p>
                    <li>
                      Internet or other electronic network activity information:
                    </li>
                    <p>
                      including pages and products viewed, items added to your
                      shopping cart, products and services you have purchased
                      from us, ads that you clicked on, emails from us that you
                      opened, browser type, and operating system (“OS”);
                    </p>
                    <li>Geolocation data:</li>
                    <p>
                      Internet Protocol (“IP”) address and device and
                      geolocation information;
                    </p>
                    <li>Audio, electronic, visual or similar information:</li>
                    <p>
                      including in connection with use of PI Corporation.
                      systems, such as voicemail, as well as CCTV and security
                      monitoring systems;
                    </p>
                    <li>Inferences:</li>
                    <p>
                      Your mobile OS, a mobile device identifier embedded by us,
                      or other commonly used mobile device and unique personal
                      identifiers such as cookies, beacons, pixel tags or
                      similar technology; and
                    </p>
                    <li>Publicly available information:</li>
                    <p>
                      about you from third-party sources, such as the postal
                      service for shipping address verification.
                    </p>
                    <p>
                      Certain India Personal Information that we collect about
                      you may be considered sensitive personal information
                      within the meaning of India Privacy Law, including: credit
                      card information (for billing and transactional purposes
                      in connection with providing products and services) and
                      precise geolocation information. We only use and disclose
                      sensitive personal information as necessary in connection
                      with the performance of services and the provision of
                      goods, compliance with federal, state, or local laws, and
                      as otherwise permitted by India Privacy Law.
                    </p>
                  </ul>
                  <h3>Sources </h3>
                  <p>
                    We generally source India Personal Information directly from
                    you (for instance, when you provide information as part of a
                    business transaction or otherwise), third party reference
                    agencies, distributors and intermediaries, distributors and
                    intermediaries, data analytics providers, and social media
                    networks.
                  </p>
                  <h3>Purposes of Use</h3>
                  <p>
                    In addition to the purposes stated above in the section How
                    We Use Your Personal Data, we currently collect and use the
                    India Personal Information for the following business
                    purposes:
                  </p>
                  <li>
                    Auditing related to a current interaction with the consumer
                    and concurrent transaction, including, but not limited to,
                    counting ad impressions to unique visitors;
                  </li>
                  <li>
                    Detecting security incidents, protecting against malicious,
                    deceptive, fraudulent, or illegal activity, and prosecuting
                    those responsible for that activity;
                  </li>
                  <li>
                    Debugging to identify and repair errors that impair existing
                    intended functionality;
                  </li>
                  <li>
                    Performing services, including maintaining or servicing
                    accounts, providing customer service, processing or
                    fulfilling orders and transactions, verifying customer
                    information, processing payments, providing financing,
                    providing advertising or marketing services, providing
                    analytic services, or providing similar services;
                  </li>
                  <li>
                    Undertaking internal research for technological development
                    and demonstration;
                  </li>
                  <li>
                    Undertaking activities to verify or maintain the quality or
                    safety of a service or device that is owned, manufactured,
                    manufactured for, or controlled by us, and to improve,
                    upgrade, or enhance the service or device; and
                  </li>
                  <li>
                    Fulfilling our services, including completing transactions.
                  </li>
                  <p>
                    In addition to the purposes stated above in the section How
                    We Use Your Personal Data, we currently collect and use the
                    India Personal Information for the following commercial
                    purposes:
                  </p>
                  <li>
                    Advertising, marketing, and public relations (including
                    direct marketing);
                  </li>
                  <li>Market research and competitor analysis;</li>
                  <li>
                    To display personalized advertising when you visit our Sites
                    or a third party website;
                  </li>
                  <li>
                    To contact you with special offers and other information we
                    believe will be of interest to you;
                  </li>
                  <li>
                    To invite you to participate in surveys and provide feedback
                    to us;
                  </li>
                  <li>To better understand your needs and interests; and</li>
                  <li>To improve our marketing and promotional efforts.</li>
                  <h3>Disclosure of India Personal Information</h3>
                  <p>
                    We may disclose the categories of India Personal Information
                    described above for the business purposes described above
                    to:
                  </p>
                  <li>
                    Affiliates. For example, we may disclose India Personal
                    Information between and among PI Corporation entities and
                    its current and future parents, affiliates, subsidiaries,
                    brands and other companies under common control or
                    ownership;
                  </li>
                  <li>
                    Third party service providers that perform data processing
                    activities on our behalf, and subject to appropriate privacy
                    and security obligations;
                  </li>
                  <li>
                    Customers, distributors, and intermediaries in connection
                    with the performance of transactions;
                  </li>
                  <li>Government and other authorities as required by law;</li>
                  <li>
                    Auditors and advisors for the performance of audit functions
                    and advisors for the provision of legal and other advice.
                  </li>
                  <li>
                    Potential purchasers and other parties in connection with
                    the sale, purchase or merger of a business; and
                  </li>
                  <li>
                    Others to the extent necessary to comply with applicable law
                    and/or as otherwise permitted under India Privacy Law.
                  </li>
                  <p>
                    We may disclose Identifiers, Internet or other electronic
                    network activity information, and Geolocation data for the
                    commercial purposes described above to:
                  </p>
                  <li>adtech providers;</li>
                  <li>social media;</li>
                  <li>and analytics providers.</li>
                  <p>
                    We do not sell (as this term is defined under India Privacy
                    Law) the India Personal Information of consumers we know to
                    be less than 16 years of age as we do not knowingly collect
                    data of a minor.
                  </p>
                  <h3>Data Retention.</h3>
                  <p>
                    We keep your India Personal Information for as long as
                    needed or permitted in light of the purpose(s) for which it
                    was obtained. The criteria used to determine our retention
                    periods include (i) for as long as we have an ongoing
                    relationship with you; (ii) as required by a legal
                    obligation to which we are subject to; or (iii) as advisable
                    in light of our legal position (such as in regard of
                    applicable statutes of limitations, litigation, or
                    regulatory investigations). We may remove your India
                    Personal Information for inactive accounts from our
                    database, subject to any applicable legal or regulatory
                    obligations. Once you have ended Your Relationship with us,
                    we will either delete or anonymize your India Personal
                    Information, unless a statutory retention requirement
                    applies (e.g. for tax purposes, compliance with laws or in
                    regards to a legal claim). To the extent possible, we will
                    restrict the processing of your India Personal Information
                    for such limited purposes after the termination of Your
                    Relationship and on a need-to-know basis only.
                  </p>
                  <h3>India Privacy Rights. </h3>
                  <li>
                    India residents have certain rights with respect to their
                    India Personal Information collected by businesses. If you
                    are a India resident, you may exercise the following rights
                    regarding your India Personal Information by clicking here.
                  </li>
                  <li>Deletion of your India Personal Information;</li>
                  <li>
                    Correction of inaccurate India Personal Information that has
                    been collected about you;
                  </li>
                  <li>
                    The right to know/ access the categories of India Personal
                    Information that we collect about you, including:
                  </li>
                  <li>the specific pieces of India Personal Information;</li>
                  <li>
                    the categories of India Personal Information we have
                    collected about you;
                  </li>
                  <li>
                    the categories of sources from which we have collected India
                    Personal Information about you;
                  </li>
                  <li>
                    the categories of India Personal Information that we have
                    disclosed about you to third parties for a business purpose,
                    and the categories of recipients to whom this information
                    was disclosed;
                  </li>
                  <li>
                    the categories of India Personal Information we have sold
                    about you (if any), and the categories of third parties to
                    whom this information was sold; and
                  </li>
                  <li>
                    the business or commercial purposes for collecting or, if
                    applicable, selling India Personal Information about you.
                  </li>
                  <li>
                    The right to direct us not to sell or share India Personal
                    Information we have collected about you to third parties now
                    or in the future. If you are under the age of 16, you have
                    the right to opt in, or to have a parent or guardian opt in
                    on your behalf, to such sales.{" "}
                  </li>
                  <li>
                    The right to non-discrimination. We will not discriminate
                    against India residents if they exercise any of the rights
                    provided in India Privacy Law as described in this section.
                    As such, we will not deny goods or services to that India
                    resident; charge different prices or rates for goods or
                    services, including through the use of discounts or other
                    benefits or imposing penalties; provide a different level or
                    quality of goods or services to the India resident; or
                    suggest that the India resident will receive a different
                    price or rate for goods or services or a different level or
                    quality of goods or services. However, we are permitted to
                    charge a India resident a different price or rate, or
                    provide a different level or quality of goods or services,
                    if that difference is reasonably related to the value
                    provided to us by the individual’s data.
                  </li>
                  <p>
                    In some instances, we may decline to honor your request. For
                    example, we may decline to honor your request if we cannot
                    verify your identity or confirm that the India Personal
                    Information that we maintain relates to you. In other
                    instances, we may decline to honor your request where an
                    exception under India Privacy Law applies, such as where the
                    disclosure of India Personal Information would adversely
                    affect the rights and freedoms of another consumer. We will
                    respond within 45 days of receiving your request, unless we
                    request an extension. In the event a 45-day extension is
                    needed, we will notify you of the extension within the
                    initial 45-day period.
                  </p>
                  <h3>
                    If you are a India resident, to make a request described
                    above relating to the India Personal Information that we
                    collected (or shared with third parties) about you that is
                    not subject to an exception under India Privacy Law, you
                    may:
                  </h3>
                  <li>
                    call the toll-free number 1-888-905-0846 from Monday to
                    Friday from 10 a.m. to 7 p.m., excluding public holidays; or
                  </li>
                  <li>
                    contact PI Corporation at Data.privacy@PI Corporation.com.
                  </li>
                  <h3>
                    To Exercise Your Right to Opt-Out of the Selling and/or
                    Sharing of Your India Personal Information
                  </h3>
                  <p>
                    In addition to the above, India residents have the right to
                    opt-out of the sharing and sale of their India Personal
                    Information, as such terms are defined under India Privacy
                    Law. To exercise this right, you may submit a request by
                    calling us at 1-888-905-0846. In addition, we have
                    configured our cookie management tool to honor opt-out
                    preference signals sent by Global Privacy Control ("GPC")
                    within the browser. Due to technical limitations, the GPC
                    signal is only honored within the particular browser. Beyond
                    GPC, we do not currently respond to web browser “do not
                    track” signals or other mechanisms that indicate your
                    preference for not having information collected over time
                    and across different websites or digital apps following your
                    visit to one of our Sites.
                  </p>
                  <h3>Authorized representative</h3>
                  <p>
                    You can designate an authorized agent to make a request
                    under India law on your behalf in certain circumstances. If
                    you use an authorized agent for this purpose, we may ask you
                    to verify your identity or that you provided the authorized
                    agent signed permission to submit a request on your behalf.
                    If you provide an authorized agent with power of attorney
                    pursuant to Probate Code sections 4000 to 4465, it may not
                    be necessary to perform these steps and we will respond to
                    any request from such authorized agent in accordance with
                    India law.
                  </p>
                  <p>
                    You will not be penalized for exercising your rights to your
                    personal information. And, if we deny your request, we will
                    explain why.
                  </p>
                  <h3>Shine the Light Law</h3>
                  <p>
                    India residents are entitled once per year to request and
                    obtain certain information regarding our disclosure, if any,
                    of your personally identifiable information to third parties
                    for their direct marketing purposes during the immediately
                    prior calendar year. To make such a request, please send an
                    email to data.privacy@PI Corporation.com with the subject
                    line “Shine the Light Request” and your full name, email
                    address, and postal address in your message. In response to
                    your request, we will provide you with a notice describing
                    the cost-free means to opt-out of our sharing your
                    personally identifiable information with third parties with
                    whom we do not share the same brand name, if the third party
                    will use it for their direct marketing purposes.
                  </p>
                  <p>
                    We do not engage in providing financial incentives for the
                    disclosure of Business Information.
                  </p>
                  <h3>Accessibility</h3>
                  <p>
                    We are committed to ensuring this Privacy Notice is
                    accessible to individuals with disabilities. If you wish to
                    access this Privacy Notice in an alternative format, please
                    contact us as stated above under the section “Contacting
                    Us.”
                  </p>
                  <h3>Changes</h3>
                  <p>
                    We may update this Privacy Notice from time to time. We will
                    notify you of any such changes, including when they will
                    take effect, by updating the “Last revised” date above or as
                    otherwise required by applicable law.
                  </p>
                </div>
              </div>
              {/*  /.tab-content */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyNoticePage;
