import React from "react";
import shape from "../../assets/images/shape/line-shape-2.svg";
import img from "../../assets/images/assets/ils_10.svg";
import { Link } from "react-router-dom";
import img1 from "../../assets/img/Product/Gui_builder/Forum.svg";
import { useGetHomeSvgData } from "../../hooks";

const QualityPage = () => {
  let fileNames = ["innovation","innovation1"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <div>
      <div className="fancy-hero-three">
        
        <div className="bg-wrapper">
          <div className="container"
          data-aos="fade-up"
          data-aos-duration="1200">
            <div className="row">
              <div className="col-xl-9 col-lg-10 m-auto">
              
                <h1 className="heading">Quality and Research</h1>
              </div>
              {data &&
              data.svgDataArray.map((val, index) => {
                if (val.fileName === "innovation") {
                  return <object key={index} data={val.svgDataURL}></object>;
                } else {
                  return null; // Or you can choose to return some other element if the condition is not met
                }
              })}
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-text-block-thirteen pt-0 md-pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
             
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Innovation</h2><br/>
                </div>
                {/* /.title-style-two */}
                <p>
                GUIview aims to discover standard, open, simple, and flexible products by creating unique designs and innovative solutions. Our organization has achieved great success thanks to our flexible architecture, which guarantees automation application for users. Our SCADA platform is both simple and secure, while also being reliable and compatible with other systems. Regular updates ensure that users have access to new, future-oriented technology.

                </p><br/>
                <p>
                We value user input and use it to develop our automation software products with updated technology. Our production and distribution team deserves thanks for their knowledge gained through research and development, as well as for providing real-world solutions when needed.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              
            >
              {data &&
              data.svgDataArray.map((val, index) => {
                if (val.fileName === "innovation1") {
                  return <img key={index} src={val.svgDataURL}></img>;
                } else {
                  return null; // Or you can choose to return some other element if the condition is not met
                }
              })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
      {/* /.block-style-twelve */}

      <div className="fancy-text-block-nine mt-130 md-mt-80">
        
        <div className="container">
          <div className="title-style-two text-center mb-35 md-mb-10">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <h2>Research and development</h2>
              </div>
            </div>
          </div>
          {/* End title-style-two */}

          <div className="row">
            <div className="col-xl-10 m-auto">
              <p
                className=""
                
              >
                As we know the importance of research and development that is
                why we put more emphasis on Research and development. It is a
                part of our basic strategy. We give the best and best-aligned
                solution to our product using advanced technology based on
                market trends and requirements of users. Although our product
                has advanced technology and unique feature, we provide products
                at a sustainable and fair price.
              </p><br/>
              <p
                className=""
                
              >
                GUI View spends a huge amount of budget on research and analysis
                to give the best product to our user. We invest more than 40% of
                resources in research and development to know about market
                trends, recent technologies. Therefore, we can apply recent
                features to our products' architecture for completing the user's
                requirements.
              </p>
            </div>
          </div>
          {/* End .row */}
        </div>
      </div>
    </div>
  );
};

export default QualityPage;
