/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import icon from "../../assets/images/icon/147.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useGetHomeSvgData } from "../../hooks";

const featuresContent = [
  {
    icon: icon,
    title: "A Technical Support Team At your service",
    delayAnimation: "",
  },
  {
    icon: icon,
    title: "Personalized Consultancy",
    delayAnimation: "100",
  },
  {
    icon: icon,
    title: "Upgrade policy for the entire product life cycle",
    delayAnimation: "200",
  },
  {
    icon: icon,
    title: "Learning, Training, Courses, Workshops",
    delayAnimation: "300",
  },
  {
    icon: icon,
    title: "Pre-sales and Post-sales Customer Support",
    delayAnimation: "400",
  },
  {
    icon: icon,
    title: "A website complete with all developer tools",
    delayAnimation: "500",
  },
];
const TechnicalSupportPage = () => {
  let fileNames = ["support"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <div>
      <div className="fancy-hero-three">
        
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 m-auto">
              <h1 className="heading">Technical Support</h1>
              <br/>
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "support") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
              {/* <img src={support} alt="feature" /> */}
                
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div
        className="fancy-feature-twentySeven lg-container pt-120 pb-35 md-pt-60"
        id="product"
      >
        <div className="container">
          <div className="title-style-twelve text-center mb-70 md-mb-20">
            <div className="row">
              <div className="col-xl-10 col-lg-11 m-auto">
                <h2>
                  <span>Partnership start with providing services</span>
                </h2>
                <p className="mt-40 md-mt-30">
                  Every designer knows that having an excellent product is not
                  enough. A software platform requires adequate technical
                  services that respond quickly and effectively with the right
                  solutions to whatever problem being experienced.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {featuresContent.map((val, i) => (
              <div
                className="col-lg-4 col-sm-4"
                data-aos="fade-up"
                data-aos-delay={val.delayAnimation}
                key={i}
              >
                <div className="block-style-twentySeven">
                  <div className="icon d-flex align-items-end justify-content-center">
                    <img src={`${val.icon}`} alt="icon" />
                  </div>
                  <h4 className="font-gordita">{val.title}</h4>
                </div>
                {/* /.block-style-twentySeven */}
              </div>
            ))}
          </div>{" "}
        </div>
      </div>
      {/* /.fancy-feature-six */}

      {/*=====================================================
				Useable Tools
			===================================================== */}
      <div
        className="faq-classic pt-100 pb-35 md-pt-100"
        // style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title-style-one">
                <h3>Need to contact Technical Support?</h3>
                <h3>Open a Support Ticket.</h3>
              </div>
              <Link
                to="/support-ticket"
                className="theme-btn-one mt-50 md-mt-30"
              >
                Open A Ticket
              </Link>
              <div className="title-style-one mt-5">
                <h3>Need Technical Support?</h3>
                <p className="font-rubik mt-2">
                  The GUIView Technical Support Service is composed of highly
                  qualified experts working in close connection with the GUIView
                  Developer Team. In order to ensure an effective and efficient
                  service to all users, the support service is regulated by
                  specific procedures. Please refer to&nbsp;
                  <a className="link" href="/support-ticket" style={{ color: "blue" }}>
                    Service&nbsp;Access&nbsp;terms.
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="md-mt-60">
                <div className="title-style-one">
                  <h3>Already a customer?</h3>
                  <p className="font-rubik mt-2">
                    LOG IN AND ACCESS YOUR RESERVED AREA
                  </p>
                  <Link to="/login" className="theme-btn-one mt-30 md-mt-30">
                    Login
                  </Link>
                  <div className="title-style-one mt-5">
                    <h3>Not a Customer?</h3>
                    <h4 className="mt-3">CONTACT OUR SALES OFFICE</h4>
                    <p className="font-rubik mt-3">
                      We are at your disposal for further information
                    </p>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FontAwesomeIcon
                          icon={faPhone}
                          style={{ marginRight: "8px" }}
                        />
                        <h4 style={{ margin: 0 }}>0281 - 2364311</h4>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          style={{ marginRight: "8px" }}
                        />
                        <h4 style={{ margin: 0 }}>support@guiview.com</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalSupportPage;
