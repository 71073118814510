import { useQuery } from "react-query";

import { HomeService } from "../api";
import { toast } from "react-toastify";
const onDefaultError = (error) => {
  toast.error(error.message);
};

/**
 * Hooks for Home page data
 * */
const useGetHomeNewsData = (onSuccess, onError = onDefaultError) => {
  return useQuery(["home-view"], HomeService.getHomeNewsData, {
    onSuccess,
    onError,
  });
};

const useGetHomeBlogData = (onSuccess, onError = onDefaultError) => {
  return useQuery(["blog-view"], HomeService.getHomeBlogData, {
    onSuccess,
    onError,
  });
};


const useGetHomeSvgData = (fileNames, onSuccess, onError = onDefaultError) => {
  return useQuery("svg-view", () => HomeService.getHomeSvgData({ fileNames }), {
    onSuccess,
    onError,
  });
};
export { useGetHomeNewsData, useGetHomeSvgData, useGetHomeBlogData };
