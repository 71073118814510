import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { PublicLayout } from "../common/layouts";
import { loader } from "../store/features/loaderSlice";
import { loggedUser } from "../store/features/authSlice";
import { LoadingSpinner } from "../common/components";

const AuthRoute = () => {
  let redirectUrl = "/";

  /**
   * using selector hooks from redux to get data
   */
  const checkLoading = useSelector(loader);
  const checkLoggenInUser = useSelector(loggedUser);
  const isLoggedIn = checkLoggenInUser.isLoggedIn;

  return !isLoggedIn ? (
    <PublicLayout>
      {checkLoading ? <LoadingSpinner /> : ""} <Outlet />
    </PublicLayout>
  ) : (
    <Navigate
      to={{
        pathname: redirectUrl,
      }}
      replace
    />
  );
};

export default AuthRoute;
