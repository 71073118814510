/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { useGetHomeSvgData } from "../../hooks";

const PharmaceuticalPage = () => {
  let fileNames = ["pharmaceuticalani","pharmaceutical"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <>
      <div className="fancy-hero-three">
      
        <div className="">
          <div className="container">
            <div className="row">
              {/* <div className="col-xl-9 col-lg-10 m-auto">
                <h1 className="heading">Pharmaceutical</h1>
              </div> */}
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "pharmaceuticalani") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-text-block-thirteen pt-40 md-pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Pharmaceutical </h2><br/>
                </div>
                {/* /.title-style-two */}
                <p>
                The pharmaceutical industry can enhance operational efficiency and regulatory compliance with GUIview’s advanced solutions, powered by GUI Builder and Cloud View. GUI Builder offers robust tools for monitoring and controlling critical processes in manufacturing, packaging, and quality control. Its intuitive interface allows seamless integration and real-time visualization of production parameters, ensuring adherence to stringent quality standards and reducing downtime.
                </p><br/>
                <p>
                <b>Cloud View</b> complements these features by providing remote access to vital data and control functions. Pharmaceutical managers can monitor processes, receive alerts, and make data-driven decisions from anywhere, ensuring compliance with regulatory requirements and optimizing operational efficiency
                </p><br/>
                <p>Together, GUI Builder and Cloud View deliver a powerful platform for the pharmaceutical industry, offering real-time data access, remote monitoring, and enhanced control. Trust GUIview to support your pharmaceutical operations with cutting-edge technology and ensure quality and compliance across your production processes.</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "pharmaceutical") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PharmaceuticalPage;
