import { React } from "react";
import PropTypes from "prop-types";

import { AfterSignuoNavbarHeader } from "../Header/AfterSignupNavbar";
import { Footer } from "../Footer/Footer";
import { ScrollToTop } from "../../components";

const PrivateLayout = (props) => {
  return (
    <div {...props}>
      <AfterSignuoNavbarHeader />
      <section>{props.children}</section>
      <ScrollToTop />
      <Footer />
    </div>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.any.isRequired,
  active: PropTypes.string,
};

export { PrivateLayout };
