import * as Yup from "yup";
let validationSchema = Yup.object({
  name: Yup.string("Name is Required").required("Name is Required"),
  email: Yup.string("Email is Required")
    .required("Email is Required")
    .email("Email Must be Valid"),
  password: Yup.string("Password is Required").required("Password is Required"),
  company: Yup.string("Company is Required").required("Company is Required"),
  businessrole: Yup.string("Business Role is Required").required(
    "Business Role is Required"
  ),
  vatnumber: Yup.string("VAT Number is Required").required(
    "VAT Number is Required"
  ),
  street: Yup.string("Street is Required").required("Street is Required"),
  city: Yup.string("City is Required").required("City is Required"),
  postalcode: Yup.string("Postal Code is Required").required(
    "Postal Code is Required"
  ),
  phonenumber: Yup.string("Phone is Required").required("Phone is Required"),
  username: Yup.string("Username is Required").required("Username is Required"),
});
export default validationSchema;
