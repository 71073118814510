import { toast } from "react-toastify";

import { TutorialVideoServices } from "../api";
import { useQuery } from "react-query";

/**
 * Hooks for Authentication Process
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useViewTutorialVideo = (id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    "tutorial-view",
    () => TutorialVideoServices.viewTutorialVideo({ id }),
    {
      onSuccess,
      onError,
    }
  );
};

export { useViewTutorialVideo };
