/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useGetHomeSvgData } from "../../hooks";

const AutomotivePage = () => {
  let fileNames = ["automotiveind2","automotiveind1"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <>
      <div className="fancy-hero-three">
        
        <div className="">
          <div className="container">
            <div className="row">
              {/* <div className="col-xl-9 col-lg-10 m-auto">
                <h1 className="heading">Automotive</h1>
              </div> */}
               {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "automotiveind2") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-text-block-thirteen pt-40 md-pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Automotive industry</h2><br/>
                </div>
                {/* /.title-style-two */}
                <p>
                The automotive industry can greatly benefit from GUIview’s innovative solutions, powered by GUI Builder and Cloud View. <b>GUI Builder</b> enables real-time monitoring and control of manufacturing processes, including assembly lines, robotics, and quality control systems. Its intuitive interface facilitates seamless integration with various industrial devices, ensuring efficient and smooth operations.
                </p><br/>
                <p>
                <b>Cloud View</b> enhances these capabilities by providing remote access to critical production data and control functions. Automotive manufacturers can monitor production metrics, receive alerts, and make informed decisions from anywhere, optimizing efficiency and ensuring high-quality output.
                </p><br/>
                <p>Together, GUI Builder and Cloud View offer a powerful platform for the automotive industry, delivering real-time data, remote accessibility, and enhanced operational control. Trust GUIview to drive innovation and efficiency in your automotive manufacturing processes with state-of-the-art technology.</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "automotiveind1") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AutomotivePage;
