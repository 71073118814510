import { useQuery } from "react-query";

import { GeneralsettingService } from "../api";
import { toast } from "react-toastify";
const onDefaultError = (error) => {
  toast.error(error.message);
};

/**
 * Hooks for Home page data
 * */
const useGetGeneralsettingData = (onSuccess, onError = onDefaultError) => {
  return useQuery(["generalsetting-view"], GeneralsettingService.getGeneralsettingData, {
    onSuccess,
    onError,
  });
};



export { useGetGeneralsettingData };
