import React from "react";
import NointernetPage from "../../assets/img/No connection-amico.png";
import Logo from "../../assets/images/icon/53.svg";

export default function NoInternetPage() {
  /**
    This function will call on Try again button and will reload page
   */
  const handleClick = () => {
    window.location.reload();
  };
  return (
    <>
      <div className="error-page d-lg-flex align-items-center">
        <div className="img-holder order-lg-last">
          <img
            src={NointernetPage}
            alt="media"
            className="w-100 illustration"
          />
        </div>
        <div className="text-wrapper order-lg-first">
          <h1 className="font-slab">No Internet Connection</h1>
          <p className="font-rubik">
            Please check your network connection and try again. Make sure you
            are connected to the internet and then refresh the page.
          </p>
          <button
            onClick={handleClick}
            className="back-home font-rubik d-flex align-items-center justify-content-center"
          >
            <span>Try Again</span>
            <img src={Logo} alt="icon" />
          </button>
        </div>
        {/* /.text-wrapper */}
      </div>
    </>
  );
}
