import React, { useState } from "react";
import { useViewTutorialVideo } from "../../hooks/useRQTutorialVideo";
import { useParams } from "react-router-dom";
import ModalVideo from "react-modal-video";
import img1 from "../../../src/assets/images/media/img_107.webp";

const TutorialVideoPage = () => {
  const { id } = useParams();
  const [data, setData] = useState("");
  const [isOpen, setOpen] = useState(false);

  useViewTutorialVideo(id, ({ data: video }) => {
    if (video) {
      setData(video);
    }
  });
  const removeHTMLTags = (input) => {
    if (typeof input === "string" || input instanceof String) {
      return input.replace(/<[^>]*>?/gm, "");
    } else {
      return input;
    }
  };
  return (
    <>
      <div className="fancy-text-block-twenty">
        <div className="container">
          <div className="fancy-video-box-one mb-130 md-mb-70 ratio ratio-21x9">
            <iframe
              width="560"
              className="main-img"
              height="315"
              src={data.videolink}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          {/* /.fancy-video-box-one */}

          <div className="row">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="client-info font-rubik">
                <span>Duration</span> : {data.duration}
              </div>
              <div className="title-style-five">
                <h2>{data.name}</h2>
              </div>
            </div>
            <div
              className="col-lg-6 ml-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="tab-content mt-20">
                <p className="font-rubik">
                  {removeHTMLTags(data?.description)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TutorialVideoPage;
