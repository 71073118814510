import { useFormik } from "formik";
import React from "react";
import validationSchema from "./SupportTicketValidationPage";
import { useAddSupportTicket,AddSupport, useGetHomeSvgData, useGetProductList } from "../../hooks";
import { toast } from "react-toastify";
import { defaultValue } from "../../helpers";
import BitrixForm from "../../common/components/BitrixForm";
import Select from "react-select";
import BitrixFormSupport from "../../common/components/BitrixFormSupport";
// import support from "../../assets/img/sticket.svg"
const SupportTicketPage = () => {
  
  // const { mutate: doSupportTicket, isLoading: supportTicketLoading } = useAddSupportTicket((res) => {
  //   toast.success(res.message);
  // });

  const doSupport = (data) => {
    // console.log("data",data)
    AddSupport(data)
  }
  
  let fileNames = ["supportticket"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading: homeSvgLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!homeSvgLoading) {
    data = general_data.data;
  }

  const { data: productList } = useGetProductList();

  const options = [];
  if (productList !== undefined) {
    productList.data.map((val) => {
      return options.push({
        value: val.id,
        label: val.name,
      });
    });
  }
  const iframeStyle = {
    minWidth: "300px", // Replace with your desired minimum width
    minHeight: "500px", // Replace with your desired minimum height
    width: "100%",
    height: "100%",
    border: "none",
  };
  // const formik = useFormik({
  //   initialValues: {
  //     name: "",
  //     company: "",
  //     street: "",
  //     city: "",
  //     postalcode: "",
  //     email: "",
  //     product: "",
  //     licencenumber: "",
  //     build: "",
  //     priority: "",
  //     object: "",
  //     problemdescription: "",
  //     phonenumber: "",
  //     problemtype: "",
  //   },
  //   validationSchema,
  //   onSubmit: async (values, { resetForm }) => {
  //     try {
  //       await doSupportTicket(values);
  //       resetForm();
  //     } catch (error) {
  //       console.error("Submission error:", error);
  //     }
  //   },
  // });

  return (
    <div>
      <div className="fancy-hero-three">
      
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 m-auto">
              <h1 className="heading">Open a Support Ticket</h1><br/>
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "supportticket") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
              {/* <img src={support} alt="feature" /> */}
                
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="container mt-5">
        <div className="title-style-two text-center mb-35 md-mb-10">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <h2>Access Service</h2>
            </div>
          </div>
        </div>

        {/* End title-style-two */}

        <div className="row">
          <div className="col-xl-10 m-auto">
            <p >
              The Technical Support Services are reserved for customers who have
              a least one valid Editor License.
            </p>
            <li
              
            >
              Fill in and submit the form below to open a Ticket for the Support
              Team.&nbsp; This ticket will be defined as “Case” with a
              progressive ID number in the traceability system.
            </li>
            <li
              
            >
              A priority level can be specified for the opened “Case”,
            </li>
            <li
            
            >
              The “Case” will remain open and managed until the reported problem
              has been solved.&nbsp; The customer will be informed by email when
              the “Case” is closed.
            </li>
            <li
              
            >
              The Support Service should be contacted for any communications
              regarding the opened “Case” by always referring to its ID number.
            </li>
            <li
              
            >
              The service is regulated by the Service Agreement and is reserved
              to users who have an Editor License.
            </li>
          </div>
        </div>
        {/* End .row */}
      </div>

      <div style={{ padding: "50px" }}>

      <div id="SupportForm">
            <BitrixFormSupport doSupport={doSupport}/>
            </div>
      {/* <div
            className="row controls"
            id="quote-form"
            style={{ width: "100%", height: "100%" }}
          >
            <iframe
              ref={iframeStyle}
              src="https://b24-eb2v4g.bitrix24.site/crm_form_eeiby/"
              style={{
                width: "100%",
                height: "1400px",
                border: "none",
                overflow: "hidden",
              }}
              title="description"
            ></iframe>
          </div> */}
       
        {/* <div className="form-style-light mt-3" style={{ padding: "20px" }}>
        <div className="container">
          <div className="title-style-two text-center mb-35 md-mb-10">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <h2>Open a support Ticket</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="title-style-five text-center mb-50 md-mb-50">
          <h2>
            <span></span>
          </h2>

         
        </div>
          <form id="contact-form" onSubmit={formik.handleSubmit}>
            <div className="messages"></div>
            <div className="row controls">
              <div className="col-md-6">
                <div className="input-group-meta form-group mb-30">
                  <label> First and Last Name</label>
                  <input
                    type="text"
                    placeholder="Enter Your Name and Surname"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    className={` ${formik.errors.name ? "is-invalid" : ""}`}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="invalid-feedback">{formik.errors.name}</div>
                  ) : null}
                </div>
              </div>
              

              <div className="col-6">
                <div className="input-group-meta form-group mb-30">
                  <label>Company</label>
                  <input
                    placeholder="Enter Your Company"
                    name="company"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.company}
                    className={` ${formik.errors.company ? "is-invalid" : ""}`}
                  />
                  {formik.touched.company && formik.errors.company ? (
                    <div className="invalid-feedback">
                      {formik.errors.company}
                    </div>
                  ) : null}
                </div>
              </div>
             

              <div className="col-12">
                <div className="input-group-meta form-group mb-30">
                  <label>Street / Square / Street number</label>
                  <input
                    placeholder="Enter Your street"
                    name="street"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.street}
                    className={` ${formik.errors.street ? "is-invalid" : ""}`}
                  />
                  {formik.touched.street && formik.errors.street ? (
                    <div className="invalid-feedback">
                      {formik.errors.street}
                    </div>
                  ) : null}
                </div>
              </div>
              

              <div className="col-6">
                <div className="input-group-meta form-group mb-30">
                  <label>City/ country</label>
                  <input
                    placeholder="Enter Your city"
                    name="city"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    className={` ${formik.errors.city ? "is-invalid" : ""}`}
                  ></input>
                  {formik.touched.city && formik.errors.city ? (
                    <div className="invalid-feedback">{formik.errors.city}</div>
                  ) : null}
                </div>
              </div>
             

              <div className="col-6">
                <div className="input-group-meta form-group mb-30">
                  <label>Postal Code / Zip Code</label>
                  <input
                    placeholder="Enter Your postalcode"
                    name="postalcode"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.postalcode}
                    className={` ${
                      formik.errors.postalcode ? "is-invalid" : ""
                    }`}
                    onKeyPress={(e) => {
                      if (!/^[0-9]+$/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {formik.touched.postalcode && formik.errors.postalcode ? (
                    <div className="invalid-feedback">
                      {formik.errors.postalcode}
                    </div>
                  ) : null}
                </div>
              </div>
              
              <div className="col-6">
                <div className="input-group-meta form-group mb-30">
                  <label>Telephone</label>
                  <input
                    placeholder="Enter Your phonenumber"
                    name="phonenumber"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phonenumber}
                    className={` ${
                      formik.errors.phonenumber ? "is-invalid" : ""
                    }`}
                    onKeyPress={(e) => {
                      if (!/^[0-9]+$/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {formik.touched.phonenumber && formik.errors.phonenumber ? (
                    <div className="invalid-feedback">
                      {formik.errors.phonenumber}
                    </div>
                  ) : null}
                </div>
              </div>
          

              <div className="col-6">
                <div className="input-group-meta form-group mb-30">
                  <label>Email</label>
                  <input
                    placeholder="Enter Your Email"
                    name="email"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className={` ${formik.errors.email ? "is-invalid" : ""}`}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="invalid-feedback">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
              </div>
          

              <div className="col-12">
                <div className="input-group-meta form-group mb-30">
                  <label>Product</label>
                  <select
                    name="product"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.product}
                    className={`form-control ${
                      formik.errors.product ? "is-invalid" : ""
                    }`}
                    style={{ width: "100%", height: "100%" }}
                  >
                    <option value="">Select Product</option>
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {formik.touched.product && formik.errors.product ? (
                    <div className="invalid-feedback">
                      {formik.errors.product}
                    </div>
                  ) : null}
                </div>
              </div>


              <div className="col-6">
                <div className="input-group-meta form-group mb-30">
                  <label>Licence Number</label>
                  <input
                    placeholder="Enter Your Licence Number"
                    name="licencenumber"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.licencenumber}
                    className={` ${
                      formik.errors.licencenumber ? "is-invalid" : ""
                    }`}
                  />
                  {formik.touched.licencenumber &&
                  formik.errors.licencenumber ? (
                    <div className="invalid-feedback">
                      {formik.errors.licencenumber}
                    </div>
                  ) : null}
                </div>
              </div>
              

              <div className="col-6">
                <div className="input-group-meta form-group mb-30">
                  <label>Build</label>
                  <input
                    placeholder="Enter Your build"
                    name="build"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.build}
                    className={` ${formik.errors.build ? "is-invalid" : ""}`}
                  />
                  {formik.touched.build && formik.errors.build ? (
                    <div className="invalid-feedback">
                      {formik.errors.build}
                    </div>
                  ) : null}
                </div>
              </div>
            

              <div className="col-12">
                <div className="input-group-meta form-group mb-30">
                  <label>Priority</label>
                  <select
                    name="priority"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.priority}
                    className={`form-control ${
                      formik.errors.priority ? "is-invalid" : ""
                    }`}
                    style={{ width: "100%", height: "100%" }}
                  >
                    <option value="">Select a Priority</option>
                    <option value="Low">Low</option>
                    <option value="Medium">Medium</option>
                    <option value="High">High</option>
                    <option value="Simple Reporting">Simple Reporting</option>
                    
                  </select>
                  {formik.touched.priority && formik.errors.priority ? (
                    <div className="invalid-feedback">
                      {formik.errors.priority}
                    </div>
                  ) : null}
                </div>
              </div>
            

              <div className="col-12">
                <div className="input-group-meta form-group mb-30">
                  <label>Type Of Problem</label>
                  <select
                    name="problemtype"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.problemtype}
                    className={`form-control ${
                      formik.errors.problemtype ? "is-invalid" : ""
                    }`}
                    style={{ width: "100%", height: "100%" }}
                  >
                    <option value="">Select a problemtype</option>
                    <option value="Reproducible">Reproducible</option>
                    <option value="Non Reproducible">Non Reproducible</option>
                    
                  </select>
                  {formik.touched.problemtype && formik.errors.problemtype ? (
                    <div className="invalid-feedback">
                      {formik.errors.problemtype}
                    </div>
                  ) : null}
                </div>
              </div>
              

              <div className="col-12">
                <div className="input-group-meta form-group mb-30">
                  <label>Object</label>
                  <input
                    placeholder="Enter Your object"
                    name="object"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.object}
                    className={` ${formik.errors.object ? "is-invalid" : ""}`}
                  />
                  {formik.touched.object && formik.errors.object ? (
                    <div className="invalid-feedback">
                      {formik.errors.object}
                    </div>
                  ) : null}
                </div>
              </div>
            

              <div className="col-12">
                <div className="input-group-meta form-group lg mb-30">
                  <label>Problem Description</label>
                  <input
                    placeholder="Enter Your Problem Description"
                    name="problemdescription"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.problemdescription}
                    className={` ${
                      formik.errors.problemdescription ? "is-invalid" : ""
                    }`}
                  />
                  {formik.touched.problemdescription &&
                  formik.errors.problemdescription ? (
                    <div className="invalid-feedback">
                      {formik.errors.problemdescription}
                    </div>
                  ) : null}
                </div>
              </div>
            

              <div className="col-12">
                <button type="submit" className="theme-btn-two">
                  Submit
                </button>
              </div>
              
            </div>
          </form>
        </div> */}
      </div>
    </div>
  );
};

export default SupportTicketPage;
