import { useMutation } from "react-query";
import { toast } from "react-toastify";

import { SubscribeServices } from "../api";

/**
 * Hooks for Authentication Process
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useAddSubscribe = (onSuccess, onError = onDefaultError) => {
  return useMutation(SubscribeServices.addSubscribe, {
    onSuccess,
    onError,
  });
};

export { useAddSubscribe };
