import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
// import img1 from "../../../src/assets/images/guibuilder/Forum.svg";
import { useGetHomeSvgData, useGetTutorialList } from "../../hooks";
import { FILE_PATH } from "../../config";

const TabListContent = [
  "GUIBuilder",
  "GCOM virtualizer",
  "GIOT Gateway",
  "GCOM over TCP",
];

const TutorialPage = () => {
  let fileNames = ["videotutorial"];
  let data = "";
  /**
   * !This API will call when the page is set. When the response comes, we set the Home Page Data.
   */
  const { isLoading: homeSvgLoading, data: general_data } =
    useGetHomeSvgData(fileNames);
  if (!homeSvgLoading) {
    data = general_data.data;
  }
  let tutorial = [];
  /**
   * !This API will call when the page is set. When the response comes, we set the Home Page Data.
   */
  const { isLoading: tutorialLoading, data: tutorial_data } =
    useGetTutorialList();

  if (!tutorialLoading) {
    tutorial = tutorial_data.data;
    // console.log("tutorial_data", tutorial_data);
  }

  return (
    <div className="main-page-wrapper">
      <div className="fancy-hero-three padding_top">
        
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 m-auto">
                <h1 className="heading">Tutorial</h1>
                <br />
                {data &&
                  data.svgDataArray.map((val, index) => {
                    if (val.fileName === "videotutorial") {
                      return <img key={index} src={val.svgDataURL}></img>;
                    } else {
                      return null; // Or you can choose to return some other element if the condition is not met
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* =============================================
         Fancy Hero Six
        ==============================================  */}
      <div className="fancy-hero-six">
        <div className="container">
          <h1 className="heading">
            Get up and running straight away thanks to the GUIView Tutorials.
          </h1>
          <p className="sub-heading">
            GUIView offers users simple tutorials to become immediately
            operational with all the GUIView platforms in full autonomy.{" "}
          </p>
        </div>
      </div>
      {/* /.fancy-hero-six */}

      {/*  =============================================
            Fancy Portfolio Two
        ==============================================  */}
      <div className="fancy-portfolio-four lg-container">
        <div className="container">
          <div className="top-border bottom-border pb-130 md-pb-90">
            <Tabs className="portfolio-container">
              <div className="controls po-control-one text-center mb-90 md-mb-50 mt-90 md-mt-60">
                <TabList className="d-flex flex-wrap justify-content-center">
                  {TabListContent.map((tab, i) => (
                    <Tab key={i}>
                      <button
                        type="button"
                        className="control"
                        style={{ display: "inline-block", margin: "5px" }}
                      >
                        {tab}
                      </button>
                    </Tab>
                  ))}
                </TabList>
              </div>
              {/* End pc-control-one */}
              <TabPanel>
                <div className="mixitUp-container  gutter-space-one d-flex flex-wrap">
                  {tutorial.map((item, i) =>
                    item.productId === 1 ? (
                      <div
                        className="mix"
                        key={i}
                        data-aos="fade-right"
                        data-aos-delay={100}
                      >
                        <div className="portfolio-block-two position-relative">
                          <div className="d-flex align-items-center justify-content-center">
                            <Link to={`/videotutorial/${item.id}`}>
                              <img
                                src={`${FILE_PATH}/tutorial/${item.images}`}
                                alt={""}
                                className="tran4s img-meta"
                                style={{ width: "400px", height: "300px" }}
                              />
                            </Link>
                          </div>
                          <div className="hover-content">
                            <h3>
                              <Link to={`/videotutorial/${item.id}`}>
                                {item.name}
                              </Link>
                            </h3>
                            <div className="tag">
                              Duration : {item.duration}
                            </div>
                          </div>
                          {/* /.hover-content */}
                        </div>
                        {/* /.portfolio-block-two */}
                      </div>
                    ) : null
                  )}
                </div>
                {/* single mixitUp-container */}
              </TabPanel>
              <TabPanel>
                <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                  {tutorial.map((item, i) =>
                    item.productId === 2 ? (
                      <div
                        className="mix"
                        key={i}
                        data-aos="fade-right"
                        data-aos-delay={100}
                      >
                        <div className="portfolio-block-two position-relative">
                          <div className="d-flex align-items-center justify-content-center">
                            <Link to={`/videotutorial/${item.id}`}>
                              <img
                                src={`${FILE_PATH}/tutorial/${item.images}`}
                                alt={""}
                                className="tran4s img-meta"
                                style={{ width: "400px", height: "300px" }}
                              />
                            </Link>
                          </div>
                          <div className="hover-content">
                            <h3>
                              <Link to={`/videotutorial/${item.id}`}>
                                {item.name}
                              </Link>{" "}
                            </h3>
                            <div className="tag">
                              Duration : {item.duration}
                            </div>
                          </div>
                          {/* /.hover-content */}
                        </div>
                        {/* /.portfolio-block-two */}
                      </div>
                    ) : null
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                  {tutorial.map((item, i) =>
                    item.productId === 3 ? (
                      <div
                        className="mix"
                        key={i}
                        data-aos="fade-right"
                        data-aos-delay={100}
                      >
                        <div className="portfolio-block-two position-relative">
                          <div className="d-flex align-items-center justify-content-center">
                            <Link to={`/videotutorial/${item.id}`}>
                              <img
                                src={`${FILE_PATH}/tutorial/${item.images}`}
                                alt={""}
                                className="tran4s img-meta"
                                style={{ width: "400px", height: "300px" }}
                              />
                            </Link>
                          </div>
                          <div className="hover-content">
                            <h3>
                              <Link to={`/videotutorial/${item.id}`}>
                                {item.name}
                              </Link>{" "}
                            </h3>
                            <div className="tag">
                              Duration : {item.duration}
                            </div>
                          </div>
                          {/* /.hover-content */}
                        </div>
                        {/* /.portfolio-block-two */}
                      </div>
                    ) : null
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                  {tutorial.map((item, i) =>
                    item.productId === 4 ? (
                      <div
                        className="mix"
                        key={i}
                        data-aos="fade-right"
                        data-aos-delay={100}
                      >
                        <div className="portfolio-block-two position-relative">
                          <div className="d-flex align-items-center justify-content-center">
                            <Link to={`/videotutorial/${item.id}`}>
                              <img
                                src={`${FILE_PATH}/tutorial/${item.images}`}
                                alt={""}
                                className="tran4s img-meta"
                                style={{ width: "400px", height: "300px" }}
                              />
                            </Link>
                          </div>
                          <div className="hover-content">
                            <h3>
                              <Link to={`/videotutorial/${item.id}`}>
                                {item.name}
                              </Link>
                            </h3>
                            <div className="tag">
                              Duration : {item.duration}
                            </div>
                          </div>
                          {/* /.hover-content */}
                        </div>
                        {/* /.portfolio-block-two */}
                      </div>
                    ) : null
                  )}
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      {/*/.fancy-portfolio-two */}

      {/* =====================================================
            Footer Style Seven
        ===================================================== */}

      {/*/.theme-footer-seven */}
    </div>
  );
};

export default TutorialPage;
