import { toast } from "react-toastify";

import { ProductServices } from "../api";
import { useQuery } from "react-query";

/**
 * Hooks for Authentication Process
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useViewProduct = (id, onSuccess, onError = onDefaultError) => {
  return useQuery("product-view", () => ProductServices.viewProduct(id), {
    onSuccess,
    onError,
  });
};

export { useViewProduct };
