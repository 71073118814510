import Yup from "../../helpers/customValidation";
let validationSchema = Yup.object({
  full_name: Yup.string("Full Name is Required").required("Full Name is Required"),
  designation: Yup.string("Designation is Required").required("Designation is Required"),
    filename: Yup.mixed("File Name is Required")
      .required("File Name is Required"),
      
      
  });
  
  
  export default validationSchema;