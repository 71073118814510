import client from "../libs/HttpClient";

class SupportTicketServices {
  static addSupportTicket(request) {
    return client.post(`/open-ticket`, request, {});
  }
  static getProductList(request) {
    return client.get(`getproductlist`, request);
  }
}

export { SupportTicketServices };
