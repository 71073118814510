import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { PublicLayout } from "../common/layouts";
import { loader } from "../store/features/loaderSlice";
import Loader from "../common/components/Loader";

const PublicRoute = () => {
  // Calling selector hook from redux, to get data from stores, like loader, login info
  const checkLoading = useSelector(loader);

  /**
   * Checking if user is registered user or not
   */

  return (
    <PublicLayout>
      {checkLoading ? <Loader /> : ""}
      <Outlet />
    </PublicLayout>
  );
};

export default PublicRoute;
