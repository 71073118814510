import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useNewpassword } from "../../../hooks";
import validationSchema from "./ChangePasswordValidation";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import password from "../../../assets/images/icon/view.svg";
const ChangePasswordPage = () => {

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [confirmpasswordShown, setConfirmPasswordShown] = useState(false);
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmpasswordShown ? false : true);
  };

    const { token } = useParams();
    // console.log("token",token)
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
     },
    validationSchema,
    onSubmit: (values) => {
      values.token = token
      doNewpassword(values);
    },
  });
  const { mutate: doNewpassword, isLoading } = useNewpassword((res) => {
    
    toast.success(res.message);
    
    navigate("/");
  });

  // const handleSubmit = (event) => {
  //   // Prevent the default form submission behavior
  //   event.preventDefault();
    
  //   // Redirect to the Change Password page
  //   navigate("/changepassword");
  // };

  return (
    <div className="user-data-page clearfix d-lg-flex justify-content-center align-items-center mt-100">
      <div className="form-wrapper">
        <div className="mt-80 md-mt-40">
          <h2>New Password</h2>
          <p className="header-info pt-30 pb-50"></p>
        </div>
        
        <form onSubmit={formik.handleSubmit} className="user-data-form">
          <div className="row">
            <div className="col-12">
              <div className="input-group-meta mb-80 sm-mb-70">
                <label>Password</label>
                <input
                  placeholder="Enter Your Password"
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className={` ${formik.errors.password ? "is-invalid" : ""}`}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="invalid-feedback">{formik.errors.password}</div>
                ) : null}
                <span
                  className="placeholder_icon"
                  onClick={togglePasswordVisiblity}
                >
                  <span
                    className={
                      passwordShown ? "passVicon eye-slash" : "passVicon"
                    }
                  >
                    <img src={password} alt="ico" />
                  </span>
                </span>
              </div>
            </div>
            <div className="col-12">
              <div className="input-group-meta mb-80 sm-mb-70">
                <label>Confirm Password</label>
                <input
                  placeholder="Enter Your Confirm Password"
                  name="confirmPassword"
                  type={confirmpasswordShown ? "text" : "password"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  className={` ${formik.errors.confirmPassword ? "is-invalid" : ""}`}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                  <div className="invalid-feedback">{formik.errors.confirmPassword}</div>
                ) : null}
                <span
                  className="placeholder_icon"
                  onClick={toggleConfirmPasswordVisiblity}
                >
                  <span
                    className={
                      confirmpasswordShown ? "passVicon eye-slash" : "passVicon"
                    }
                  >
                    <img src={password} alt="ico" />
                  </span>
                </span>
              </div>
            </div>
            <div className="col-12">
              <div className="agreement-checkbox d-flex justify-content-between align-items-center"></div>
            </div>
            <div className="col-12">
              <button type="submit" className="theme-btn-one mt-10 mb-50">
                Submit
              </button>
            </div>
          </div>
        </form>
        {/* Form End */}
        <p className="text-center font-rubik copyright-text">
          © Copyright {new Date().getFullYear()}. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
