import React from "react";
// import img1 from "../../assets/img/Product/Gui_builder/Forum.svg";
import { useGetHomeSvgData } from "../../hooks";

const AboutUsPage = () => {
  let fileNames = ["mission", "aboutus", "ownership"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  // console.log(data, "data");
  return (
    <>
      <div className="fancy-hero-three">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 m-auto">
                <h1 className="heading">About us</h1>
                {data &&
                  data.svgDataArray.map((val, index) => {
                    if (val.fileName === "aboutus") {
                      return (
                        <object key={index} data={val.svgDataURL}></object>
                      );
                    } else {
                      return null; // Or you can choose to return some other element if the condition is not met
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-text-block-nine mt-20 md-mt-80">
        <div className="container">
          <div className="title-style-two text-center mb-35 md-mb-10">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <h2>
                  We are GUI, dedicated to crafting and developing solutions
                </h2>
              </div>
            </div>
          </div>
          {/* End title-style-two */}

          <div className="row">
            <div className="col-xl-10 m-auto">
              <p className="">
                GUIview has been a prominent player in industrial automation
                software for several years. Our reputation in the international
                market has been steadily growing, thanks to our loyal customer
                base and strategic partnerships. Through various agencies and
                collaborations, GUIview extends its services worldwide. We
                remain at the forefront of technology by investing in extensive
                research and development efforts within the field.
              </p>
              <br />
              <p className="">
                Our flagship product, <b>GUI Builder</b>, is renowned for its
                fast performance, affordability, compatibility, and advanced
                technology. Even though we use cutting-edge tech, we ensure our
                software integrates seamlessly with other systems, allowing
                businesses to save money and enhance efficiency.
              </p>
              <br />
              <p className="">
                Additionally, our <b>Cloud View</b> product offers robust cloud-based
                solutions for real-time monitoring and control, expanding the
                capabilities of industrial automation to include remote access
                and IoT integration. This enables businesses to stay connected
                and manage operations from anywhere in the world.
              </p>
              <br />
              <p className="">
              Our team is dedicated to meeting clients' needs, finding effective business solutions, and fostering innovation. We are proud of our team's ability to deliver top-notch solutions, providing accurate, satisfying, and up-to-date services. You can rely on us for comprehensive help, training, and support worldwide.
              </p>
              <br />
              <p className="">
              We offer high-quality technical support to our clients and partners, ensuring they get the most out of our products. Our training programs for new users cover the latest technology, and we guarantee seamless integration with your existing systems.
              </p>
              <br />
              <p className="">
              Choose GUIview for industry-leading industrial automation solutions with GUI Builder and Cloud View, supported by unparalleled customer service and technical expertise.
              </p>
            </div>
          </div>
          {/* End .row */}
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-80 md-pt-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-last">
              <div className="">
                <div className="title-style-two">
                  <h2>Our Mission</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Our team is characterized by a youthful and informal
                  atmosphere, where team members are adaptable and capable of
                  tackling challenges due to the friendly and flexible
                  environment.
                </p>
                <br />
                <p>
                  Our objective is to innovate and pioneer solutions rather than
                  waiting for others' products. We set new benchmarks in SCADA
                  software and achieve them through the ingenuity of our
                  innovative team. Continuously striving for progress, our team
                  sets new goals in the automation industry. All our products
                  seamlessly integrate with GUIVIEW, a manufacturing company
                  renowned for its unique services.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div className="col-lg-6 col-md-8 m-auto order-lg-first">
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "mission") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
              {/* <img src={data.svgDataURL} alt="feature" /> */}
              {/* <object data={data.svgDataURL}>
                
              </object> */}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-80 md-pt-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-first">
              <div className="">
                <div className="title-style-two">
                  <h2>Ownership</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  The GUIVIEW is a product of pi incorporation, a leading
                  provider of industrial automation for many years. It combines
                  technology with real-time projects to deliver the best
                  products. The organization consistently provides solutions
                  that are efficient, safe, cost-effective, and compatible. We
                  are always ready to solve customers' problems and fulfill
                  their dreams by delivering the best products.
                </p>
                <br />
                <p>
                  Our work ensures our team's energy and motivation, supported
                  by effective management, creating an outstanding combination
                  for finding solutions. The trust and satisfaction of our
                  customers provide insight into our organization's performance.
                  We prioritize building strong bonds with our customers,
                  understanding our responsibility.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div className="col-lg-6 col-md-8 m-auto order-lg-last">
              {/* <img src={img1} alt="feature" /> */}
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "ownership") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;
