import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useAddAnswer, useAddPost, useGetPostAnswer } from "../../hooks";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Modal from "react-modal";
import { FILE_PATH } from "../../config";
import validationSchema from "./AddForumDetailsValidation";
import { useFormik } from "formik";
import validationSchema2 from "./AddForumAnswerValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faComment } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { loggedUser } from "../../store/features/authSlice";
import { useSelector } from "react-redux";

const ForumDetail = () => {
  const { id } = useParams();
  const { refetch, data, error, isLoading } = useGetPostAnswer(id);
  const checkLoggenInUser = useSelector(loggedUser);

  const isLoggedIn = checkLoggenInUser.isLoggedIn;

  const { mutate: doAddPost } = useAddPost(
    (response) => {
      toast.success(response.message);
      refetch();
    },
    () => {
      toast.error("An error occurred. Please try again.");
    }
  );

  const { mutate: doAddAnswer } = useAddAnswer((response) => {
    toast.success(response.message);
    refetch();
    setSelectedFileName("");
  });
// console.log("aaaaaaaaaa")
  const formik = useFormik({
    initialValues: {
      label: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      values.topic_id = id;
      doAddPost(values);
      formik.resetForm();
      toggleModalOne();
    },
  });

  const answerformik = useFormik({
    initialValues: {
      answer: "",
      answer_type: null,
    },
    validationSchema: validationSchema2,
    validate: (values) => {
      const errors = {};
      if (!values.answer && !values.answer_type) {
        errors.answer = "You must provide either a description or a file";
        errors.answer_type = "You must provide either a description or a file";
      } else if (values.answer && values.answer_type) {
        errors.answer =
          "You can only provide a description or a file, not both";
        errors.answer_type =
          "You can only provide a description or a file, not both";
      }
      return errors;
    },
    onSubmit: async (values) => {
      values.topic_id = id;
      values.post_id = postId;
      doAddAnswer(values);
      answerformik.resetForm(); // Reset form after submission
      toggleModalTwo(); // Close modal after submission
    },
  });

  const [postId, setPostId] = useState("");
  const newsImageRef = useRef();
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isAnswer, setIsAnswer] = useState(false);

  const toggleModalOne = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalTwo = (id) => {
    setPostId(id);
    setIsAnswer(!isAnswer);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const FaqContent = data.posts.map((post, index) => {
  const answerData = post.post_answer_data || [];
  // console.log(`Post ID: ${post.id}, Answer Data:`, answerData);
  const media = answerData.map((answer) => {
    const fileUrl = `${FILE_PATH}/postanswer/${answer.answer}`;
    return {
      type: answer.answer_type,
      url: fileUrl,
      fileData: answer.answer,
      user: answer.User,
      createdAt: answer.createdAt,
    };
  });
  // console.log(`Post ID: ${post.id}, Media:`, media); 
  const descriptions = answerData.map((answer) => answer.answer);

  return {
    id: post.id,
    title: post.label,
    desc: descriptions.length > 0 ? descriptions.join(", ") : "No answer available",
    media: media,
    expand: `item-${index}`,
  };
});
// console.log('FAQ Content:', FaqContent);
return (
  <div className="main-page-wrapper">
    <div className="faqs-inner-page">
      
      <div className="container">
        <div className="row">
          <div className="col-xl-11 m-auto">
            <div className="all-faqs">
              <div className="faqs-all-qus m-0">
                <div className="article-preview mb-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h3 className="font-rubik">{data.title}</h3>
                      <div className="avatar-info">
                        Written by <span>{data.UsersRef.username}</span> <br />
                        <span>
                          <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                          {new Date(data.createdAt).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                    {isLoggedIn && (
                      <div className="d-flex justify-content-end mt-3">
                        <button className="theme-btn-two" onClick={toggleModalOne}>
                          Post
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="article-details">
                    <div className="accordion-style-four">
                      <div className="faq-wrraper">
                        <Accordion preExpanded={["b"]} allowZeroExpanded>
                          {FaqContent.map((item, i) => (
                            <AccordionItem className="card" key={i} uuid={item.expand}>
                              <AccordionItemHeading className="card-header">
                                <AccordionItemButton>
                                  <h5 className="mb-0">
                                    <button className="btn btn-link">{item.title}</button>
                                  </h5>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel className="card-body fadeInUp">
                                <ul className="list-meta">
                                  {item.media.length > 0 ? (
                                    item.media.map((mediaItem, index) => (
                                      <li key={index} style={{ border: "2px solid #d3cccc", padding: "10px", paddingLeft: "21px" }}>
                                        <div>
                                          {mediaItem.type === true ? (
                                            <p>{mediaItem.fileData}</p> // Display text
                                          ) : mediaItem.type === 2 ? (
                                            <img
                                              src={mediaItem.url}
                                              alt={`User Posted Image ${index + 1}`}
                                              style={{ width: "400px", height: "auto" }}
                                            />
                                          ) : mediaItem.type === 3 ? (
                                            <video controls style={{ maxWidth: "50%", height: "auto" }}>
                                              <source src={mediaItem.url} type="video/mp4" />
                                              Your browser does not support the video tag.
                                            </video>
                                          ) : null}
                                          {mediaItem.user && (
                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                              <div>
                                                <span>
                                                  <FontAwesomeIcon icon={faEye} /> 123
                                                </span>
                                                <span className="ml-3">
                                                  <FontAwesomeIcon icon={faComment} /> 10
                                                </span>
                                              </div>
                                              <div className="avatar-info">
                                                Written by <span>{mediaItem.user.username}</span> <br />
                                                <span>
                                                  <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                                                  {new Date(mediaItem.createdAt).toLocaleDateString()}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </li>
                                    ))
                                  ) : (
                                    <li>No answer available</li>
                                  )}
                                </ul>
                                <div className="d-flex justify-content-end mt-3">
                                  {isLoggedIn && (
                                    <button className="theme-btn-two" onClick={() => toggleModalTwo(item.id)}>
                                      Answer
                                    </button>
                                  )}
                                </div>
                              </AccordionItemPanel>
                            </AccordionItem>
                          ))}
                          {
                            FaqContent.length == 0 &&  <div>No data found</div>
                          }
                        </Accordion>
                      </div>
                    </div>
                  </div>

                  <Modal
                    isOpen={isOpen}
                    onRequestClose={toggleModalOne}
                    contentLabel="Ask a Question"
                    className="custom-modal modal-contact-popup-one"
                    overlayClassName="custom-overlay"
                    closeTimeoutMS={500}
                  >
                    <div className="box_inner">
                      <main className="main-body box_inner modal-content">
                        <button className="close" onClick={toggleModalOne}>
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                        <div className="right-side">
                          <h2 className="form-title">Ask a Question</h2>
                          <form id="contact-form" onSubmit={formik.handleSubmit}>
                            <div className="row controls">
                              <div className="col-md-12">
                                <div className="input-group-meta form-group mb-20">
                                  <label>Description</label>
                                  <textarea
                                    placeholder="Enter Your Question"
                                    name="label"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.label}
                                    className={`form-control ${formik.touched.label && formik.errors.label ? "is-invalid" : ""}`}
                                  />
                                  {formik.touched.label && formik.errors.label && (
                                    <div className="invalid-feedback">{formik.errors.label}</div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="d-flex justify-content-center mt-3">
                                    <button type="submit" className="theme-btn-two" disabled={!formik.isValid}>
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </main>
                    </div>
                  </Modal>

                  <Modal
                    isOpen={isAnswer}
                    onRequestClose={toggleModalTwo}
                    contentLabel="Answer a Question"
                    className="custom-modal modal-contact-popup-one"
                    overlayClassName="custom-overlay"
                    closeTimeoutMS={500}
                  >
                    <div className="box_inner">
                      <main className="main-body box_inner modal-content clearfix">
                        <button className="close" onClick={toggleModalTwo}>
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                        <div className="right-side">
                          <h2 className="form-title">Answer</h2>
                          <form id="contact-form" onSubmit={answerformik.handleSubmit}>
                            <div className="row controls">
                              <div className="col-md-12">
                                <div className="input-group-meta form-group mb-20">
                                  <label>Description</label>
                                  <textarea
                                    placeholder="Enter Your Answer"
                                    name="answer"
                                    onChange={answerformik.handleChange}
                                    onBlur={answerformik.handleBlur}
                                    value={answerformik.values.answer}
                                    className={`form-control ${answerformik.touched.answer && answerformik.errors.answer ? "is-invalid" : ""}`}
                                  />
                                  {answerformik.touched.answer && answerformik.errors.answer && (
                                    <div className="invalid-feedback">{answerformik.errors.answer}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 text-center">
                                <p>or</p>
                              </div>
                            </div>
                            <div className="row controls">
                              <div className="col-md-12">
                                <div className="input-group-meta form-group mb-50">
                                  <label>Upload File:</label>
                                  <div className="d-flex align-items-center">
                                    <input
                                      className="d-none"
                                      type="file"
                                      accept=".txt, .jpeg .png, .jpg, .mp4, .avi"
                                      ref={newsImageRef}
                                      name="answer_type"
                                      onChange={(event) => {
                                        const file = event.currentTarget.files[0];
                                        if (file) {
                                          answerformik.setFieldValue("answer_type", file);
                                          setSelectedFileName(file.name);
                                        }
                                      }}
                                      onBlur={answerformik.handleBlur}
                                    />
                                    <button type="button" onClick={() => newsImageRef.current.click()} className="btn btn-outline-primary theme-btn-two mt-10">
                                      Choose File
                                    </button>
                                  </div>
                                  {selectedFileName && <div className="selected-file">Selected File: {selectedFileName}</div>}
                                  {answerformik.touched.answer_type && answerformik.errors.answer_type && (
                                    <div className="invalid-feedback">{answerformik.errors.answer_type}</div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="d-flex justify-content-center mt-3">
                                    <button type="submit" className="theme-btn-two" disabled={!answerformik.isValid}>
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </main>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default ForumDetail;
