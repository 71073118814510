import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useGetGeneralsettingData } from "../../../hooks";

import PropTypes from "prop-types";

const Testimonial = ({ testimonials_data }) => {
  const { isLoading: settingLoading, data: setting_data } =
    useGetGeneralsettingData();
  let title = "";

  if (!settingLoading && setting_data && setting_data.data) {
    const { list_generalsetting } = setting_data.data;
    const titleSetting = list_generalsetting.find(
      (setting) =>
        setting.text_key === "home_page_general_setting_testimonial_title"
    );

    title = titleSetting ? titleSetting.text_value : "";
  }
  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const settings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  // const TestimonilContent = [
  //   {
  //     desc: `The GUI.Builder software's UI is simply outstanding! It's user-friendly, intuitive, and visually appealing.`,
  //     reviewerName: "Nitin",
  //     designation: "krishna Eng",
  //   },
  //   {
  //     desc: `GUI.Builder's communication interface is remarkable! It seamlessly connects our diverse systems.`,
  //     reviewerName: "Vijay",
  //     designation: "Embicon industries pvt ltd",
  //   },
  //   {
  //     desc: `Cloud.view gateway is an all-in-one solution that has exceeded our expectations.`,
  //     reviewerName: "Mahesh",
  //     designation: "Om Automation",
  //   },
  //   {
  //     desc: `Cloud.view's device driver availability is exceptional, ensuring seamless integration with our industrial equipment.`,
  //     reviewerName: "Jay",
  //     designation: "Asiatic Gypsum llp",
  //   },
  // ];
  return (
    <div
      className="client-feedback-slider-six pt-80 md-pt-10"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="container">
        <div className="title-style-five text-center mb-10 md-mb-10">
          <div className="row">
            <div className="col-lg-7 col-md-9 m-auto">
              <h2>
                <span>{title}</span>
              </h2>
            </div>
          </div>
        </div>
        {/* End title */}
        <div className="client-feedback-slider-six pt-0 md-pt-50" id="feedback">
          <div className="inner-container">
            <div className="clientSliderSix">
              <Slider {...settings}>
                {/* {testimonials_data.map((item, i) => (
                 <div
                   className="item"
                   key={i}
                   data-aos="fade-up"
                   data-aos-duration="1200"
                   data-aos-delay="100"
                 >
                   <p>{stripHtml(item.description)}</p>
                   <h6 className="name">{item.name}</h6>
                   <span className="desig">{item.company_name}</span>
                 </div>
               ))} */}
                {testimonials_data.map((item, i) => (
                  <div className="item" key={i}>
                    <div className="feedback-wrapper">
                      <p>{stripHtml(item.description)}</p>
                      <div className="d-flex align-items-center">
                        <h6 className="name">{item.name},</h6>
                        <span className="mx-1"></span>{" "}
                        {/* Using Bootstrap utility class for spacing */}
                        <span className="desig">{item.company_name}</span>
                      </div>
                    </div>
                    {/* End .feedback-wrapper */}
                  </div>
                ))}
              </Slider>

              {/* /.testimonial-slider-wrapper */}
            </div>
          </div>
        </div>
      </div>
      {/* /.container */}
    </div>
  );
};
Testimonial.propTypes = {
  testimonials_data: PropTypes.object,
};

export { Testimonial };
