/* eslint-disable jsx-a11y/alt-text */
import React from "react";
// import img1 from "../../assets/img/Product/Gui_builder/Forum.svg";
import { useGetHomeSvgData } from "../../hooks";

const AlarmingPage = () => {

  let fileNames = ["alarming"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <div>
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Alarming</h2><br/>
                </div>
                {/* /.title-style-two */}
                <p>
                GUIview’s alarm management and notification solutions, powered by GUI Builder and Cloud View, deliver unmatched reliability and responsiveness. These advanced tools enable users to seamlessly monitor, manage, and respond to critical system conditions, ensuring operational safety and efficiency.
                </p><br/>
                <p>
                <b>GUI Builder</b> offers robust alarm monitoring, continuously overseeing system conditions and triggering immediate notifications for any deviations. Users can customize alarm thresholds to ensure alerts are relevant and actionable, reducing alarm fatigue and enabling prompt responses. Various alert methods, including email notifications, keep key personnel informed.
                </p><br/>
                <p>
                <b>Cloud View</b> enhances this by providing real-time access to alarm information from anywhere. This cloud-based solution allows users to receive, manage, and respond to alerts remotely, ensuring continuous oversight and swift action even when off-site.</p><br/>
                <p>Together, GUI Builder and Cloud View offer a powerful combination for comprehensive alarm management, providing detailed alarm reports for root cause analysis and proactive maintenance. Trust GUIview to keep your operations secure, responsive, and efficient.</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-4 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
              data.svgDataArray.map((val, index) => {
                if (val.fileName === "alarming") {
                  return <img key={index} src={val.svgDataURL}></img>;
                } else {
                  return null; // Or you can choose to return some other element if the condition is not met
                }
              })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlarmingPage;
