import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import img from "../../../assets/images/assets/ils_08.svg";
import img2 from "../../../assets/images/assets/ils_08.1.svg";
import img3 from "../../../assets/images/assets/ils_08.2.svg";
import { useFormik } from "formik";
import validationSchema from "./VerificationValidation";
import { toast } from "react-toastify";
import { useVerification } from "../../../hooks";
const VerificationPage = () => {
  const navigate = useNavigate();

  /**
   * This Block will execute on Form Submit, provides form fields and validations for that
   */
  const formik = useFormik({
    initialValues: {
      confirm_code: "",
    },
    validationSchema,
    onSubmit: (values) => {
      doVerification(values);
    },
  });
  /**
   * This Block is execute on Form Submit when we'll get success response, then we are storing data in Redux using React Toolkit.
   */
  const { mutate: doVerification, isLoading } = useVerification((res) => {
    toast.success(res.message);
    navigate("/login");
  });
  return (
    <div className="user-data-page clearfix d-lg-flex justify-content-center align-items-center mt-100">
      {/* <div className="illustration-wrapper d-flex align-items-center justify-content-between flex-column">
        <div className="illustration-holder">
          <img src={img} alt="illustration" className="illustration" />
          <img src={img2} alt="illustration" className="shapes shape-one" />
          <img src={img3} alt="illustration" className="shapes shape-two" />
        </div>
  </div> */}
      {/* /.illustration-wrapper */}

      <div className="form-wrapper">
        <div className="d-flex justify-content-between">
          <Link className="font-rubik go-back-button" to="/">
            Go to home
          </Link>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="user-data-form mt-80 md-mt-40"
        >
          <div className="row">
            <div className="col-12">
              <div className="input-group-meta mb-80 sm-mb-70">
                <label>Confirmation Code</label>
                <input
                  placeholder="Enter Your Confirmation Code"
                  name="confirm_code"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirm_code}
                  className={` ${
                    formik.errors.confirm_code ? "is-invalid" : ""
                  }`}
                />
                {formik.touched.confirm_code && formik.errors.confirm_code ? (
                  <div className="invalid-feedback">
                    {formik.errors.confirm_code}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="agreement-checkbox d-flex justify-content-between align-items-center"></div>
              {/*  /.agreement-checkbox */}
            </div>
            <div className="col-12">
              <button className="theme-btn-one mt-50 mb-50">Verify</button>
            </div>
          </div>
        </form>
        {/* Login Form End */}
        <p className="text-center font-rubik copyright-text">
          © Copyright {new Date().getFullYear()}. All Rights Reserved.
        </p>
      </div>
      {/* /.form-wrapper */}
    </div>
  );
};

export default VerificationPage;
