import * as Yup from 'yup';

const validationSchema2 = Yup.object().shape({
  answer: Yup.string().when('answer_type', {
    is: (answer_type) => !answer_type,
    then: Yup.string().required('Description is required if no file is uploaded'),
    otherwise: Yup.string().notRequired(),
  }),
  answer_type: Yup.mixed().test(
    'fileRequired',
    'File is required if no description is provided',
    function (value) {
      const { answer } = this.parent;
      if (!answer && !value) {
        return false;
      }
      return true;
    }
  ),
});

export default validationSchema2;
