/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { useGetHomeSvgData } from "../../hooks";

const OpcUaPage = () => {

  let fileNames = ["opcua"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <div>
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container" data-aos="fade-left"
              data-aos-duration="1200">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              
            >
              <div className="">
                <div className="title-style-two">
                  <h2> OpcUa connectivity</h2><br/>
                </div>
                {/* /.title-style-two */}
                <p>
                GUIview’s OPC UA connectivity solutions, powered by GUI Builder and Cloud View, provide seamless and secure data exchange across diverse industrial devices and systems. <b>GUI Builder</b> offers robust support for OPC UA protocols, ensuring reliable and efficient communication with a wide range of equipment and applications. Users can easily configure and manage OPC UA connections to streamline data integration and enhance system interoperability.</p><br/>
                <p><b>Cloud View</b> extends these capabilities by enabling remote access to OPC UA data. This cloud-based solution allows users to monitor and control OPC UA-enabled devices from anywhere, providing real-time insights and operational flexibility.</p><br/>
                <p>Together, GUI Builder and Cloud View deliver a powerful OPC UA connectivity solution, offering secure, scalable, and remote access to critical industrial data. Trust GUIview to enhance your data communication and operational efficiency with advanced OPC UA features</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              
            >
               {data &&
              data.svgDataArray.map((val, index) => {
                if (val.fileName === "opcua") {
                  return <img key={index} src={val.svgDataURL}></img>;
                } else {
                  return null; // Or you can choose to return some other element if the condition is not met
                }
              })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="title-style-two text-center mb-35 md-mb-10">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <h2>What are the OPC Servers?</h2>
            </div>
          </div>
        </div>
        {/* End title-style-two */}

        <div className="row">
          <div className="col-xl-10 m-auto">
            <li
              
            >
              Configuration and Management: Users can configure communication protocol parameters and manage the address space for OPC UA client accessibility.
            </li>
            <li
              
            >
             GUIbuilder Technology: The system incorporates GUIbuilder technology, enabling communication within software components and providing an alternative to traditional OPC servers, thereby reducing restrictiveness.
            </li>
            <li
              
            >
              Features in Communication Technology: GUIview offers various features in communication technology, covering PLC, RTU, PAC, CNC, Fieldbus, Instrumentation, and other industrial devices for buildings or infrastructures. Users can experience both OPC UA and OPC DA standard technologies.
            </li>
            <li
              
            >
              OPC Server: The OPC server is crucial for managing communication protocols. IGUI builder provides software solutions for industrial communication, reducing restrictiveness associated with traditional OPC UA Servers, and offering performance and reliable communication for management, monitoring, and control.
            </li>
            <li
              
            >
              Countless Communication Protocols: GUIview offers countless communication protocols, overcoming the limitations of traditional OPC UA servers and providing flexibility of use.
            </li>
            
          </div>
        </div>
        {/* End .row */}
      </div>
    </div>
  );
};

export default OpcUaPage;
