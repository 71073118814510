import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Airport from "../../../assets/img/case_study/aeroplanemo_m2.svg";
import Automation from "../../../assets/img/case_study/automationbremove.svg";
import Builder from "../../../assets/img/case_study/builder.svg";
import Datacenter from "../../../assets/img/case_study/datacenter.svg";
import Foodbeverage from "../../../assets/img/case_study/foodbeverage.svg";
import Oilandgas from "../../../assets/img/case_study/oilgas_m.svg";
import Machinetoolsindustry from "../../../assets/img/case_study/machinetoolsindustry.jpg";
import Pharma from "../../../assets/img/case_study/pharma.svg";
import Powerandenergy from "../../../assets/img/case_study/powerandenergybremove.svg";
import Smartcity from "../../../assets/img/case_study/smartcitybremove.svg";
import Waterwaste from "../../../assets/img/case_study/waterwaste_m.svg";

const CaseStudy = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: "0",
    slidesToShow: slidesToShow,
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(3);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const PortfolioContent = [
    {
      img: Airport,
      meta: "Case Study",
      title: "Airport",
      content:
        "GUI view can handle data connection, graphical tools, system monitoring, and alarm analysis.",
    },
    {
      img: Automation,
      meta: "Case Study",
      title: "Automotive",
      content:
        "GUI's view has been working for a long time in the automotive sector. We have found solutions that minimize downtime, reduce operating costs, and innovative solutions.",
    },
    {
      img: Builder,
      meta: "Case Study",
      title: "Building Automation",
      content:
        "GUI's view has been working for a long time in the automotive sector.",
    },
    {
      img: Datacenter,
      meta: "Case Study",
      title: "Data Center",
      content:
        "As we all know the people are generating tons of data and it requires storage for storing data.",
    },
    {
      img: Foodbeverage,
      meta: "Case Study",
      title: "Food Beverage",
      content:
        "The food and Beverage industry always looking for quality products that are the basic requirements for superior monitoring, control, reporting, trending, and alarming.",
    },
    {
      img: Oilandgas,
      meta: "Case Study",
      title: "Oil Gas",
      content:
        "There are many assets available in Oil &gas production, but some of them disappeared across large geographies.",
    },
    {
      img: Machinetoolsindustry,
      meta: "Case Study",
      title: "Machine Tools Industry",
      content:
        "The machine tools industry can achieve remarkable improvements in efficiency and precision with GUIview’s cutting-edge solutions, powered by GUI Builder and Cloud View.",
    },
    {
      img: Pharma,
      meta: "Case Study",
      title: "Pharmaceutical",
      content:
        "As people are becoming more aware of health and they know the importance of medicine.",
    },
    {
      img: Powerandenergy,
      meta: "Case Study",
      title: "Energy",
      content:
        "The highest cost is the main concern of the Utilities and Energy Industry. ",
    },
    {
      img: Smartcity,
      meta: "Case Study",
      title: "Smart Cities",
      content:
        "Smart cities are the trending concept all over the world, but authority always stays in stress because of energy consumption and emissions, Therefore, improve their overall resource efficiency, and foster a more sustainable environment are must for finding a solution. ",
    },
    {
      img: Waterwaste,
      meta: "Case Study",
      title: "Water WasteWater",
      content:
        "Nowadays pollution is increasing at an alarming rate that is why the availability of adequate water supplies and effective waste processing is necessary.",
    },
  ];

  return (
    <div className="portfolio_slider_one" data-aos="fade-right" data-aos-delay={100}>
      <div className="m-auto">
        <div className="title-style-five text-center mb-30 md-mb-30">
          <h2>
            <span>Case Study</span>
          </h2>
        </div>
      </div>
      <div className="container">
        <Slider {...settings}>
          {PortfolioContent.map((item, i) => (
            <div className="item" key={i}>
              <div className="portfolio-block-two position-relative">
                <div className="title-style-one text-center mb-20 md-mb-20">
                  <h6>{item.title}</h6>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={item.img}
                    alt=""
                    className="w-100 tran5s"
                  />
                </div>
                <div className="hover-content">
                  <h3>
                    <Link style={{ textDecoration: "none" }} to={`/${item.title.toLowerCase().replace(/\s+/g, '-')}`}>
                      {item.content}
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export { CaseStudy };
