/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { useGetHomeSvgData } from "../../hooks";

const OilAndGasPage = () => {
  let fileNames = ["oilngasa","oilgas"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <>
      <div className="fancy-hero-three">
        
        <div className="">
          <div className="container">
            <div className="row">
              {/* <div className="col-xl-9 col-lg-10 m-auto">
                <h1 className="heading">Oil & Gas</h1>
              </div> */}
               {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "oilngasa") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-text-block-thirteen pt-40 md-pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Oil And Gas</h2><br/>
                </div>
                {/* /.title-style-two */}
                <p>
                The oil and gas industry can optimize operations and enhance safety with GUIview’s advanced solutions, powered by GUI Builder and Cloud View. <b>GUI Builder</b> provides robust tools for real-time monitoring and control of drilling rigs, pipelines, and refineries. Its intuitive interface ensures seamless integration with various systems, allowing for efficient management and visualization of critical parameters.
                </p><br/>
                <p>
                <b>Cloud View</b> extends these capabilities by offering remote access to vital data and control functions. Operators and engineers can monitor systems, receive alerts, and make informed decisions from anywhere, improving responsiveness and operational efficiency.
                </p><br/>
                <p>
                Together, GUI Builder and Cloud View offer a powerful platform for the oil and gas industry, delivering real-time data access, remote monitoring, and enhanced control. Trust GUIview to drive safety, efficiency, and innovation in your oil and gas operations with cutting-edge technology.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "oilgas") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
    </>
  );
};

export default OilAndGasPage;
