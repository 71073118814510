import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import img from "../../../assets/images/assets/ils_08.svg";
import img2 from "../../../assets/images/assets/ils_08.1.svg";
import img3 from "../../../assets/images/assets/ils_08.2.svg";
import pass from "../../../assets/images/icon/view.svg";
import { useFormik } from "formik";
import { useRegister } from "../../../hooks";
import { toast } from "react-toastify";
import validationSchema from "./SignUpPageValidation";

const SignUpPage = () => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  /**
   * This Block is execute on Form Submit when we'll get success response, then we are storing data in Redux using React Toolkit.
   */
  const { mutate: doRegister, isLoading } = useRegister((res) => {
    toast.success(res.message);
    navigate("/verification");
  });

  /**
   * This Block will execute on Form Submit, provides form fields and validations for that
   */
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      company: "",
      businessrole: "",
      vatnumber: "",
      street: "",
      city: "",
      postalcode: "",
      phonenumber: "",
      username: "",
    },
    validationSchema,
    onSubmit: (values) => {
      doRegister(values);
    },
  });

  return (
    <div className="user-data-page clearfix d-lg-flex justify-content-center align-items-center mt-100">
      {/* <div className="illustration-wrapper d-flex align-items-center justify-content-between flex-column">
    <div className="illustration-holder">
      <img src={img} alt="illustration" className="illustration" />
      <img src={img2} alt="illustration" className="shapes shape-one" />
      <img src={img3} alt="illustration" className="shapes shape-two" />
    </div>
  </div> */}
      {/* /.illustration-wrapper  */}

      <div className="form-wrapper">
        <div className="d-flex justify-content-between">
          <Link className="font-rubik go-back-button" to="/">
            Go to home
          </Link>
        </div>
        {/* End d-flex */}
        <div className="mt-30">
          <p className="header-info pb-50">
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </div>
        <form onSubmit={formik.handleSubmit} class="user-data-form ">
          <div className="row">
            <div className="col-6">
              <div className="input-group-meta mb-50 sm-mb-50">
                <label>Name</label>
                <input
                  placeholder="Enter Your Name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className={` ${formik.errors.name ? "is-invalid" : ""}`}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="invalid-feedback">{formik.errors.name}</div>
                ) : null}
              </div>
            </div>
            <div className="col-6">
              <div className="input-group-meta mb-50 sm-mb-50">
                <label>Company</label>
                <input
                  placeholder="Enter Your Company"
                  name="company"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company}
                  className={` ${formik.errors.company ? "is-invalid" : ""}`}
                />
                {formik.touched.company && formik.errors.company ? (
                  <div className="invalid-feedback">
                    {formik.errors.company}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-6">
              <div className="input-group-meta mb-50 sm-mb-50">
                <label>Business Role</label>
                <input
                  placeholder="Enter Your Business Role"
                  name="businessrole"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.businessrole}
                  className={` ${
                    formik.errors.businessrole ? "is-invalid" : ""
                  }`}
                />
                {formik.touched.businessrole && formik.errors.businessrole ? (
                  <div className="invalid-feedback">
                    {formik.errors.businessrole}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-6">
              <div className="input-group-meta mb-50 sm-mb-50">
                <label>VAT Number</label>
                <input
                  placeholder="Enter Your VAT Number"
                  name="vatnumber"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.vatnumber}
                  className={` ${formik.errors.vatnumber ? "is-invalid" : ""}`}
                  onKeyPress={(e) => {
                    if (!/^[0-9]+$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {formik.touched.vatnumber && formik.errors.vatnumber ? (
                  <div className="invalid-feedback">
                    {formik.errors.vatnumber}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="input-group-meta mb-50 sm-mb-50">
                <label>Street / Square / Street Number</label>
                <input
                  placeholder="Enter Your Street"
                  name="street"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.street}
                  className={` ${formik.errors.street ? "is-invalid" : ""}`}
                />
                {formik.touched.street && formik.errors.street ? (
                  <div className="invalid-feedback">{formik.errors.street}</div>
                ) : null}
              </div>
            </div>
            <div className="col-6">
              <div className="input-group-meta mb-50 sm-mb-50">
                <label>City / Country</label>
                <input
                  placeholder="Enter Your City / Country"
                  name="city"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  className={` ${formik.errors.city ? "is-invalid" : ""}`}
                />
                {formik.touched.city && formik.errors.city ? (
                  <div className="invalid-feedback">{formik.errors.city}</div>
                ) : null}
              </div>
            </div>
            <div className="col-6">
              <div className="input-group-meta mb-50 sm-mb-50">
                <label>Postal Code / Zip Code</label>
                <input
                  placeholder="Enter Your Postal Code "
                  name="postalcode"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postalcode}
                  className={` ${formik.errors.postalcode ? "is-invalid" : ""}`}
                  onKeyPress={(e) => {
                    if (!/^[0-9]+$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {formik.touched.postalcode && formik.errors.postalcode ? (
                  <div className="invalid-feedback">
                    {formik.errors.postalcode}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-6">
              <div className="input-group-meta mb-50 sm-mb-50">
                <label>Telephone</label>
                <input
                  placeholder="Enter Your Telephone"
                  name="phonenumber"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phonenumber}
                  className={` ${
                    formik.errors.phonenumber ? "is-invalid" : ""
                  }`}
                  onKeyPress={(e) => {
                    if (!/^[0-9]+$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {formik.touched.phonenumber && formik.errors.phonenumber ? (
                  <div className="invalid-feedback">
                    {formik.errors.phonenumber}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-6">
              <div className="input-group-meta mb-50 sm-mb-50">
                <label>Comapany Email</label>
                <input
                  placeholder="Enter Your Comapany Email"
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={` ${formik.errors.email ? "is-invalid" : ""}`}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>
            <div className="col-6">
              <div className="input-group-meta mb-50">
                <label>Password</label>
                <input
                  placeholder="Enter Password"
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className={` ${formik.errors.password ? "is-invalid" : ""}`}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="invalid-feedback">
                    {formik.errors.password}
                  </div>
                ) : null}
                <span
                  className="placeholder_icon"
                  onClick={togglePasswordVisiblity}
                >
                  <span
                    className={
                      passwordShown ? "passVicon eye-slash" : "passVicon"
                    }
                  >
                    <img src={pass} alt="" />
                  </span>
                </span>
                {/* End placeholder icon */}
              </div>
            </div>
            <div className="col-6">
              <div className="input-group-meta mb-50 sm-mb-50">
                <label>Username</label>
                <input
                  placeholder="Enter Your Username"
                  name="username"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  className={` ${formik.errors.username ? "is-invalid" : ""}`}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className="invalid-feedback">
                    {formik.errors.username}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="agreement-checkbox d-flex justify-content-between align-items-center">
                <div>
                  <input type="checkbox" id="remember" />
                  <label htmlFor="remember">
                    Agree to terms and conditions
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12">
              <button type="submit" className="theme-btn-one mt-50 mb-50">
                Sign Up
              </button>
            </div>
          </div>
        </form>

        {/* End Signup Form */}
        <p className="text-center font-rubik copyright-text">
          © Copyright 2023. All Rights Reserved.
        </p>
        {/* End .copyright */}
      </div>
      {/* /.form-wrapper */}
    </div>
  );
};

export default SignUpPage;
