import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import validationSchema from "./EditProfileValidationPage";
import { useUpdateEditProfile, useViewEditProfile } from "../../../hooks";
import { toast } from "react-toastify";
import { defaultValue } from "../../../helpers";

const EditProfilePage = () => {
  const { id } = useParams();

  const { mutate: doEditProfile, isLoading } = useUpdateEditProfile((res) => {
    toast.success(res.message);
  });

  const { refetch: doGetProfile } = useViewEditProfile(
    id,
    ({ data: editprofile }) => {
      if (editprofile) {
        // console.log(editprofile, "editprofile");
        formik.values.name = editprofile.name;
        formik.values.email = editprofile.email;
        formik.values.password = "";
        formik.values.company = editprofile.company;
        formik.values.businessrole = editprofile.businessrole;
        formik.values.vatnumber = editprofile.vatnumber;
        formik.values.street = editprofile.street;
        formik.values.city = editprofile.city;
        formik.values.postalcode = editprofile.postalcode;
        formik.values.phonenumber = editprofile.phonenumber;
        formik.values.username = editprofile.username;
      }
    }
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      company: "",
      businessrole: "",
      vatnumber: "",
      street: "",
      city: "",
      postalcode: "",
      phonenumber: "",
      username: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        values.id = id;
        await doEditProfile(values);
        resetForm();
        setTimeout(() => {
          doGetProfile();
          window.scrollTo(0, 0);
        }, 1000);
      } catch (error) {
        console.error("Submission error:", error);
      }
    },
  });

  return (
    <div>
      <div className="fancy-hero-three">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 m-auto">
                <h1 className="heading">Edit Profile</h1>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="container mt-5"></div>

      <div className="form-style-light mb-50">
        <form id="contact-form" onSubmit={formik.handleSubmit}>
          <div className="messages"></div>
          <div className="row controls">
            <div className="col-md-6">
              <div className="input-group-meta form-group mb-60">
                <label> Name</label>
                <input
                  type="text"
                  placeholder="Enter Your Name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className={` ${formik.errors.name ? "is-invalid" : ""}`}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="invalid-feedback">{formik.errors.name}</div>
                ) : null}
              </div>
            </div>
            {/* End .col */}

            <div className="col-6">
              <div className="input-group-meta form-group mb-60">
                <label>Email</label>
                <input
                  placeholder="Enter Your Email"
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={` ${formik.errors.email ? "is-invalid" : ""}`}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>

            <div className="col-6">
              <div className="input-group-meta form-group mb-60">
                <label>Password</label>
                <input
                  placeholder="Enter Your Password"
                  name="password"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className={` ${formik.errors.password ? "is-invalid" : ""}`}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="invalid-feedback">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-6">
              <div className="input-group-meta form-group mb-60">
                <label>Company</label>
                <input
                  placeholder="Enter Your Company"
                  name="company"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company}
                  className={` ${formik.errors.company ? "is-invalid" : ""}`}
                />
                {formik.touched.company && formik.errors.company ? (
                  <div className="invalid-feedback">
                    {formik.errors.company}
                  </div>
                ) : null}
              </div>
            </div>
            {/* End .col */}

            <div className="col-6">
              <div className="input-group-meta form-group mb-60">
                <label>Businessrole</label>
                <input
                  placeholder="Enter Your Businessrole"
                  name="businessrole"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.businessrole}
                  className={` ${
                    formik.errors.businessrole ? "is-invalid" : ""
                  }`}
                />
                {formik.touched.businessrole && formik.errors.businessrole ? (
                  <div className="invalid-feedback">
                    {formik.errors.businessrole}
                  </div>
                ) : null}
              </div>
            </div>
            {/* End .col */}

            <div className="col-6">
              <div className="input-group-meta form-group mb-60">
                <label>vatnumber</label>
                <input
                  placeholder="Enter Your vatnumber"
                  name="vatnumber"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.vatnumber}
                  className={` ${formik.errors.vatnumber ? "is-invalid" : ""}`}
                />
                {formik.touched.vatnumber && formik.errors.vatnumber ? (
                  <div className="invalid-feedback">
                    {formik.errors.vatnumber}
                  </div>
                ) : null}
              </div>
            </div>
            {/* End .col */}

            <div className="col-12">
              <div className="input-group-meta form-group mb-60">
                <label>Street / Square / Street number</label>
                <input
                  placeholder="Enter Your street"
                  name="street"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.street}
                  className={` ${formik.errors.street ? "is-invalid" : ""}`}
                />
                {formik.touched.street && formik.errors.street ? (
                  <div className="invalid-feedback">{formik.errors.street}</div>
                ) : null}
              </div>
            </div>
            {/* End .col */}

            <div className="col-6">
              <div className="input-group-meta form-group  mb-40">
                <label>city / Country</label>
                <input
                  placeholder="Enter Your city"
                  name="city"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  className={` ${formik.errors.city ? "is-invalid" : ""}`}
                />
                {formik.touched.city && formik.errors.city ? (
                  <div className="invalid-feedback">{formik.errors.city}</div>
                ) : null}
              </div>
            </div>
            {/* End .col */}

            <div className="col-6">
              <div className="input-group-meta form-group mb-60">
                <label>Postal Code / Zip Code</label>
                <input
                  placeholder="Enter Your postalcode"
                  name="postalcode"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postalcode}
                  className={` ${formik.errors.postalcode ? "is-invalid" : ""}`}
                />
                {formik.touched.postalcode && formik.errors.postalcode ? (
                  <div className="invalid-feedback">
                    {formik.errors.postalcode}
                  </div>
                ) : null}
              </div>
            </div>
            {/* End .col */}

            <div className="col-6">
              <div className="input-group-meta form-group mb-60">
                <label>Telephone</label>
                <input
                  placeholder="Enter Your phonenumber"
                  name="phonenumber"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phonenumber}
                  className={` ${
                    formik.errors.phonenumber ? "is-invalid" : ""
                  }`}
                  onKeyPress={(e) => {
                    if (!/^[0-9]+$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {formik.touched.phonenumber && formik.errors.phonenumber ? (
                  <div className="invalid-feedback">
                    {formik.errors.phonenumber}
                  </div>
                ) : null}
              </div>
            </div>
            {/* End .col */}

            {/* End .col */}

            <div className="col-6">
              <div className="input-group-meta form-group mb-60">
                <label>username</label>
                <input
                  placeholder="Enter username"
                  name="username"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  className={` ${formik.errors.username ? "is-invalid" : ""}`}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className="invalid-feedback">
                    {formik.errors.username}
                  </div>
                ) : null}
              </div>
            </div>
            {/* End .col */}

            <div className="col-12">
              <button type="submit" className="theme-btn-two">
                Submit
              </button>
            </div>
            {/* End .col */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfilePage;
