import React, { useEffect } from 'react';

const BitrixForm = () => {
  useEffect(() => {
    // Check if the script is already loaded
    if (!document.getElementById('bitrix24-script')) {
      const script = document.createElement('script');
      script.id = 'bitrix24-script';
      script.async = true;
      script.src = 'https://cdn.bitrix24.in/b30119693/crm/site_button/loader_2_k5g14t.js?' + (Date.now() / 60000 | 0);
      document.getElementsByTagName('script')[0].parentNode.insertBefore(script, document.getElementsByTagName('script')[0]);
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  return null; // or you can return some JSX if needed
};

export default BitrixForm;