import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { loggedUser } from "../../../store/features/authSlice";

import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { AfterSignuoNavbarHeader } from "../Header/AfterSignupNavbar";
import { ScrollToTop } from "../../components";

const PublicLayout = (props) => {
  const checkLoggenInUser = useSelector(loggedUser);

  const isLoggedIn = checkLoggenInUser.isLoggedIn;

  // Adding class in body
  return (
    <div {...props} className="main-page-wrapper p0">
      {isLoggedIn === true ? <AfterSignuoNavbarHeader /> : <Header />}
      <section>{props.children}</section>
      <ScrollToTop />
      <Footer />
    </div>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export { PublicLayout };
