import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Link } from "react-router-dom";
import shape from "../../../assets/images/shape/120.svg";
import shape1 from "../../../assets/images/shape/121.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import { useGetBrochureData } from "../../../hooks";
import img1 from "../../../assets/img/Product/Gui_builder/Forum.svg";
import { FILE_PATH } from "../../../config";

const BrochurePage = () => {
  let brochure = [];
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: brochure_data } = useGetBrochureData();
  if (!isLoading) {
    brochure = brochure_data.data;
  }
  const removeHTMLTags = (input) => {
    return input.replace(/<[^>]*>?/gm, ""); // This regular expression matches and removes HTML tags
  };
  return (
    <>
      <div className="fancy-hero-three bg-transparent">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">Brochure</h1>
              </div>
              <div className="faq-section-four">
                <div className="title-style-five text-center mb-0 md-mb-0">
                  <h6>
                    Select the brochure you need and download the language of
                    your interest.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-feature-nineteen" id="effect">
        <div className="container">
          {/* End .row */}
          <div className="row">
            {brochure.map((val, i) => (
              <div className="mt-3 col-lg-6 py-3">
                <div className="row g-0">
                  <div
                    className="col-lg-6"
                    style={{ width: "60%", height: "300px" }}
                  >
                    <div
                      className="card-body text-white"
                      style={{
                        backgroundColor: "#004b8d",
                        height: "300px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4 className="card-title text-6 text-white">
                        {val.title}
                      </h4>
                      <div style={{ maxHeight: "150px", overflow: "auto" }}>
                        <p className="card-text text-white my-2">
                          {removeHTMLTags(val.description)}
                        </p>
                      </div>

                      <div style={{ display: "flex", gap: "10px" }}>
                        {val.pdfIT && (
                          <a
                            href={`${FILE_PATH}/brochure/${val.pdfIT}`}
                            target="_blank"
                            className="btn bg-color-white text-black btn-rounded btn-3d btn-info mb-2"
                            style={{ flex: 1 }}
                          >
                            IT
                          </a>
                        )}
                        {val.pdfEN && (
                          <a
                            href={`${FILE_PATH}/brochure/${val.pdfEN}`}
                            target="_blank"
                            className="btn btn-rounded text-black bg-color-white btn-3d btn-info mb-2"
                            style={{ flex: 1 }}
                          >
                            EN
                          </a>
                        )}
                        {val.pdfDE && (
                          <a
                            href={`${FILE_PATH}/brochure/${val.pdfDE}`}
                            target="_blank"
                            className="btn bg-color-white text-black btn-rounded btn-3d btn-info mb-2"
                            style={{ flex: 1 }}
                          >
                            DE
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4"
                    style={{
                      width: "50%",
                      backgroundColor: "#f1f1f1",
                      display: "flex", // Use Flexbox
                      justifyContent: "center", // Horizontally center the image
                      alignItems: "center", // Vertically center the image
                    }}
                  >
                    {val.imagePDF && (
                      <img
                        src={`${FILE_PATH}/brochure/${val.imagePDF}`}
                        alt="icon"
                        className="img-fluid left-1 rounded-end p-3"
                        style={{
                          height: "44px",
                          maxWidth: "143px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BrochurePage;
