/* eslint-disable jsx-a11y/alt-text */
import React from "react";
// import img1 from "../../assets/img/Product/Gui_builder/Forum.svg";
import { useGetHomeSvgData } from "../../hooks";

const EnergyEfficencyPage = () => {

  let fileNames = ["energyefficiency"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <>
      <div className="fancy-text-block-thirteen pt-140 md-pt-100" data-aos="fade-left"
              data-aos-duration="1200">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              
            >
              <div className="">
                <div className="title-style-two">
                  <h2> Energy Effiency</h2><br/>
                </div>
                {/* /.title-style-two */}
                <p>
                We offer a straightforward and efficient solution for monitoring and optimizing energy efficiency, helping clients reduce production costs and save money. Through careful analysis of consumption data and the implementation of efficiency measures, we aim to minimize energy waste and reduce environmental impact.
                </p>
                {/* <p>
                  <strong className="background-text-gradient">
                    Regulations:{" "}
                  </strong>
                  In terms of regulations, our company adheres to the latest energy standards, including the ISO-50001 Energy Management standard and EN-15232 classification.
                </p> */}
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              
            >
              {data &&
              data.svgDataArray.map((val, index) => {
                if (val.fileName === "energyefficiency") {
                  return <img key={index} src={val.svgDataURL}></img>;
                } else {
                  return null; // Or you can choose to return some other element if the condition is not met
                }
              })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-nine mt-100 md-mt-80">
        
        <div className="container">
          <div className="title-style-two text-center mb-35 md-mb-10">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <h2>Advantages</h2>
              </div>
            </div>
          </div>
          {/* End title-style-two */}

          <div className="row">
            <div className="col-xl-10 m-auto">
              <p
                className=""
                
              >
                - Flexible and Effective Monitoring: Our system offers adaptable and efficient monitoring of consumption.
              </p><br/>
              <p
                className=""
                
              >
               Cost Savings: Utilize our robust monitoring system to track electricity, gas, water, compressed air, or steam usage, ultimately saving money and enhancing competitiveness.
              </p><br/>
              <p
                className=""
             
              >
                - Optimized Energy Consumption: Gain a competitive edge by optimizing energy consumption costs for your organization.
              </p><br/>
              <p
                className=""
                
              >
                - Energy Consumption Visibility: Our software provides a clear overview of the energy infrastructure and facilitates utility distribution.
              </p><br/>
              <p
                className=""
               
              >
                - Identify Potential Energy Savings: Collect data on energy consumption across manufacturing sites and building premises to identify potential savings. Real-time insights help pinpoint errors and provide timely solutions.
              </p><br/>
              <p
                className=""
                
              >
                - Monitor Corrective Action Results: Continuous improvement is necessary for any industry. Therefore, we also analyze your outcomes and monitor your real-time actions.
              </p><br/>
              <p
                className=""
                
              >
                - Document Energy Efficiencies and Use of Incentives: Powerful tools are available to verify, document, export, and send reports on effective consumption reductions to interested parties, which can be advantageous for obtaining incentives and specific certifications.
              </p><br/>
              <p
                className=""
               
              >
                - Obtain Benefits from Incentives or Desired Certifications: Maximize benefits by leveraging available incentives or obtaining desired certifications.
              </p><br/>
              <p
                className=""
                
              >
                - Increase Competitiveness through Correct Corporate Strategy: Enhance competitiveness by aligning with a correct corporate strategy.
              </p>
            </div>
          </div>
          {/* End .row */}
        </div>
      </div>
    </>
  );
};

export default EnergyEfficencyPage;
