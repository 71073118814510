import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Link } from "react-router-dom";
import shape from "../../../assets/images/shape/120.svg";
import shape1 from "../../../assets/images/shape/121.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import { useGetSoftwareData } from "../../../hooks";
import { FILE_PATH } from "../../../config";
import { useSelector } from "react-redux";
import { loggedUser } from "../../../store/features/authSlice";
const SoftwarePage = () => {
  const checkLoggenInUser = useSelector(loggedUser);
  const isLoggedIn = checkLoggenInUser.isLoggedIn;
  let software = [];
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: software_data } = useGetSoftwareData();
  if (!isLoading) {
    software = software_data.data;
  }

  return (
    <>
      <div className="fancy-hero-three bg-transparent">
        
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">Software</h1>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="faq-section-four">
        <div className="container">
          <div className="title-style-five text-center mb-80 md-mb-60">
            {/* <h2>
              <span>Download your product here.</span>
            </h2> */}
            <h6 className="mt-10">
              This section allows you to download the software product from
              GUIView.
            </h6>
            <h6>
              Please be reminded that the program installation comes complete
              with Help-on-Line. The specific language will be specified during
              the setup procedure. The programs are available for operating
              system Windows 7, Windows 8 or Windows 10, for both 32 or 64 bit.
              If you prefer to be sent full DVD support, please contact our
              offices or local dealer.
            </h6>
            <h6>
              Access to this download section requires user Login or
              pre-registration.
            </h6>
          </div>
          <div className="row">
            <div className="col-xl-9 col-lg-10 m-auto">
              <div
                className="accordion-style-four"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="faq-wrraper">
                  <Accordion preExpanded={["b"]} allowZeroExpanded>
                    {software.length > 0 &&
                      software.map((item, i) => (
                        <AccordionItem className="card" key={i} uuid={item.id}>
                          <AccordionItemHeading className="card-header">
                            <AccordionItemButton>
                              <h5 className="mb-0">
                                <button className="btn btn-link">
                                  <FontAwesomeIcon
                                    icon={faArrowAltCircleDown}
                                    style={{ marginRight: "8px" }}
                                  />
                                  {item.title}
                                </button>
                              </h5>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          {/* Accordion Heading */}
                          <AccordionItemPanel className="card-body fadeInUp">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="download-content">
                                    <h3>Download {item.title}</h3>
                                    <p>Release : {item.release}</p>
                                    <p>Build : {item.build}</p>
                                    <p>Date : {item.softwareDate}</p>
                                    <p>File Setup : {item.filesize}</p>
                                    <p>For : {item.systemrequired}</p>
                                    {isLoggedIn === true ? null : (
                                      <p>
                                        For non-signed downloads, please click{" "}
                                        <a href="/login">here</a>.
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  {isLoggedIn === true ? (
                                    <a
                                      href={`${FILE_PATH}/softwareFiles/${item.euserversetup}`}
                                      target="_blank"
                                      className="btn btn-info btn-3d btn-spacing"
                                    >
                                      Download software
                                    </a>
                                  ) : (
                                    <a
                                      href="/login"
                                      className="btn btn-info btn-3d btn-spacing"
                                    >
                                      Download Setup
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </AccordionItemPanel>

                          {/* Accordion Body Content */}
                        </AccordionItem>
                      ))}
                  </Accordion>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
        {/* /.container */}
      </div>
    </>
  );
};

export default SoftwarePage;
