/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { useGetHomeSvgData } from "../../hooks";

const DataCenterPage = () => {
  let fileNames = ["datacentre1","database1"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <>
      <div className="fancy-hero-three">
       
        <div className="">
          <div className="container">
            <div className="row">
              {/* <div className="col-xl-9 col-lg-10 m-auto">
                <h1 className="heading">Data Center</h1>
              </div> */}
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "datacentre1") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-text-block-thirteen pt-40 md-pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Data Center</h2><br/>
                </div>
                {/* /.title-style-two */}
                <p>
                In today's data-driven world, the volume of generated data is immense, necessitating adequate storage solutions. We aim to provide a comprehensive overview of data center performance, ensuring efficient energy utilization. Monitoring and controlling aspects such as building infrastructure, cooling systems, and energy management are fundamental in optimizing data center operations.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
               {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "database1") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
    </>
  );
};

export default DataCenterPage;
