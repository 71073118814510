import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  SidebarHeader,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import Close from "../../../assets/images/icon/close-w.svg";
import logo from "../../../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { logoutSuccess } from "../../../store/features/authSlice";
import { useViewProduct } from "../../../hooks";
import { useParams } from "react-router-dom";
const Hmi = [
  {
    name: "Gui.Builder",
    routerPath: "/guibuilder",
  },
];
const Iot = [
  {
    name: "Cloud.View",
    routerPath: "/product-gateway",
  },
];
const Virtual = [
  {
    name: "Serial Virtualizer",
    routerPath: "/product-virtualizer",
  },
  {
    name: "Serial Over Ethernet",
    routerPath: "/gcomovertcp",
  },
];
const Solutions = [
  {
    name: "Alarming",
    routerPath: "/alarming",
  },
  {
    name: "Energy Efficiency",
    routerPath: "/energy-efficiency",
  },
  {
    name: "Historian And Data Logging",
    routerPath: "/historian",
  },
  {
    name: "Industrial Iot",
    routerPath: "/industrial-iot",
  },
  {
    name: "OPC UA Connectivity",
    routerPath: "/opc-ua",
  },
  {
    name: "Production Analysis",
    routerPath: "/production-analysis",
  },
  {
    name: "Scada",
    routerPath: "/scada",
  },
];
const Industry = [
  {
    name: "Airport",
    routerPath: "/airport",
  },
  {
    name: "Automotive",
    routerPath: "/automotive",
  },
  {
    name: "Building Automation",
    routerPath: "/building-automation",
  },
  {
    name: "Data Center",
    routerPath: "/data-center",
  },
  {
    name: "Food And Beverage",
    routerPath: "/food-beverage",
  },
  {
    name: "Oil And Gas",
    routerPath: "/oil-gas",
  },
  {
    name: "Pharmaceutical",
    routerPath: "/pharmaceutical",
  },
  {
    name: "Power & Energy",
    routerPath: "/energy",
  },
  {
    name: "Smart Cities",
    routerPath: "/smart-cities",
  },
  {
    name: "Water And Waste",
    routerPath: "/water-wastewater",
  },
];
const Support = [
  {
    name: "Technical Support",
    routerPath: "/technical-support",
  },
  {
    name: "support ticket",
    routerPath: "/support-ticket",
  },
  {
    name: "training",
    routerPath: "/training",
  },
  {
    name: "tutorial",
    routerPath: "/tutorial",
  },
  {
    name: "forum",
    routerPath: "/forum",
  },
];
const Download = [
  {
    name: "Manuals",
    routerPath: "/manual",
  },
  {
    name: "Brochure",
    routerPath: "/brochure",
  },
  {
    name: "Themantical demo",
    routerPath: "/thematic-demo",
  },
  {
    name: "Software",
    routerPath: "/software",
  },
];

const AfterLoginMegaMenuMobile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState("");
  useViewProduct(id, ({ data: product }) => {
    if (product) {
      setData(product.data);
    }
  });
  console.log(data, "data");
  const [click, setClick] = useState(false);
  const handleClick = () => {
    console.log("click",click)
    setClick(!click);
  }
  const handleLogout = () => {
    dispatch(logoutSuccess());
    navigate("/");
  };
  return (
    <div className="mega-menu-wrapper">
      <div className="mob-header multi-mob-header">
        <button className="toggler-menu" onClick={handleClick}>
          <div className={click ? "active" : ""}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      {/* End Header */}

      <ProSidebar
        className={click ? "mega-mobile-menu menu-open" : "mega-mobile-menu"}
      >
        <SidebarHeader>
          <div className="logo position-static">
            <Link to="/">
              <img src={logo} alt="home-demo" />
            </Link>
          </div>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src={Close} alt="icon" />
          </div>
          {/* Mobile Menu close icon */}

          {/* End logo */}
        </SidebarHeader>
        <SidebarContent>
          <Menu>
            {/* <SubMenu title="Home"></SubMenu> */}
            <MenuItem onClick={handleClick}>
              <Link to="/">Home</Link>
            </MenuItem>
            {/* End Home SubMenu */}

            <SubMenu title="Company" onClick={handleClick}>
              <MenuItem>
                <Link to="/aboutus">About us</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/innovation">Quality And Research</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/career">Career</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/contact-us">Contact Us</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/blog">Blog</Link>
              </MenuItem>
            </SubMenu>
            {/* End Company SubMenu */}

            <SubMenu title="Product" >
              <SubMenu title="HMI/SCADA" className="plus alt" >
                {Hmi.map((val, i) => (
                  <MenuItem key={i} onClick={handleClick}>
                    <Link to={val.routerPath}>{val.name}</Link>
                  </MenuItem>
                ))}
              </SubMenu>
              {/* End HMI/SCADA SubMenu */}
              <SubMenu title="IoT Gateway" className="plus alt">
                {Iot.map((val, i) => (
                  <MenuItem key={i} onClick={handleClick}>
                    <Link to={val.routerPath}>{val.name}</Link>
                  </MenuItem>
                ))}
              </SubMenu>
              {/* End IIoT Gateway SubMenu */}
              <SubMenu title="Virtual SerialPort" className="plus alt">
                {Virtual.map((val, i) => (
                  <MenuItem key={i} onClick={handleClick}>
                    <Link to={val.routerPath}>{val.name}</Link>
                  </MenuItem>
                ))}
              </SubMenu>
              {/* End Virtual Gateway SubMenu */}
            </SubMenu>
            {/* End Product SubMenu */}

            <SubMenu title="Solutions">
              {Solutions.map((val, i) => (
                <MenuItem key={i} onClick={handleClick}>
                  <Link to={val.routerPath}>{val.name}</Link>
                </MenuItem>
              ))}
            </SubMenu>
            {/* End Solutions SubMenu */}

            <SubMenu title="Industry">
              {Industry.map((val, i) => (
                <MenuItem key={i} onClick={handleClick}>
                  <Link to={val.routerPath}>{val.name}</Link>
                </MenuItem>
              ))}
            </SubMenu>
            {/* End Industry SubMenu */}

            <SubMenu title="Support">
              {Support.map((val, i) => (
                <MenuItem key={i} onClick={handleClick}>
                  <Link to={val.routerPath}>{val.name}</Link>
                </MenuItem>
              ))}
            </SubMenu>
            {/* End Support SubMenu */}

            <SubMenu title="Download">
              {Download.map((val, i) => (
                <MenuItem key={i} onClick={handleClick}>
                  <Link to={val.routerPath}>{val.name}</Link>
                </MenuItem>
              ))}
            </SubMenu>
            {/* End Download SubMenu */}
            <MenuItem>
              <Link to="/edit-profile">Edit Profile</Link>
            </MenuItem>
            <MenuItem>
              <Link onClick={handleLogout}>Log Out</Link>
            </MenuItem>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default AfterLoginMegaMenuMobile;
