import * as Yup from "yup";
let validationSchema = Yup.object({
  name: Yup.string("Name is Required").required("Name is Required"),
  email: Yup.string("Email is Required")
    .required("Email is Required")
    .email("Email Must be Valid"),
  country: Yup.string("Country is Required").required("Country is Required"),
  message: Yup.string("Message is Required").required("Message is Required"),
  contactnumber: Yup.string("Phone is Required").required("Phone is Required"),
});
export default validationSchema;
