/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import shape1 from "../../../assets/images/shape/100.svg";
import shape2 from "../../../assets/images/shape/101.svg";
import shape3 from "../../../assets/images/shape/102.svg";
import shape4 from "../../../assets/images/shape/103.svg";
import Analysis from "../../../assets/img/analysis.svg";
import Control from "../../../assets/img/controls-svgrepo-com.svg";
import Visualization from "../../../assets/img/data-analysis-examine-svgrepo-com.svg";
import { useGetGeneralsettingData, useGetHomeSvgData } from "../../../hooks";
import { Link } from "react-router-dom";
import shap1 from "../../../assets/images/shape/64.svg";
import shap2 from "../../../assets/images/shape/65.svg";
import Shape from "../../../assets/images/shape/137.svg";
import PropTypes from "prop-types";
// import SvgIcon from "../../../assets/img/case_study/automation.svg";

import { FILE_PATH } from "../../../config";

const HeroSection = ({feature_data}) => {
  const { isLoading: settingLoading, data: setting_data } = useGetGeneralsettingData();
  
  let fileNames = ["homepage"];
  let title = "";
  let subtitle = "";
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  if (!settingLoading && setting_data && setting_data.data) {
    const { list_generalsetting } = setting_data.data;
    const titleSetting = list_generalsetting.find(
      (setting) => setting.text_key === "home_page_general_setting_banner_title"
    );
    const subtitleSetting = list_generalsetting.find(
      (setting) => setting.text_key === "home_page_general_setting_banner_subtitle"
    );
    title = titleSetting ? titleSetting.text_value : "";
    subtitle = subtitleSetting ? subtitleSetting.text_value : "";
  }
  
  // const FeatureContent = [
  //   {
  //     icon: Analysis,
  //     meta: "Analysis",
  //     subTitle: `Driving Success through Industrial Data Analysis: Harnessing Insights for Optimal Performance`,
  //   },
  //   {
  //     icon: Control,
  //     meta: "Control",
  //     subTitle: `Seamless Integration. Precise Control. Unleashing the Potential of Industrial Data`,
  //   },
  //   {
  //     icon: Visualization,
  //     meta: "Visualization",
  //     subTitle: `Visualizing Industrial Data for Clear Insights and Informed Decision-Making   `,
  //   },
  // ];
  const myStyles = {
    
    marginBottom: "20px", // Add margin-bottom
  };
  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  return (
    <div className="hero-banner-four">
        {/* <img src={shape1} alt="shape" className="shapes shape-four" /> */}
      {/* <img src={shape2} alt="shape" className="shapes shape-five" />
      <img src={shape3} alt="shape" className="shapes shape-six" />
      <img src={shape4} alt="shape" className="shapes shape-seven" />
       */}
 
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-lg-last">
            <div className="illustration-holder">
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "homepage") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
          </div>
          {/* End .col */}

          <div className="col-lg-6 order-lg-first">
            <div className="text-wrapper">
              <h1 data-aos="fade-up" data-aos-duration="1200">
              <span>{title}</span>
              </h1>
              <p
                className="sub-text"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                 {subtitle}
              </p>
            </div>
            {/* /.text-wrapper */}
          </div>
          {/* End .col */}
        </div>
      </div>

      {/* <div className="fancy-feature-eight md-mt-100">
        <div className="container">
          <div className="bg-wrapper">
            <div className="row justify-content-center">
              {FeatureContent.map((val, i) => (
                <div
                  className="col-lg-4 col-md-6"
                  key={i}
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <div className="block-style-thirteen">
                    <div className="icon">
                      <img src={`${val.icon}`} alt="icon" />
                    </div>
                    <div className="title font-rubik">{val.meta}</div>
                    <p>{val.subTitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}
      <div className="container">
        
        <div className="row justify-content-center mt-35 md-mt-20">
      
          {feature_data.map((item, i) => (
            <div
              className="col-lg-4 col-md-6" style={myStyles}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay={item.dataDealy}
              key={i}
            
            >
              <div className="block-style-fifteen homepage">
                <div className="block-style-thirteen">
                  <div className="icon">
                    <img src={`${FILE_PATH}/features/${item.images}`} alt="icon" />
                  </div>
                  <div className="title font-rubik">{item.title}</div>
                  <p>{stripHtml(item.description)}</p>
                </div>
              </div>
              {/* /.block-style-ten */}
              
            </div>
            
          ))}
          
        </div>
        
        
      </div>

      {/* /.fancy-feature-eight */}
    </div>
  );
  //  {/* /.hero-banner-four */}
};
HeroSection.propTypes = {
  feature_data: PropTypes.object,
};


export { HeroSection };
