import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { EditProfileServices } from "../api";

const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hook for Admin Organisation States Master section
 */

const useUpdateEditProfile = (onSuccess, onError = onDefaultError) => {
  return useMutation(EditProfileServices.updateEditProfile, {
    onSuccess,
    onError,
  });
};
const useViewEditProfile = (id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    "editprofile-view",
    () => EditProfileServices.viewEditProfile({ id }),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

export { useUpdateEditProfile, useViewEditProfile };
