/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import Airport from "../../assets/img/case_study/aeroplanemo_m2.svg";
import { useGetHomeSvgData } from "../../hooks";
const AirportPage = () => {
  let fileNames = ["airport1", "airport"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <>
      <div className="fancy-hero-three">
        
        <div className="bg-wrapper">
          {/* <div className="col-xl-9 col-lg-10 m-auto">
            <h1 className="heading">Airport</h1>
          </div> */}
          <div className="">
            <div className="row">
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "airport1") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-text-block-thirteen pt-40 md-pt-0">
        <div className="container"
         data-aos="fade-left"
         data-aos-duration="1200">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
             
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Airport industry</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                The airport industry can significantly benefit from GUIview’s advanced solutions, powered by GUI Builder and Cloud View. <b>GUI Builder</b> enables real-time monitoring and control of airport operations, including baggage handling, HVAC systems, lighting, and security. Its intuitive interface allows for seamless integration with various airport systems, ensuring smooth and efficient operations.
                </p>
                <br />
                <p>
                <b>Cloud View</b> extends these capabilities by offering remote access to critical data and control functions. Airport managers can monitor systems in real-time, receive alerts, and make data-driven decisions from anywhere, enhancing operational efficiency and safety.
                </p><br/>
                <p>Together, GUI Builder and Cloud View provide a robust platform for the airport industry, offering reliable monitoring, efficient control, and enhanced situational awareness. Trust GUIview to optimize your airport operations with cutting-edge technology and real-time connectivity</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "airport") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
              {/* <object type="image/svg+xml" data={img2}>svg-animation</object> */}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AirportPage;
