import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { FILE_PATH } from "../../../config";
import { useGetGeneralsettingData } from "../../../hooks";

const TruncateText = ({ text, limit }) => {
  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  const truncatedText = truncateString(text, limit);

  return <div>{truncatedText}</div>;
};

const BlogSection = ({ blog_data }) => {
  const { isLoading: settingLoading, data: setting_data } =
    useGetGeneralsettingData();
  let title = "";
  let subtitle = "";
  let data = "";

  if (!settingLoading && setting_data && setting_data.data) {
    const { list_generalsetting } = setting_data.data;
    const titleSetting = list_generalsetting.find(
      (setting) => setting.text_key === "home_page_general_setting_blog_title"
    );
    const subtitleSetting = list_generalsetting.find(
      (setting) =>
        setting.text_key === "home_page_general_setting_blog_subtitle"
    );
    title = titleSetting ? titleSetting.text_value : "";
    subtitle = subtitleSetting ? subtitleSetting.text_value : "";
  }

  return (
    <div
      className="feature-blog-one pb-0 md-pt-0 md-pb-0"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="fancy-feature-ten  md-pt-0 font-gordita" id="features">
        <div className="bg-wrapper">
          <div className="container">
            <div className="title-style-five text-center mb-10 md-mb-30">
              <h2>
                <span>{title}</span>
              </h2>

              <div
                className="col-lg- col-md-"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <p className="sub-text pt-10 pb-10">{subtitle}</p>
              </div>
              {/* End .col */}
            </div>
            <div className="row justify-content-center mb-20">
              {blog_data.map((item, i) => (
                <div
                  className="col-lg-4 col-md-6"
                  key={i}
                  data-aos-delay={item.dealyAnimation}
                >
                  <Link to={`/blogarticle/${item.id}`} className="post-meta">
                    <img
                      src={`${FILE_PATH}/blog/${item.images}`}
                      alt="media"
                      className="image-meta"
                    />
                    <div className="tag">{item.category.substring(0, 50)}</div>
                    <h3 className="title">{item.title.substring(0, 50)}</h3>
                    <div className="read-more d-flex justify-content-between align-items-center">
                      <span>Read More</span>
                      <i className="flaticon-right-arrow"></i>
                    </div>
                  </Link>

                  {/* /.post-meta */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
BlogSection.propTypes = {
  blog_data: PropTypes.object,
};
export { BlogSection };
