import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Link } from "react-router-dom";
// import shape from "../../../assets/images/shape/120.svg";
// import shape1 from "../../../assets/images/shape/121.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import { useGetManualData } from "../../../hooks";
import { FILE_PATH } from "../../../config";

const ManualPage = () => {
  let manual = [];
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: manual_data } = useGetManualData();
  if (!isLoading && manual_data) {
    manual = manual_data.data;
  }
  const removeHTMLTags = (input) => {
    return input.replace(/<[^>]*>?/gm, ""); // This regular expression matches and removes HTML tags
  };

  return (
    <>
      <div className="fancy-hero-three bg-transparent">
        
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">Manuals</h1>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      <div className="faq-section-four">
        
        <div className="container">
          <div className="title-style-five text-center mb-80 md-mb-60">
            <h6>
              This section allows you to download the desired Technical Manual
              (PDF) for reading or printing. Access to this download section
              requires user Login or pre-registration. If you wish to be sent
              complete DVD support please contact our offices or your local
              dealer.
            </h6>
          </div>

          <div className="row">
            <div className="col-xl-9 col-lg-10 m-auto">
              <div
                className="accordion-style-four"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="faq-wrraper">
                  <Accordion preExpanded={["b"]} allowZeroExpanded>
                    {manual.length > 0 &&
                      manual.map((item, i) => (
                        <AccordionItem className="card" key={i} uuid={item.id}>
                          <AccordionItemHeading className="card-header">
                            <AccordionItemButton>
                              <h5 className="mb-0">
                                <button className="btn btn-link">
                                  <FontAwesomeIcon
                                    icon={faArrowAltCircleDown}
                                    style={{ marginRight: "8px" }}
                                  />
                                  {item.title}
                                </button>
                              </h5>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          {/* Accordion Heading */}
                          <AccordionItemPanel className="card-body fadeInUp">
                            <div className="table-responsive">
                              <table
                                className="table"
                                style={{ border: "hidden" }}
                              >
                                <thead>
                                  <tr>
                                    <th>Description</th>
                                    <th>Version</th>
                                    <th>Edition</th>
                                    <th>Language</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{removeHTMLTags(item.description)}</td>
                                    <td>{item.version}</td>
                                    <td>{item.edition}</td>
                                    <td>
                                      {item.pdfIT && (
                                        <a
                                          href={`${FILE_PATH}/manual/${item.pdfIT}`}
                                          target="_blank"
                                          className="btn btn-rounded btn-info btn-3d btn-spacing"
                                        >
                                          IT
                                        </a>
                                      )}
                                      {item.pdfEN && (
                                        <a
                                          href={`${FILE_PATH}/manual/${item.pdfEN}`}
                                          target="_blank"
                                          className="btn btn-rounded btn-info btn-3d btn-spacing"
                                        >
                                          EN
                                        </a>
                                      )}
                                      {item.pdfDE && (
                                        <a
                                          href={`${FILE_PATH}/manual/${item.pdfDE}`}
                                          target="_blank"
                                          className="btn btn-rounded btn-info btn-3d btn-spacing"
                                        >
                                          DE
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </AccordionItemPanel>

                          {/* Accordion Body Content */}
                        </AccordionItem>
                      ))}
                  </Accordion>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
        {/* /.container */}
      </div>
    </>
  );
};

export default ManualPage;
