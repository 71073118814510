import client from "../libs/HttpClient";

class ProductServices {
  static viewProduct(params) {
    console.log("params", params);
    return client.get(`/getproductlist`, { params });
  }
}

export { ProductServices };
