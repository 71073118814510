import { toast } from "react-toastify";

import { BlogArticleServices } from "../api";
import { useQuery } from "react-query";

/**
 * Hooks for Authentication Process
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useViewBlogArticle = (id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    "blog-article-view",
    () => BlogArticleServices.viewBlogArticle({ id }),
    {
      onSuccess,
      onError,
    }
  );
};

const useListBlogArticle = (onSuccess, onError = onDefaultError) => {
    return useQuery(["blog-list"], BlogArticleServices.listBlogArticle, {
      onSuccess,
      keepPreviousData: true,
      onError,
    });
  };

export { useViewBlogArticle,useListBlogArticle };
