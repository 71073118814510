/* eslint-disable react/jsx-no-undef */
import React, { useState } from "react";
import { useFormik } from "formik";
import icon1 from "../../assets/images/icon/44.svg";
import icon2 from "../../assets/images/icon/45.svg";
import icon3 from "../../assets/images/icon/46.svg";
// import shape from "../../assets/images/shape/64.svg";
// import shape1 from "../../assets/images/shape/65.svg";

import { useAddContactUs, useGetHomeSvgData } from "../../hooks";
import validationSchema from "./AddContactUsValidations";
import { toast } from "react-toastify";

// import ContactForm from "./ContactForm";
const ContactUsPage = () => {
  // const { mutate: doAddContactUs, isLoading } = useAddContactUs((response) => {
  //   toast.success(response.message);
  // });

  // let fileName = "homepage";
  // let data = "";
  // const { isLoading, data: general_data } = useGetHomeSvgData(fileName);
  // if (!isLoading) {
  //   console.log(general_data, "general_data");
  //   data = general_data.data;
  // }

  const { mutate: doAddContactUs, isLoading: addContactUsLoading } = useAddContactUs((response) => {
    toast.success(response.message);
  });
  
  let fileNames = ["contactus"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  

  const socialContent = [
    {
      icon: "fa-facebook",
      link: "https://www.facebook.com/people/Guiviewofficial/61558387238769/?mibextid=LQQJ4d&rdid=nILSkMx1ifUHdFqj",
    },
    {
      icon: "fa-twitter",
      link: "https://x.com/guiviewofficial?s=11",
    },
    {
      icon: "fa-linkedin",
      link: "https://www.linkedin.com/company/guiview/",
    },
    {
      icon: "fa-instagram",
      link: "https://www.instagram.com/guiviewofficial?igsh=MXYyOTljN3k3cnVldw==",
    },
    {
      icon: "fa-pinterest",
      link: "https://in.pinterest.com/infoguiview/",
    },
    {
      icon: "fa-youtube-play",
      link: "https://youtube.com/@Guiviewofficial?si=1n4BKcy5D9ZqJK9U",
    },
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      company: "",
      city: "",
      postalcode: "",
      email: "",
      message: "",
      phonenumber: "",
    },
    validationSchema,
    onSubmit: async (values, action) => {
      doAddContactUs(values);
      action.resetForm();
    },
  });

  return (
    <>
      <div className="fancy-hero-four bg-event space-fix">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl- col-lg- col-md- m-auto">
                <h6>Contact us</h6>
                <h2>Feel free to contact us or just say hi!</h2>
                {data &&
              data.svgDataArray.map((val, index) => {
                if (val.fileName === "contactus") {
                  return <img key={index} src={val.svgDataURL}></img>;
                } else {
                  return null; // Or you can choose to return some other element if the condition is not met
                }
              })}
              </div>
            </div>
            
          </div>
          
        </div>
        {/* /.bg-wrapper */}
      </div>
      
      {/* /.fancy-hero-one */}

      <div className="contact-style-two">
        <div className="container">
          <div className="contact-info-wrapper">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6 d-lg-flex">
                <div className="address-info">
                  <div className="icon d-flex align-items-end">
                    <img src={icon1} alt="icon" />
                  </div>
                  <div className="title">Location</div>
                  <p className="font-rubik">
                    Mahadev vadi street no 3, <br />
                    Umakant Pandit Udyognagar,
                    <br />
                    laxminagar main road,
                    <br />
                    Rajkot(Gujarat) -360004
                  </p>
                </div>{" "}
                {/* /.address-info  */}
              </div>
              {/* End .col */}

              <div className="col-lg-4 col-sm-6 d-lg-flex">
                <div className="address-info">
                  <div className="icon d-flex align-items-end">
                    <img src={icon2} alt="icon" />
                  </div>
                  <div className="title">Contact</div>
                  <p className="font-rubik">
                    sales@guiview.com
                    <br />
                    support@guiview.com
                  </p>
                </div>{" "}
                {/* /.address-info  */}
              </div>
              {/* End .col */}

              <div className="col-lg-4 col-sm-6 d-lg-flex">
                <div className="address-info">
                  <div className="icon d-flex align-items-end">
                    <img src={icon3} alt="icon" />
                  </div>
                  <div className="title">Social Media</div>
                  <p className="font-rubik">Find on social media</p>
                  <ul className="d-flex justify-content-center">
                    {socialContent.map((val, i) => (
                      <li key={i}>
                        <a href={val.link} target="_blank" rel="noreferrer">
                          <i className={`fa ${val.icon}`}></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* /.address-info  */}
              </div>
              {/* End .col */}
            </div>{" "}
            {/* <img src={shape} alt="shape" className="shapes shape-one" />
            <img src={shape1} alt="shape" className="shapes shape-two" /> */}
          </div>
          {/* /.contact-info-wrapper */}
<div className="form-style-classic mt-100 md-mt-80">
            <form id="contact-form" onSubmit={formik.handleSubmit}>
              <div className="messages"></div>
              <div className="row controls">
                <div className="col-md-6">
                  <div className="input-group-meta form-group mb-60">
                    <label> Name</label>
                    <input
                      placeholder="Enter Your Name"
                      name="name"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      className={` ${formik.errors.name ? "is-invalid" : ""}`}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="invalid-feedback">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* End .col */}

                <div className="col-md-6">
                  <div className="input-group-meta form-group mb-60">
                    <label>Company</label>
                    <input
                      placeholder="Enter Your Company"
                      name="company"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.company}
                      className={` ${
                        formik.errors.company ? "is-invalid" : ""
                      }`}
                    />
                    {formik.touched.company && formik.errors.company ? (
                      <div className="invalid-feedback">
                        {formik.errors.company}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* End .col */}

                <div className="col-6">
                  <div className="input-group-meta form-group mb-60">
                    <label>City / Country</label>
                    <input
                      placeholder="Enter Your city"
                      name="city"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                      className={` ${formik.errors.city ? "is-invalid" : ""}`}
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <div className="invalid-feedback">
                        {formik.errors.city}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* End .col */}

                <div className="col-6">
                  <div className="input-group-meta form-group mb-60">
                    <label>Postal Code / Zip Code</label>
                    <input
                      placeholder="Enter Your Postal Code / Zip Code"
                      name="postalcode"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.postalcode}
                      className={` ${
                        formik.errors.postalcode ? "is-invalid" : ""
                      }`}
                      onKeyPress={(e) => {
                        if (!/^[0-9]+$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {formik.touched.postalcode && formik.errors.postalcode ? (
                      <div className="invalid-feedback">
                        {formik.errors.postalcode}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-6">
                  <div className="input-group-meta form-group mb-60">
                    <label>Telephone</label>
                    <input
                      placeholder="Enter Your Telephone"
                      name="phonenumber"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phonenumber}
                      className={` ${
                        formik.errors.phonenumber ? "is-invalid" : ""
                      }`}
                      onKeyPress={(e) => {
                        if (!/^[0-9]+$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {formik.touched.phonenumber && formik.errors.phonenumber ? (
                      <div className="invalid-feedback">
                        {formik.errors.phonenumber}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-6">
                  <div className="input-group-meta form-group mb-60">
                    <label>Email</label>
                    <input
                      placeholder="Enter Your Email"
                      name="email"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className={` ${formik.errors.email ? "is-invalid" : ""}`}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="invalid-feedback">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-12">
                  <div className="input-group-meta form-group lg mb-40">
                    <label>Message</label>
                    <textarea
                      placeholder="Your message goes here.."
                      name="message"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                      className={` ${
                        formik.errors.message ? "is-invalid" : ""
                      }`}
                    ></textarea>
                    {formik.touched.message && formik.errors.message ? (
                      <div className="invalid-feedback">
                        {formik.errors.message}
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* End .col */}

                <div className="col-12">
                  <button type="submit" className="theme-btn-two">
                    Send Message
                  </button>
                </div>
                {/* End .col */}
              </div>
            </form>{" "}
          </div>
          
        </div>
      </div>
    </>
  );
};

export default ContactUsPage;
