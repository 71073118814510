/* eslint-disable jsx-a11y/alt-text */
import React from "react";
// import img1 from "../../assets/img/Product/Gui_builder/Forum.svg";
import { useGetHomeSvgData } from "../../hooks";

const HistorianDataPage = () => {
  let fileNames = ["hdata"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <div>
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2> Historian & Data Logging</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                GUIview’s historian and data logging solutions, powered by GUI Builder and Cloud View, provide robust data capture and analysis capabilities. With GUI Builder, users can log data based on specific conditions or at regular intervals, storing information in various databases like MS Access, SQLite, and SQL-related databases. This flexibility ensures comprehensive data management tailored to operational needs.

                </p>
                <br />
                <p>
                Cloud View enhances these features by enabling real-time data access and remote monitoring. Users can access historical data, perform in-depth analysis, and generate reports from anywhere, ensuring continuous oversight and informed decision-making.
                </p><br/>
                <p>
                Together, GUI Builder and Cloud View offer a powerful solution for data logging and historical analysis, providing the tools needed for efficient and effective data management in industrial environments.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "hdata") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistorianDataPage;
