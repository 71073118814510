import client from "../libs/HttpClient";

class CareerService {
  static getCareerData() {
    return client.get("/career");
  }
  static addCareerData(request) {
    return client.post(`/add-career`, request, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  }
}

export { CareerService };
