import React from "react";
import { Helmet } from "react-helmet";

const RefundAndReturnPage = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Terms & Conditions || Deski-Saas & Software React Template
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-12">
              {/* Tab panes */}
              <div className="tab-content ">
                <div id="opt3">
                  <div className="col-xl-6 col-lg-6 m-auto terms-condition-container">
                    <h3 className="font-gilroy-bold">
                      Refund, Return & Shipping Policy
                    </h3>
                    <div className="update-date">Effective Date: 4/17/2024</div>
                  </div>

                  <h3>No Refunds :</h3>
                  <p>
                    Once the payment is successfully processed and the software
                    license is generated, no refunds will be provided under any
                    circumstances.
                  </p>

                  <h3>Exception to No Refunds :</h3>
                  <p>
                    If the payment has been processed, but the software license
                    has not been generated due to a technical issue, please
                    follow these steps:
                  </p>
                  <li>
                    {" "}
                    Contact our support team immediately and provide them with
                    the payment receipt and transaction details.
                  </li>
                  <li>
                    Our team will work on resolving the issue and ensure that
                    the license is generated as soon as possible.
                  </li>
                  <h3>Refund Timeline :</h3>
                  <p>
                    If the issue is not resolved within 7 days of contacting
                    support, a refund will be issued within 7 days from the day
                    the resolution timeline ends.
                  </p>

                  <h3>Shipping Policy :</h3>
                  <p>
                    Our products are delivered digitally, with no physical
                    shipment involved. Upon successful payment, your software
                    license will be delivered instantly via email or made
                    available in your account. Please ensure that your email
                    address and account details are accurate to avoid any
                    delays.
                  </p>

                  <h3>Return Policy :</h3>
                  <p>
                    We do not accept returns for digital products once the
                    license has been delivered and activated. If you experience
                    any issues with your purchase, such as non-delivery of the
                    license, please contact our support team. We are here to
                    help resolve the issue.
                  </p>
                  <br />

                  <p>
                    If you have any questions, please reach out to{" "}
                    <a href="mailto: support@guiview.com">
                      support@guiview.com
                    </a>
                  </p>
                </div>
              </div>
              {/*  /.tab-content */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundAndReturnPage;
