/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import img1 from "../../../assets/img/Product/Gui_builder/Forum.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useGetHomeSvgData } from "../../../hooks";
import { useNavigate } from "react-router-dom";
const SerialVirtualizerPage = () => {
  const navigate = useNavigate();
  let fileNames = [
    "serialvirtulizer",
    "comvirtualizersvg1",
    "signalline",
    "virtrealport",
    "virtanynum",
  ];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  const handleclick = () => {
    navigate("/quote");
  };
  return (
    <div>
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>
                    {" "}
                    {data &&
                      data.svgDataArray.map((val, index) => {
                        if (val.fileName === "serialvirtulizer") {
                          return <img key={index} src={val.svgDataURL}></img>;
                        } else {
                          return null; // Or you can choose to return some other element if the condition is not met
                        }
                      })}
                  </h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  GComm virtualizer creates a virtual connection between virtual
                  COM ports using a virtual null-modem cable. It employs recent
                  technology specifically designed for developing, testing, or
                  debugging serial ports, whether in software or hardware. Users
                  can create and use as many virtual COM ports as needed. If
                  physical COM ports are insufficient, the software provides
                  solutions for this as well. Furthermore, for systems lacking
                  real serial ports, we offer a comprehensive solution
                  integrated with the system. Users can establish connections
                  between virtual serial interfaces by utilizing a virtual
                  null-modem link.
                </p>
              </div>
              <div className="md-pt-0 mt-10">
                {/* Submit button */}
                <button
                  onClick={handleclick}
                  style={{ marginBottom: "30px" }}
                  className="theme-btn-two float-lg-right"
                >
                  Request a Quote
                </button>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "comvirtualizersvg1") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="container">
        <h2 className="title-style-two text-center mt-50">
          Virtualizer functionality
        </h2>
      </div>

      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-8 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Virtual Serial Port Driver</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Virtual Serial Port Driver can serve as a standalone solution
                  or integrated with your product. It facilitates serial
                  communication by creating virtual COM port pairs instead of
                  relying on physical COM ports. This approach proves
                  particularly useful when real serial ports are already
                  occupied, necessitating additional ports.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-2 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "virtanynum") {
                    return (
                      <object
                        key={index}
                        data={val.svgDataURL}
                        style={{ height: "250px", width: "100%" }} // Inline styles for height and width
                      ></object>
                    );
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>

            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-8 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2> Create any number of virtual COM ports</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  Users often encounter situations where they require additional
                  COM ports due to insufficient physical ports. Virtual Serial
                  Port Driver enables the creation of virtual COM interfaces to
                  address this need. Typically, these drivers are readily
                  available within your system, accessible via the Device
                  Manager. They emulate real ports like RS232, RS422, or RS485,
                  allowing seamless integration without the constraints of
                  physical hardware. Compatibility with any application
                  simplifies installation, ensuring easy setup. Once installed,
                  virtual COM ports function as physical serial interfaces.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-2 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "virtanynum") {
                    return <object key={index} data={val.svgDataURL}
                    style={{ height: "250px", width: "100%" }}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-8 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Emulate real port settings</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  The software swiftly identifies the operating system since it
                  establishes virtual connections. Users can effectively manage
                  serial ports, including baud rate emulation. Settings for
                  serial ports and additional features such as Hand Flow control
                  are adjustable according to specific requirements, leveraging
                  Windows kernel driver technology (WDM, WMI, Power Management,
                  PnP, etc.). Additionally, Virtual Serial Port Driver enables
                  users to monitor the current port state and the status of the
                  last port, as well as view transmitted and received data,
                  including serial line breaks.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-2 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "virtrealport") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-8 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2> Manage hardware signal lines</h2>
                  <br />
                </div>
                {/* /.title-style-two */}
                <p>
                  The software offers users flexibility by providing virtual
                  serial port connections that mimic real null-modem
                  connections. It supports various hardware signal lines,
                  including Data Carrier Detect (DCD), Data Terminal Ready
                  (DTR), Data Set Ready (DSR), Clear to Send (CTS), Request to
                  Send (RTS), and RI. Given the diverse hardware configurations
                  of users, we ensure compatibility with systems that can be
                  utilized in loopback pinouts. This allows users to tailor
                  their systems according to their specific requirements,
                  regardless of their hardware configuration.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-2 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "signalline") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>

      <br></br>
      <section
        className="bg-color-orange position-relative border-0  m-0"
        style={{
          backgroundImage:
            "linear-gradient(to right, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #8d3d86 100%)",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <div className="container">
          <div className="row text-white text-center align-items-center">
            <div className="col-md-6">
              <h2
                style={{ fontSize: "25px", color: "white", marginBottom: "0" }}
              >
                Download and try it now!
              </h2>
            </div>
            <div className="col-md-6 mt-4">
              <div className="d-flex flex-column align-items-center">
                <Link
                  to="/contact-us"
                  className="btn"
                  style={{
                    backgroundColor: "#000",
                    color: "white",
                    height: "45px",
                    width: "70%",
                    fontSize: "15px",
                    border: "none",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none", // to remove default underline
                  }}
                >
                  Talk with our experts
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: "8px" }}
                  />
                </Link>
                <Link
                  to="/software"
                  className="btn"
                  style={{
                    backgroundColor: "#000",
                    color: "white",
                    height: "45px",
                    width: "70%",
                    fontSize: "15px",
                    border: "none",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none", // to remove default underline
                  }}
                >
                  Download product
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: "8px" }}
                  />
                </Link>
                <Link
                  to="/tutorial"
                  className="btn"
                  style={{
                    backgroundColor: "#000",
                    color: "white",
                    height: "45px",
                    width: "70%",
                    fontSize: "15px",
                    border: "none",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none", // to remove default underline
                  }}
                >
                  Video tutorial
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: "8px" }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SerialVirtualizerPage;
