import React from "react";
import Errorpage from "../../assets/img/404 Error-bro.png";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/icon/53.svg";

const PageNotFound = () => {
  return (
    <>
      <div className="error-page d-lg-flex align-items-center">
        <div className="img-holder order-lg-last">
          <img src={Errorpage} alt="media" className="w-100 illustration" />
        </div>
        <div className="text-wrapper order-lg-first">
          <h1 className="font-slab">
            Sorry, <br />
            The Page Can’t <br />
            be Found.
          </h1>
          <p className="font-rubik">
            The page you are looking for might have been removed, had its name
            changed, or is temporarily unavailable. Please check the URL for any
            mistakes, or try navigating back to the homepage.
          </p>
          <Link
            to="/"
            className="back-home font-rubik d-flex align-items-center justify-content-center"
          >
            <span>Back to Home</span>
            <img src={Logo} alt="icon" />
          </Link>
        </div>
        {/* /.text-wrapper */}
      </div>
    </>
  );
};

export default PageNotFound;
