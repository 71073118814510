import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useViewBlogArticle } from "../../hooks";
import { FILE_PATH } from "../../config";
import { Link } from "react-router-dom";

const BlogArticlePage = () => {
  const { id } = useParams();
  const [data, setData] = useState("");
  const [otherBlog, setOtherData] = useState("");
  const [pageTitle, setPageTitle] = useState("Blog Article Title");
  const [pageDescription, setPageDescription] = useState("Blog article description");
  const [pageKeywords, setPageKeywords] = useState("Blog, Article, News");

  const { refetch } = useViewBlogArticle(id, ({ data: blog }) => {
    if (blog) {
      setData(blog.data);
      setOtherData(blog.data1);
    }
  });

  useEffect(() => {
    refetch();
  }, [id, refetch]);

  useEffect(() => {
    if (data) {
      const seo_title = data.seo_title || "Blog Article Title";
      const seo_description = data.seo_description ? removeHTMLTags(data.seo_description) : "Blog article description";
      const seo_keyword = data.seo_keyword || "Blog, Article, News";

      setPageTitle(seo_title);
      setPageDescription(seo_description);
      setPageKeywords(seo_keyword);
    }
  }, [data]);

  const removeHTMLTags = (input) => {
    if (typeof input === "string" || input instanceof String) {
      const div = document.createElement("div");
      div.innerHTML = input;

      const allowedTags = ["h1", "h2", "h3", "h4", "h5", "h6", "ol", "ul", "li", "strong", "em", "br", "p", "div", "a", "img", "figure"];

      const sanitizeNode = (node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          if (!allowedTags.includes(node.tagName.toLowerCase())) {
            node.remove();
            return;
          }

          if (node.tagName.toLowerCase() === "a") {
            node.setAttribute("style", "color: #ff8c00;");
          } else if (node.tagName.toLowerCase() === "img") {
            node.setAttribute("class", "blog-image-left");
          } else if ([].includes(node.tagName.toLowerCase())) {
            node.setAttribute("class", "blog-text-right");
          }

          for (let i = node.childNodes.length - 1; i >= 0; i--) {
            sanitizeNode(node.childNodes[i]);
          }
        }
      };

      for (let i = div.childNodes.length - 1; i >= 0; i--) {
        sanitizeNode(div.childNodes[i]);
      }

      return div.innerHTML;
    } else {
      return input;
    }
  };

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="og:title" content={pageTitle} />
        <meta name="title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta name="og:description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
        <meta name="og:keywords" content={pageKeywords} />
      </Helmet>

      <style>{`
        .blog-image-left {
          width: 100%; /* Adjust the width as needed */
          height: 350px; /* Fixed height */
          object-fit: contain; /* Ensures the entire image fits within the container without stretching */
        }
        .post-meta {
          overflow: hidden; /* Ensure content stays within the card */
          padding: 20px; /* Add padding for better appearance */
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visual separation */
          border-radius: 8px; /* Optional: Add rounded corners */
          background-color: #fff; /* Optional: Set background color */
          margin-bottom: 20px; /* Optional: Add space between cards */
        }

        .blog-text-right {
          float: right;
          width: 100%; /* Adjust width as needed */
        }
        .fixed-size-image {
          width: 100%; /* Adjust the width as needed */
          height: 400px; /* Fixed height */
          object-fit: contain; /* Ensures the entire image fits within the container without stretching */
        }
      `}</style>

      <div className="fancy-hero-three">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 m-auto">
                <h1 className="heading">Blog Brief</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-page-bg">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-12 feature-blog-one width-lg blog-details-post-v1">
              <div className="post-meta blogArticle">
                <img src={`${FILE_PATH}/blog/${data.images}`} alt="feature" className="fixed-size-image" />
                <div className="tag">{data?.category}</div>
                <h3 className="title">{data?.title}</h3>
                {/* Render HTML content safely */}
                <div dangerouslySetInnerHTML={{ __html: removeHTMLTags(data.description) }} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="feature-blog-one pt-15 pb-15 md-pt-15 md-pb-15">
        <div className="container">
          <div className="row">
            <div className="m-auto">
              <div className="title-style-one text-center mb-50 md-mb-20">
                <h2>Read The Other Blog</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {otherBlog &&
              otherBlog.map((item, i) => (
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  key={i}
                  data-aos-delay={item.dealyAnimation}
                >
                  <Link to={`/blogarticle/${item.id}`} className="post-meta">
                    <img
                      src={`${FILE_PATH}/blog/${item.images}`}
                      alt="media"
                      className="image-meta fixed-size-image"
                    />
                    <div className="tag">{item.category.substring(0, 50)}</div>
                    <h3 className="title">{item.title.substring(0, 50)}</h3>
                    <div className="read-more d-flex justify-content-between align-items-center">
                      <span>Read More</span>
                      <i className="flaticon-right-arrow"></i>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogArticlePage;
