import client from "../libs/HttpClient";

class EditProfileServices {
  static updateEditProfile(request) {
    return client.post(`user-profile`, request, {});
  }
  static viewEditProfile(params) {
    return client.get(`get-user-profile`, { params });
  }
}

export { EditProfileServices };
