import client from "../libs/HttpClient";

class AuthServices {
  static login(loginData) {
    return client.post("/user-login", loginData);
  }
  static register(request) {
    return client.post("/user-signup", request);
  }
  static verification(request) {
    return client.post("/verify-user", request);
  }
  static resetpassword(request) {
    return client.post("/reset-password", request);
  }
  static newpassword(request) {
    return client.post("/new-password", request);
  }
}
export { AuthServices };
