/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { useGetHomeSvgData } from "../../hooks";

const SmartCitiesPage = () => {
  let fileNames = ["smartcityanimation", "smartcity"];
  let data = "";
  /**
   * !This API will call when page set. When response come we are Home Page Data Set
   */
  const { isLoading, data: general_data } = useGetHomeSvgData(fileNames);
  if (!isLoading) {
    data = general_data.data;
  }
  return (
    <>
      <div className="fancy-hero-three">
        
        <div className="">
          <div className="container">
            <div className="row">
              {/* <div className="col-xl-9 col-lg-10 m-auto">
                <h1 className="heading">Smart Cities</h1>
              </div> */}
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "smartcityanimation") {
                    return <object key={index} data={val.svgDataURL}></object>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>

      <div className="fancy-text-block-thirteen pt-40 md-pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="">
                <div className="title-style-two">
                  <h2>Smart Cities</h2><br/>
                </div>
                {/* /.title-style-two */}
                <p>
                Smart cities can enhance their infrastructure and services with GUIview’s innovative solutions, powered by GUI Builder and Cloud View. <b>GUI Builder</b> offers comprehensive tools for real-time monitoring and control of various city systems, including traffic management, public safety, and utilities. Its intuitive interface ensures seamless integration with smart devices and sensors, providing clear visualization and efficient management of urban operations.
                </p><br/>
                <p>
                <b>Cloud View</b> amplifies these capabilities by offering remote access to critical data and control functions. City planners and administrators can monitor systems, receive alerts, and make data-driven decisions from anywhere, optimizing city operations and improving quality of life for residents.
                </p><br/>
                <p>Together, GUI Builder and Cloud View deliver a powerful platform for smart city initiatives, providing real-time data access, remote monitoring, and enhanced control. Trust GUIview to support your smart city projects with cutting-edge technology and drive innovation and efficiency in urban management.
</p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {data &&
                data.svgDataArray.map((val, index) => {
                  if (val.fileName === "smartcity") {
                    return <img key={index} src={val.svgDataURL}></img>;
                  } else {
                    return null; // Or you can choose to return some other element if the condition is not met
                  }
                })}
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartCitiesPage;
