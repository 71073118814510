import React from "react";
import { Link } from "react-router-dom";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Hmi = [
  {
    name: "Gui.Builder",
    routerPath: "/guibuilder",
  },
];
const Iot = [
  {
    name: "Cloud.View",
    routerPath: "/product-gateway",
  },
];
const Virtual = [
  {
    name: "Serial Virtualizer",
    routerPath: "/product-virtualizer",
  },
  {
    name: "Serial Over Ethernet",
    routerPath: "/gcomovertcp",
  },
];
const Solutions = [
  {
    name: "Alarming",
    routerPath: "/alarming",
  },
  {
    name: "Energy Efficiency",
    routerPath: "/energy-efficiency",
  },
  {
    name: "Historian And Data Logging",
    routerPath: "/historian",
  },
  {
    name: "Industrial Iot",
    routerPath: "/industrial-iot",
  },
  {
    name: "OPC UA Connectivity",
    routerPath: "/opc-ua",
  },
  {
    name: "Production Analysis",
    routerPath: "/production-analysis",
  },
  {
    name: "Scada",
    routerPath: "/scada",
  },
];
const Industry = [
  {
    name: "Airport",
    routerPath: "/airport",
  },
  {
    name: "Automotive",
    routerPath: "/automotive",
  },
  {
    name: "Building Automation",
    routerPath: "/building-automation",
  },
  {
    name: "Data Center",
    routerPath: "/data-center",
  },
  {
    name: "Food And Beverage",
    routerPath: "/food-beverage",
  },
  {
    name: "Oil And Gas",
    routerPath: "/oil-gas",
  },
  {
    name: "Pharmaceutical",
    routerPath: "/pharmaceutical",
  },
  {
    name: "Power & Energy",
    routerPath: "/energy",
  },
  {
    name: "Smart Cities",
    routerPath: "/smart-cities",
  },
  {
    name: "Water And Waste",
    routerPath: "/water-wastewater",
  },
  {
    name: "Machine Tools Industry",
    routerPath: "/machine-tools-industry",
  },
];
const Support = [
  {
    name: "Technical Support",
    routerPath: "/technical-support",
  },
  {
    name: "support ticket",
    routerPath: "/support-ticket",
  },
  {
    name: "training",
    routerPath: "/training",
  },
  {
    name: "tutorial",
    routerPath: "/tutorial",
  },
  {
    name: "forum",
    routerPath: "/forum",
  },
];
const Download = [
  {
    name: "Manuals",
    routerPath: "/manual",
  },
  {
    name: "Brochure",
    routerPath: "/brochure",
  },
  {
    name: "Themantical demo",
    routerPath: "/thematic-demo",
  },
  {
    name: "Software",
    routerPath: "/software",
  },
];

const MegaMenu = () => {
  return (
    <ul className="navbar-nav">
      <li className="nav-item dropdown position-static">
        <Link className="nav-link" to="/">
          Home
        </Link>
      </li>

      <li className="nav-item dropdown">
        <a className="nav-link" href="#" data-toggle="dropdown">
        Company
        </a>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to="/aboutus">
              About Us
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/innovation">
              Quality And Research
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/career">
              Career
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/contact-us">
              Contact Us
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/blog">
              Blog
            </Link>
          </li>
        </ul>
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}

      <li className="nav-item dropdown">
        <a className="nav-link" href="#" data-toggle="dropdown">
          Product
        </a>

        <ul className="dropdown-menu">
          <li className="dropdown-submenu dropdown">
            <a
              href="#"
              className="dropdown-item dropdown-toggle"
              data-toggle="dropdown"
            >
              HMI/SCADA
            </a>
            <ul className="dropdown-menu">
              {Hmi.map((val, i) => (
                <li key={i}>
                  <Link to={val.routerPath} className="dropdown-item">
                    {val.name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li className="dropdown-submenu dropdown">
            <a
              href="#"
              className="dropdown-item dropdown-toggle"
              data-toggle="dropdown"
            >
              IoT Gateway
            </a>
            <ul className="dropdown-menu">
              {Iot.map((val, i) => (
                <li key={i}>
                  <Link to={val.routerPath} className="dropdown-item">
                    {val.name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li className="dropdown-submenu dropdown">
            <a
              href="#"
              className="dropdown-item dropdown-toggle"
              data-toggle="dropdown"
            >
              Virtual SerialPort
            </a>
            <ul className="dropdown-menu">
              {Virtual.map((val, i) => (
                <li key={i}>
                  <Link to={val.routerPath} className="dropdown-item">
                    {val.name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        </ul>
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}

      <li className="nav-item dropdown">
        <a className="nav-link" href="#" data-toggle="dropdown">
          Solutions
        </a>
        <ul className="dropdown-menu">
          {Solutions.map((val, i) => (
            <li key={i}>
              <Link to={val.routerPath} className="dropdown-item">
                {val.name}
              </Link>
            </li>
          ))}
        </ul>
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}

      <li className="nav-item dropdown">
        <a className="nav-link" href="#" data-toggle="dropdown">
          Industry
        </a>
        <ul className="dropdown-menu">
          {Industry.map((val, i) => (
            <li key={i}>
              <Link to={val.routerPath} className="dropdown-item">
                {val.name}
              </Link>
            </li>
          ))}
        </ul>
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}

      <li className="nav-item dropdown">
        <a className="nav-link" href="#" data-toggle="dropdown">
          Support
        </a>
        <ul className="dropdown-menu">
          {Support.map((val, i) => (
            <li key={i}>
              <Link to={val.routerPath} className="dropdown-item">
                {val.name}
              </Link>
            </li>
          ))}
        </ul>
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}

      <li className="nav-item dropdown">
        <a className="nav-link" href="#" data-toggle="dropdown">
          download
        </a>
        <ul className="dropdown-menu">
          {Download.map((val, i) => (
            <li key={i}>
              <Link to={val.routerPath} className="dropdown-item">
                {val.name}
              </Link>
            </li>
          ))}
        </ul>
        {/* /.dropdown-menu */}
      </li>

      {/* End li */}
    </ul>
  );
  // End navbar nav mega menu main
};

export default MegaMenu;
