import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { FILE_PATH } from "../../../config";

const TruncateText = ({ text, limit }) => {
  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  const truncatedText = truncateString(text, limit);

  return <div>{truncatedText}</div>;
};

const NewsSection = ({ news_data }) => {
  return (
    <div
      className="feature-blog-one pb-0 md-pt-0 md-pb-0"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="container">
        <div className="row">
          <div className="m-auto">
            <div className="title-style-five text-center mb-20 md-mb-30">
              <h2>
                <span>News & Events</span>
              </h2>

              {/* End .col */}
            </div>
            {/* /.title-style-one */}
          </div>
        </div>
        <div className="row justify-content-center">
          {news_data.map((item, i) => (
            <div
              className="col-lg-4 col-md-6"
              key={i}
              data-aos-delay={item.dealyAnimation}
            >
              <Link  to={`/newsarticle/${item.id}`} className="post-meta">
              <img
                  src={`${FILE_PATH}/news/${item.images}`}
                  alt="media"
                  className="image-meta mx-auto d-block"
                />
                    <div className="tag">
                  {moment(item.createdAt).format("DD MMM YYYY")}
                </div>
                    <h3 className="title"> <TruncateText text={item.title} limit={50} /></h3>
                    <div className="read-more d-flex justify-content-between align-items-center">
                      <span>Read More</span>
                      <i className="flaticon-right-arrow"></i>
                    </div>
                  </Link>


              {/* <div className="post-meta">
                <img
                  src={`${FILE_PATH}/news/${item.images}`}
                  alt="media"
                  className="image-meta mx-auto d-block"
                />
                <div className="tag">
                  {moment(item.createdAt).format("DD MMM YYYY")}
                </div>
                <h3 style={{ height: "130px" }}>
                  <Link to={`/newsarticle/${item.id}`} className="title">
                    <TruncateText text={item.title} limit={50} />
                  </Link>
                </h3>
                <Link
                  to={`/newsarticle/${item.id}`}
                  className="read-more d-flex justify-content-between align-items-center"
                >
                  <span>Read More</span>
                  <i className="flaticon-right-arrow"></i>
                </Link>
              </div> */}
              {/* /.post-meta */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
NewsSection.propTypes = {
  news_data: PropTypes.object,
};
export { NewsSection };
