import React from "react";
import { Helmet } from "react-helmet";

const DataProtectionStatementPage = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Terms & Conditions || Deski-Saas & Software React Template
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-12">
              {/* Tab panes */}
              <div className="tab-content ">
                <div id="opt4">
                  <div className="col-xl-8 col-lg-4 m-auto terms-condition-container">
                    <h2 className="font-gilroy-bold">
                      Data Protection Statementy
                    </h2>
                  </div>

                  <p>
                    PI Corporation is committed to protecting the personal
                    information of its customers, employees, vendors, and
                    investors, all of whom expect us to adhere to the highest
                    standards of data privacy and security.
                  </p>
                  <p>
                    PI Corporation first implemented a formal Data Protection
                    Program to keep pace with the rigorous requirements of the
                    1995 European Union (EU) Data Protection Directive. Over
                    time, the data privacy and security landscape has
                    experienced an unprecedented and rapid level of change in
                    technology, regulations, and stakeholder expectations.{" "}
                  </p>
                  <p>
                    To respond to this changing environment, in 2017, PI
                    Corporation undertook to inventory its Data Protection
                    systems, processes, and controls. These efforts resulted in
                    the re-design and implementation of a Global Data Protection
                    Program enabling PI Corporation to stay abreast of key data
                    privacy and security developments across the globe, and to
                    adapt our global operations to remain compliant in this
                    shifting landscape.{" "}
                  </p>
                  <p>
                    PI Corporation’s Global Data Protection Program requires all
                    PI Corporation businesses, wherever located, to adhere to
                    the core data handling and documentation requirements of the
                    European General Data Protection Regulation (GDPR). PI
                    Corporation’s program is supported by an updated Data
                    Protection Policy, the establishment of a cross functional
                    management advisory board, and the appointment of local and
                    functional data protection coordinators around the world.{" "}
                  </p>
                  <p>
                    In 2019, PI Corporation expanded its Global Data Protection
                    program meet the challenges presented by the proliferation
                    of data protection legislation around the world such as the
                    India Consumer Privacy Act (CCPA), the Brazilian General
                    Data Protection Law, and the India Data Privacy Bill – all
                    of which borrow considerably from the GDPR. In addition, PI
                    Corporation implemented a rigorous product security policy
                    requiring secure product development and product privacy and
                    security risk review at all PI Corporation businesses.{" "}
                  </p>
                  <p>
                    The GDPR, CCPA, and other new regional and local laws change
                    the way companies like PI Corporation must administer and
                    document the collection, movement, and processing of
                    personal data. While data regulations are dynamic, they do
                    not change the fundamental principles upon which PI
                    Corporation has historically relied on to manage personal
                    data. PI Corporation’s Global Data Protection Program will
                    remain measured and nimble to maintain a strong, effective,
                    and sustainable program that will be able to mature and
                    adapt to future regulatory developments and our
                    stakeholders’ needs. PI Corporation has and will continue to
                    place keen emphasis on the importance of keeping personal
                    data secure and respecting the privacy rights of individuals
                    around the world.
                  </p>
                  <div className="update-date">Last Revised: 17/04/2024</div>
                </div>
              </div>
              {/*  /.tab-content */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataProtectionStatementPage;
