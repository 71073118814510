import * as Yup from "yup";

let validationSchema = Yup.object({
  name: Yup.string("Name is Required").required("Name is Required"),
  company: Yup.string("Company is Required").required("Company is Required"),
  city: Yup.string("City is Required").required("City is Required"),
  postalcode: Yup.string("Postalcode is Required").required(
    "Postalcode is Required"
  ),
  email: Yup.string("Email is Required")
    .required("Email is Required")
    .email("Email Must be Valid"),
  message: Yup.string("Message is Required").required("Message is Required"),
  phonenumber: Yup.string().required("Phone Number is Required"),
});

export default validationSchema;
