import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClientProvider, QueryClient } from "react-query";
import PagesRoutes from "../routes/index";
import { persistor, store } from "../store";
import "../assets/main.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setAuthToken } from "../libs/HttpClient";
import Loader from "../common/components/Loader";
import BitrixForm from "../common/components/BitrixForm";



import { NoInternetPage } from "../common/components/index";
import AOS from "aos";
import "aos/dist/aos.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      //retryOnMount:
      // retry: false
    },
  },
});

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });

    // Disable Right-Click
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextMenu);

    // Disable Specific Keyboard Shortcuts
    const handleKeyDown = (e) => {
      if (
        e.key === "F12" ||
        (e.ctrlKey && e.shiftKey && e.key === "I") ||
        (e.ctrlKey && e.shiftKey && e.key === "J")
      ) {
        e.preventDefault();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Checking if navigator is online or not
  if (!navigator.onLine) {
    return <NoInternetPage />;
  }

  // Checking if browser is offline or not
  window.onoffline = () => {
    return <NoInternetPage />;
  };

  // Checking if browser is online or not
  window.ononline = () => {
    window.location.reload(true);
  };

  /**
   * This function will call and page load, and will check, if user is registered or not, and setting authentication token
   */
  const handleOnBeforeLift = () => {
    if (
      store.getState().user.accessToken !== undefined &&
      store.getState().user.accessToken !== null
    ) {
      setAuthToken(store.getState().user.accessToken);
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
          onBeforeLift={handleOnBeforeLift}
        >
          <QueryClientProvider client={queryClient}>
            <PagesRoutes />
            <BitrixForm />
            <ToastContainer />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}

export default App;
